import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Alert, Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FlagIcon from '@material-ui/icons/Flag';
import ReportIcon from '@material-ui/icons/Report';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import BlockIcon from '@material-ui/icons/Block';
import ErrorIcon from '@material-ui/icons/Error';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import BallotIcon from '@material-ui/icons/Ballot';
import { AppContext, colors } from '../../../../App';
import CustomModalHeader from '../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ReadOnlyFormData from '../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import Collapsible from '../../../../shared/components/Collapsible/Collapsible';
import badgeApi from '../../../../ajax/Badge/badgeApi';
import PeopleListEditModal, { BadgesLabel } from '../../People/PeopleList/PeopleListEditModal';
import ReassignSignatoryModal from './components/ReassignSignatoryModal/ReassignSignatoryModal';
import EditBadgeDataModal from './components/EditBadgeDataModal/EditBadgeDataModal';
import ModifyEndorsementsModal from './components/ModifyEndorsementsModal/ModifyEndorsementsModal';
import FlagBadgeModal from './components/FlagBadgeModal/FlagBadgeModal';
import DeactivateBadgeModal from './components/DeactivateBadgeModal/DeactivateBadgeModal';
import ReceiveBadgeModal from './components/ReceiveBadgeModal/ReceiveBadgeModal';
import ActivateBadgeModal from './components/ActivateBadgeModal/ActivateBadgeModal';
import MarkBadgeNotIssuedModal from './components/MarkBadgeNotIssuedModal/MarkBadgeNotIssuedModal';
import PreventRenewalAction, { RenewalPreventedNotice } from './components/PreventRenewalAction';
import StartRenewalAction from './components/StartRenewalAction/StartRenewalAction';
import BadgeComments from './BadgeComments';
import FontAwesomeIcon from '../../../../shared/components/FontAwesome/FontAwesomeIcon';
import { handleError } from '../../../../utils';
import RetainBadgeModal from './components/RetainBadgeModal/RetainBadgeModal';
import DeactivateUserModal from './components/DeactivateUserModal/DeactivateUserModal';
import peopleApi from '../../../../ajax/People/peopleApi';
import CancelRapbackModal from './components/CancelRapbackModal/CancelRapbackModal';
import BadgeCreateModal from './BadgeCreateModal';
import Feature, { hasFeature } from '../../../../shared/Feature';
import badgeTypeApi from '../../../../ajax/BadgeType/badgeTypeApi';
import ReplacePacsBadgeModal from './ReplacePacsBadgeModal';
import ViewPrintedBadgeAction from './components/ViewPrintedBadgeAction';
import BadgeDetailsAdminActionsPanel from './components/BadgeDetailsAdminActionsPanel';
import { FixInvalidEmailAction } from './components/FixInvalidEmailModal';
import EscortEndorsementPicker from '../../BadgeApplications/BadgeApplicationEndorsementsPicker/components/EscortEndorsementPicker';
import DrivingEndorsementPicker from '../../BadgeApplications/BadgeApplicationEndorsementsPicker/components/DrivingEndorsementPicker';
import { getDrivingOptions } from '../../../../shared/BadgeTypes/drivingOptionsUtils';
import PrintBadgeModal from './PrintBadgeModal';
import BadgeCreateRequest from '../../../../ajax/Badge/BadgeCreateRequest';
import { getDefaultBadgeExpiration } from './CreateBadgeApplicationBadgeModal/CreateBadgeApplicationBadgeModal';
import BadgeQuestions from '../BadgeQuestions/BadgeQuestions';

export function getBadgeTypeAndStatus({ badgeTypeStatus, active, collected, badgeTypeDisplayName }) {
  if (active && collected) {
    return (
      <div>
        <span>{badgeTypeDisplayName}</span>
        <i style={{ color: colors.danger, paddingLeft: 5 }}>(retained)</i>
      </div>
    );
  }
  return <div>{badgeTypeStatus}</div>;
}

function parseDate(value, timezone) {
  return value ? moment.tz(value, timezone).format('MM/DD/YYYY') : 'N/A';
}

const defaultBadgeDetails = {
  id: -1,
  uuid: '',
  badgeHolderUuid: 'Unknown',
  badgeHolderName: 'Unknown',
  authorizedSignatoryName: 'Unknown',
  badgeId: 'Unknown',
  badgeTypeStatus: 'Unknown',
  badgeDates: 'Unknown',
  collectionDate: 'Unknown',
  cardNumber: 'Unknown',
  driving: 'Unknown',
  parking: 'Unknown',
  comments: [],
  isAssociatedToPacs: false,
};

let requestTimeout = null;
let apiAbortController = null;

export default function BadgeDetailsModal({ isOpen, onClose, state, api, badge, history }) {
  const app = useContext(AppContext);
  const hasPrintingFeature = hasFeature('printing', app.airport?.config?.features || []);
  const { warningColor, primaryColor, dangerColor, successColor } = state;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReassignSignatoryModalOpen, setIsReassignSignatoryModalOpen] = useState(false);
  const [isModifyEndorsementsModalOpen, setIsModifyEndorsementsModalOpen] = useState(false);
  const [isViewPersonModalOpen, setIsViewPersonModalOpen] = useState(false);
  const [isEditBadgeDataModalOpen, setIsEditBadgeDataModalOpen] = useState(false);
  const [isFlagBadgeModalOpen, setIsFlagBadgeModalOpen] = useState(false);
  const [isDeactivateBadgeModalOpen, setIsDeactivateBadgeModalOpen] = useState(false);
  const [isReceiveBadgeModalOpen, setIsReceiveBadgeModalOpen] = useState(false);
  const [isActivateBadgeModalOpen, setIsActivateBadgeModalOpen] = useState(false);
  const [isMarkBadgeNotIssuedModalOpen, setIsMarkBadgeNotIssuedModalOpen] = useState(false);
  const [badgeDetails, setBadgeDetails] = useState({ ...defaultBadgeDetails });
  const [editTitle, setEditTitle] = useState('');
  const [isCommentsExpanded, setIsCommentsExpanded] = useState(false);
  const [isRetainBadgeModalOpen, setIsRetainBadgeModalOpen] = useState(false);
  const [isDeactivateUserModalOpen, setIsDeactivateUserModalOpen] = useState(false);
  const [isCancelRapbackModalOpen, setIsCancelRapbackModalOpen] = useState(false);
  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState(false);
  const [badgeTypes, setBadgeTypes] = useState([]);
  const [isPrintBadgeModalOpen, setIsPrintBadgeModalOpen] = useState(false);

  const onReplaceModalOpen = () => {
    if (!badgeTypes?.length) {
      app.api.toggleLoading(true);
      badgeTypeApi
        .activeBadgeTypes()
        .then(({ data }) => {
          setBadgeTypes(data);
          showIssueBadgeWarning();
        })
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    } else {
      showIssueBadgeWarning();
    }
  };

  const showCorrectBadgeCreateModal = () => {
    const isPacsEnabled = !!app?.airport?.isPacsEnabled;
    if (!isPacsEnabled && !hasPrintingFeature) {
      setBadgeDetails({ ...badgeDetails, cardNumber: null });
      setIsReplaceModalOpen(true);
    } else if (!isPacsEnabled && hasPrintingFeature) {
      setIsPrintBadgeModalOpen(true);
    } else if (isPacsEnabled && !hasPrintingFeature) {
      setIsReplaceModalOpen(true);
    } else if (isPacsEnabled && hasPrintingFeature) {
      setIsPrintBadgeModalOpen(true);
    }
  };

  const showIssueBadgeWarning = () => {
    if (!badgeDetails?.badgeHolderPacsId && app?.airport?.isPacsEnabled) {
      app.api.confirmMessage(
        'Missing PACS Identifier',
        <Alert color="danger">
          This person has not been associated with PACS. This usually indicates they have never completed a Badge
          Application. Please start with a new Badge Application.
        </Alert>,
        false,
        false
      );
    } else {
      app.api.confirmMessage(
        'Are you sure?',
        <Alert color="warning">
          Issuing a badge directly from this screen will bypass the standard compliance checks. In almost all cases, you
          should initiate a Badge Application.
        </Alert>,
        () => {
          showCorrectBadgeCreateModal();
        },
        null,
        false,
        'Continue'
      );
    }
  };

  const createMisprintedBadge = ({ number, cardNumber, expiration, template }) => {
    const now = moment.tz(app.timezone).format('YYYY-MM-DD HH:mm:ss');
    const badge = {
      badge_holder_uuid: badgeDetails.badgeHolderUuid,
      authorized_signatory_profile_uuid: badgeDetails.authorizedSignatoryProfileUuid,
      type: badgeDetails.badgeTypeDetails.id,
      status: 'misprint',
      group: null,
      number: number || '???',
      card_serial_number: cardNumber || '???',
      effective: now,
      expires: expiration,
      driving: 'none',
      escort: 0,
      leo: badgeDetails.isLeo ? 1 : 0,
      parking: 0,
      collected: now,
      template: template || null,
    };
    app.api.toggleLoading(true);
    BadgeCreateRequest('authenticated-user', badge)
      .then((success, message) => {
        if (success === false) handleError({ message: message });
      })
      .catch(error => handleError({ error, message: 'Badge could not be created' }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onPrintBadgeModalClose = data => {
    if (!data) {
      return setIsPrintBadgeModalOpen(false);
    }

    if (data.isMisprint) {
      createMisprintedBadge(data);
    } else {
      const badge = { ...badgeDetails };

      const { number, cardNumber, expiration, isEscort, driving, template } = data;
      if (number) badge.badgeNumber = number;
      if (cardNumber) badge.cardNumber = cardNumber;
      if (expiration) badge.expirationDate = expiration;
      if (template) badge.template = template;
      if (isEscort === true || isEscort === false) badge.isEscort = isEscort;
      if (driving) badge.driving = driving;

      setBadgeDetails(badge);
      setIsPrintBadgeModalOpen(false);
      setIsReplaceModalOpen(true);
    }
  };

  const onReplaceModalClose = shouldReload => {
    setIsReplaceModalOpen(false);
    if (shouldReload === true) {
      getBadgeDetails();
    }
  };

  const onDeactivateUserModalClose = () => {
    setIsDeactivateUserModalOpen(false);
  };

  const onCancelRapbackModalClose = () => {
    setIsCancelRapbackModalOpen(false);
    handleNoActiveBadgesPrompt(badgeDetails);
  };

  const determineIfRapbackIsCancellable = () => {
    app.api.toggleLoading(true);
    peopleApi
      .isRapbackCancellable({ userUuid: badgeDetails.badgeHolderUuid })
      .then(({ success, isRapbackCancellable }) => {
        if (success && isRapbackCancellable) setIsCancelRapbackModalOpen(true);
        else handleNoActiveBadgesPrompt(badgeDetails);
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const handleNoActiveBadgesPrompt = ({ collected, numActiveBadges }) => {
    if (badge.isBadgeHolderActive === false || badge.isBadgeHolderActive === 0) return;
    if (!(collected !== null && collected !== false && numActiveBadges === 0)) return;

    setIsDeactivateUserModalOpen(true);
  };

  const onRetainBadgeModalClose = ({ shouldReload }) => {
    setIsRetainBadgeModalOpen(false);
    if (shouldReload) getBadgeDetails();
  };

  const getBadgeStatusDetails = (status, type) => {
    const badgeStatus = app.badgeStatuses.find(x => x.value === status) || { label: 'Unknown', value: 'unknown' };
    return {
      badgeStatus: badgeStatus.value,
      badgeTypeStatus: `${type || badgeDetails.badgeType} (${badgeStatus.label})`,
    };
  };

  const onModalClose = () => {
    setIsCommentsExpanded(false);
    setIsModalOpen(false);
    setIsReassignSignatoryModalOpen(false);
    setIsViewPersonModalOpen(false);
    setIsEditBadgeDataModalOpen(false);
    setIsFlagBadgeModalOpen(false);
    setIsDeactivateBadgeModalOpen(false);
    setIsReceiveBadgeModalOpen(false);
    setBadgeDetails({ ...defaultBadgeDetails });
    onClose();
  };

  const onViewPersonModalClose = () => {
    setIsViewPersonModalOpen(false);
  };

  const onViewPerson = () => {
    setIsViewPersonModalOpen(true);
  };

  const onReassignSignatoryClose = () => {
    setIsReassignSignatoryModalOpen(false);
  };

  const onReassignSignatorySave = ({ newSignatory, newSignatoryCompany, newSignatoryName, comments }) => {
    setBadgeDetails({
      ...badgeDetails,
      comments,
      authorizedSignatoryUuid: newSignatory.uuid,
      authorizedSignatoryProfileId: newSignatory.profile_id,
      authorizedSignatoryName: newSignatoryName,
      authorizedSignatoryCompany: newSignatoryCompany,
    });
    setIsReassignSignatoryModalOpen(false);
  };

  const onModifyEndorsementsModalClose = () => {
    setIsModifyEndorsementsModalOpen(false);
  };

  const onModifyEndorsementsModalSave = ({ parking, driving, isEscort, isLeo, comments }) => {
    const newBadgeData = { ...badgeDetails, comments };
    newBadgeData.parking = parking ? 'Yes' : 'No';
    newBadgeData.originalParking = parking;
    newBadgeData.driving = driving || 'error';
    newBadgeData.originalDriving = driving;
    newBadgeData.isEscort = isEscort;
    newBadgeData.isLeo = isLeo;

    setBadgeDetails(newBadgeData);
    setIsModifyEndorsementsModalOpen(false);
  };

  const onFlagBadgeModalClose = () => {
    setIsFlagBadgeModalOpen(false);
  };

  const onFlagBadgeModalSave = ({ flagged, comments }) => {
    setBadgeDetails({ ...badgeDetails, flagged, comments });
    setIsFlagBadgeModalOpen(false);
  };

  const onDeactivateBadgeModalClose = () => {
    setIsDeactivateBadgeModalOpen(false);
  };

  const onDeactivateBadgeModalSave = ({ status, active, collected, deactivationDate, numActiveBadges, ...rest }) => {
    setBadgeDetails({
      ...badgeDetails,
      ...getBadgeStatusDetails(status),
      active: active ? 1 : 0,
      collected,
      collectionDate: parseDate(collected, app.timezone),
      deactivationDate: parseDate(deactivationDate, app.timezone),
      numActiveBadges,
      ...rest,
    });
    setIsDeactivateBadgeModalOpen(false);
    if (!active && collected) {
      determineIfRapbackIsCancellable();
    } else {
      app.api.toggleLoading(false);
      handleNoActiveBadgesPrompt({ collected, numActiveBadges });
    }
  };

  const onReceiveBadgeModalClose = () => {
    setIsReceiveBadgeModalOpen(false);
  };

  const onActivateBadgeModalSave = ({ status, active, collected, deactivationDate, ...rest }) => {
    setBadgeDetails({
      ...badgeDetails,
      ...getBadgeStatusDetails(status),
      active: active ? 1 : 0,
      collected,
      collectionDate: parseDate(collected, app.timezone),
      deactivationDate: parseDate(deactivationDate, app.timezone),
      ...rest,
    });
    setIsActivateBadgeModalOpen(false);
  };

  const onActivateBadgeModalClose = () => {
    setIsActivateBadgeModalOpen(false);
  };

  const onReceiveBadgeModalSave = ({ status, active, collected, deactivationDate, numActiveBadges, ...rest }) => {
    setBadgeDetails({
      ...badgeDetails,
      ...getBadgeStatusDetails(status),
      active: active ? 1 : 0,
      collected,
      collectionDate: parseDate(collected, app.timezone),
      deactivationDate: parseDate(deactivationDate, app.timezone),
      ...rest,
    });
    setIsReceiveBadgeModalOpen(false);
    if (!active && collected) {
      determineIfRapbackIsCancellable();
    } else {
      app.api.toggleLoading(false);
      handleNoActiveBadgesPrompt({ collected, numActiveBadges });
    }
  };

  const onEditBadgeDataModalClose = () => {
    setIsEditBadgeDataModalOpen(false);
  };

  const onEditBadgeDataModalSave = ({ comments, cardNumber, group, effectiveDate, expirationDate, badgeId }) => {
    const newBadgeData = { ...badgeDetails, comments };

    const startDate = effectiveDate
      ? moment.tz(effectiveDate, app.timezone).format('MM/DD/YYYY')
      : moment.tz(badgeDetails.startDate, app.timezone).format('MM/DD/YYYY');
    const endDate = expirationDate
      ? moment.tz(expirationDate, app.timezone).format('MM/DD/YYYY')
      : moment.tz(badgeDetails.endDate, app.timezone).format('MM/DD/YYYY');

    if (cardNumber) newBadgeData.cardNumber = cardNumber;
    if (group) newBadgeData.group = group;
    if (badgeId) newBadgeData.badgeId = badgeId;
    if (effectiveDate) newBadgeData.startDate = effectiveDate;
    if (expirationDate) newBadgeData.endDate = expirationDate;
    newBadgeData.badgeDates = `${startDate} - ${endDate}`;

    setBadgeDetails(newBadgeData);
    setIsEditBadgeDataModalOpen(false);
  };

  const onMarkBadgeNotIssuedModalClose = () => {
    setIsMarkBadgeNotIssuedModalOpen(false);
  };

  const onMarkBadgeNotIssuedModalSave = ({ status, collected, deactivationDate, ...rest }) => {
    setBadgeDetails({
      ...badgeDetails,
      ...getBadgeStatusDetails(status),
      active: 0,
      collected,
      collectionDate: parseDate(collected, app.timezone),
      deactivationDate: parseDate(deactivationDate, app.timezone),
      ...rest,
    });
    setIsMarkBadgeNotIssuedModalOpen(false);
  };

  const getBadgeDetails = () => {
    if (requestTimeout) {
      if (apiAbortController) apiAbortController.abort();
      clearTimeout(requestTimeout);
    }

    app.api.toggleLoading(true);
    requestTimeout = setTimeout(() => {
      apiAbortController = new AbortController();
      app.api.toggleLoading(true);
      badgeApi
        .getDetails({ badgeUuid: badge.uuid, signal: apiAbortController.signal })
        .then(({ success, badge }) => {
          if (!success) return handleError({ message: 'A problem occurred while trying to open this badge' });

          const startDate = badge.effective ? moment.tz(badge.effective, app.timezone).format('MM/DD/YYYY') : 'unknown';
          const endDate = badge.expires ? moment.tz(badge.expires, app.timezone).format('MM/DD/YYYY') : 'unknown';

          setBadgeDetails({
            ...badge,
            ...getBadgeStatusDetails(badge.status, badge.badgeTypeDisplayName),
            badgeDates: `${startDate} - ${endDate}`,
            startDate: badge.effective,
            endDate: badge.expires,
            collectionDate: parseDate(badge.collected, app.timezone),
            deactivationDate: parseDate(badge.deactivationDate, app.timezone),
            originalDriving: badge.driving,
            driving: badge.driving,
            originalParking: badge.parking,
            parking: badge.parking ? 'Yes' : 'No',
            badgeHolder: { uuid: badge.badgeHolderUuid },
          });
        })
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    }, 500);
  };

  const performDeactivateAction = () => {
    if (badgeDetails.isBadgeUnderAudit) {
      app.api.confirmMessage(
        'Badge Currently Under Audit',
        <Alert color="warning">
          <p>
            Performing this action while the badge is under audit will circumvent the Signatory's role in the audit.
          </p>
          <p>
            An Action Item will be created in order to determine if the Signatory's authorization for this badge remains
            valid.
          </p>
          <p>
            To maintain the integrity of the audit, record this as a comment on the individual audit for this badge.
          </p>
        </Alert>,
        () => {
          setIsDeactivateBadgeModalOpen(true);
        }
      );
    } else {
      setIsDeactivateBadgeModalOpen(true);
    }
  };

  const performAction = action => () => {
    switch (action) {
      case 'reassignSignatory': {
        setIsReassignSignatoryModalOpen(true);
        break;
      }
      case 'modifyEndorsements': {
        setIsModifyEndorsementsModalOpen(true);
        break;
      }
      case 'flag': {
        setIsFlagBadgeModalOpen(true);
        break;
      }
      case 'receive': {
        setIsReceiveBadgeModalOpen(true);
        break;
      }
      case 'deactivate': {
        performDeactivateAction();
        break;
      }
      case 'activate': {
        setIsActivateBadgeModalOpen(true);
        break;
      }
      case 'markNotIssued': {
        setIsMarkBadgeNotIssuedModalOpen(true);
        break;
      }
      case 'retain': {
        setIsRetainBadgeModalOpen(true);
        break;
      }
      case 'replace': {
        onReplaceModalOpen();
        break;
      }
      default: {
        return false;
      }
    }
  };

  const onEditBadgeData = title => () => {
    if (app.airport && app.airport.isPacsEnabled && title !== 'Group') {
      return app.api.confirmMessage(
        'PACS Integration Notice',
        <div>
          <p>PACS Integration is enabled.</p>
          <p>Badge details must be updated on PACS and cannot be modified in AirBadge.</p>
        </div>,
        () => false,
        false
      );
    } else if (title === 'Badge ID') {
      return app.api.confirmMessage(
        'TSC Integration Notice',
        <div>
          <p>
            Because TSC already has a record of this badge, AirBadge cannot update the {title} without duplicating the
            badge at TSC.
          </p>
          <p>
            You will need to use the <i>Replace Badge</i> functionality to fix the {title}.
          </p>
        </div>,
        () => false,
        false
      );
    }
    setEditTitle(title);
    setIsEditBadgeDataModalOpen(true);
    return false;
  };

  const isReactivateBadgeDisabled = () => {
    if (badgeDetails.badgeStatus === 'suspended') {
      return false;
    }

    const date30DaysAgo = moment().subtract(30, 'days');
    const deactivationDate = moment(badgeDetails.deactivationDate, 'MM/DD/YYYY');
    return !(deactivationDate.isAfter(date30DaysAgo) || deactivationDate.isSame(date30DaysAgo));
  };

  const isReplaceBadgeDisabled = () => {
    if (!badge) {
      return true;
    }

    const { active, expires, status } = badge;
    const now = moment.tz(app.timezone);
    const thirtyDaysFromNow = moment.tz(app.timezone).add(30, 'days');
    const expiresMoment = moment.tz(expires, app.timezone);
    const isExpiringSoon = expiresMoment.isBefore(thirtyDaysFromNow) && expiresMoment.isAfter(now);

    if (active || ['lost', 'stolen', 'destroyed', 'suspended'].includes(status)) {
      return isExpiringSoon;
    }
    return true;
  };

  const getDrivingOption = () => {
    const drivingOptions = getDrivingOptions(badgeDetails?.badgeTypeDetails?.config?.endorsements?.driving);
    return drivingOptions.find(option => option.value === badgeDetails?.driving) || 'ERROR';
  };

  const buildModal = badgeDetails => {
    if (badgeDetails?.id === -1) return null;

    const isPacsEnabled = !!app?.airport?.isPacsEnabled;

    let expiration = moment().endOf('month').add(2, 'years').format('YYYY-MM-DD');
    if (badgeDetails?.badgeTypeDetails?.config) {
      expiration = getDefaultBadgeExpiration({ badgeType: badgeDetails?.badgeTypeDetails, timezone: app.timezone });
    }

    let shouldReturnBadgeCreateModal = false;
    let shouldReturnPacsModal = false;
    let shouldReturnPrintingModal = false;

    if (!isPacsEnabled && !hasPrintingFeature) {
      shouldReturnBadgeCreateModal = true;
      shouldReturnPacsModal = false;
      shouldReturnPrintingModal = false;
    } else if (!isPacsEnabled && hasPrintingFeature) {
      shouldReturnBadgeCreateModal = true;
      shouldReturnPacsModal = false;
      shouldReturnPrintingModal = true;
    } else if (isPacsEnabled && !hasPrintingFeature) {
      shouldReturnBadgeCreateModal = false;
      shouldReturnPacsModal = true;
      shouldReturnPrintingModal = false;
    } else if (isPacsEnabled && hasPrintingFeature) {
      let features = app?.airport?.pacsConfig?.features || [];
      const isPacsActivationEnabled = features.includes('allow_activation_from_airbadge');
      shouldReturnBadgeCreateModal = isPacsActivationEnabled;
      shouldReturnPacsModal = !isPacsActivationEnabled;
      shouldReturnPrintingModal = true;
    }

    return (
      <>
        {shouldReturnBadgeCreateModal && (
          <BadgeCreateModal
            isOpen={isReplaceModalOpen}
            onClose={onReplaceModalClose}
            shouldSkipDefaultEndorsementSelection={hasPrintingFeature}
            predefinedBadge={{
              renewingBadgeId: badgeDetails.uuid,
              person: {
                uuid: badgeDetails.badgeHolderUuid,
                firstName: badgeDetails.badgeHolderFirstName,
                lastName: badgeDetails.badgeHolderLastName,
                pacsPersonId: badgeDetails.badgeHolderPacsId,
                original: {
                  airportPersonId: badgeDetails.badgeHolderAirportPersonId,
                  dob: badgeDetails.badgeHolderDob,
                },
              },
              badge: {
                status: 'in_use',
                authorizedSignatoryProfileUuid: badgeDetails.authorizedSignatoryProfileUuid,
                type: badgeDetails.renewsIntoBadgeType?.name || badgeDetails.badgeTypeDisplayName,
                badgeNumber: badgeDetails.badgeNumber || '',
                cardNumber: badgeDetails.cardNumber || '',
                expirationDate: badgeDetails.expirationDate || null,
                driving: badgeDetails.driving,
                parking: badgeDetails.parking,
                escort: badgeDetails.isEscort || false,
                leo: badgeDetails.isLeo || false,
                template: badgeDetails.template || null,
              },
            }}
            badgeTypes={badgeTypes}
          />
        )}

        {shouldReturnPacsModal && (
          <ReplacePacsBadgeModal
            isOpen={isReplaceModalOpen}
            onClose={onReplaceModalClose}
            badgeTypes={badgeTypes}
            originalBadgeType={badgeDetails?.badgeTypeDetails}
            defaultBadgeHolder={{
              uuid: badgeDetails.badgeHolderUuid,
              pacsPersonId: badgeDetails.badgeHolderPacsId,
              airportPersonId: badgeDetails.badgeHolderAirportPersonId,
              tscPersonId: badgeDetails.badgeHolderAirportPersonId,
              name: `${badgeDetails.badgeHolderFirstName} ${badgeDetails.badgeHolderLastName}`,
              full_name: `${badgeDetails.badgeHolderFirstName} ${badgeDetails.badgeHolderLastName}`,
            }}
          />
        )}

        {shouldReturnPrintingModal && (
          <PrintBadgeModal
            isOpen={isPrintBadgeModalOpen}
            onClose={onPrintBadgeModalClose}
            badgeType={badgeDetails?.badgeTypeDetails}
            badgeData={{
              companyName: badgeDetails?.authorizedSignatoryCompany || '',
              number: '',
              firstName: badgeDetails?.badgeHolderFirstName || '',
              middleName: '',
              lastName: badgeDetails?.badgeHolderLastName || '',
              fullName: `${badgeDetails?.badgeHolderFirstName || ''} ${badgeDetails?.badgeHolderLastName || ''}`.trim(),
              airportPersonId: badgeDetails?.badgeHolderAirportPersonId || '',
              dateOfBirth: badgeDetails?.badgeHolderDob || '',
              expiration,
              endorsements: {
                driving: badgeDetails?.driving || 'none',
                isEscort: !!badgeDetails?.isEscort,
                isParking: false,
                isLeo: !!badgeDetails?.isLeo,
              },
            }}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    if (!isOpen || !badge) return;
    getBadgeDetails();
    setIsModalOpen(true);

    return () => {
      setIsPrintBadgeModalOpen(false);
      setIsReplaceModalOpen(false);
    };
  }, [isOpen, badge]);

  return (
    <>
      <Modal size="lg" isOpen={isModalOpen} toggle={onModalClose}>
        <CustomModalHeader toggle={onModalClose}>
          Badge Details{' '}
          {badgeDetails.flagged ? <FlagIcon style={{ color: warningColor, position: 'relative', top: -3 }} /> : null}
        </CustomModalHeader>
        <ModalBody>
          <RenewalPreventedNotice badge={badgeDetails} />
          {badgeDetails.hasRenewalInProgress && <Alert>Badge renewal in progress</Alert>}
          {badgeDetails.isBadgeUnderAudit ? (
            <Alert color="warning">
              <Row>
                <Col className="text-center" style={{ paddingTop: 8, paddingBottom: 8 }}>
                  <i className="fa fa-exclamation-triangle mr-2 text-warning" aria-hidden="true"></i>
                  <i>This badge is currently under audit</i>
                  <i className="fa fa-exclamation-triangle ml-2 text-warning" aria-hidden="true"></i>
                </Col>
              </Row>
            </Alert>
          ) : null}
          <Row>
            <Col>
              <ReadOnlyFormData
                label="Badge Holder"
                value={
                  <>
                    <button type="button" className="btn btn-link" style={{ padding: 0 }} onClick={onViewPerson}>
                      {badgeDetails.badgeHolderName}
                    </button>
                    <BadgesLabel numActiveBadges={badgeDetails.badgeHolderNumActiveBadges} />
                  </>
                }
              />
            </Col>
            <Col>
              <ReadOnlyFormData
                label="Company (Authorized Signatory)"
                value={`${badgeDetails.authorizedSignatoryCompany} (${badgeDetails.authorizedSignatoryName})`}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ReadOnlyFormData
                label="Badge ID"
                value={badgeDetails.badgeId}
                onEdit={onEditBadgeData('Badge ID')}
                skipTextTransform
              />
            </Col>
            <Col>
              <ReadOnlyFormData label="Type (Status)" value={getBadgeTypeAndStatus(badgeDetails)} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ReadOnlyFormData
                label="Group"
                value={badgeDetails.group}
                onEdit={onEditBadgeData('Group')}
                skipTextTransform
              />
            </Col>
            <Col>
              <ReadOnlyFormData label="Deactivation Date" value={badgeDetails.deactivationDate} skipTextTransform />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ReadOnlyFormData
                label="Active Dates"
                value={badgeDetails.badgeDates}
                onEdit={onEditBadgeData('Active Dates')}
              />
            </Col>
            <Col>
              <ReadOnlyFormData label="Collection Date" value={badgeDetails.collectionDate} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ReadOnlyFormData
                label="Card Number"
                value={badgeDetails.cardNumber}
                onEdit={onEditBadgeData('Card Number')}
                skipTextTransform
              />
            </Col>
            {app.airport && app.airport.isPacsEnabled && (
              <Col>
                <ReadOnlyFormData
                  label="Physical Access Control System (PACS) ID"
                  value={
                    badgeDetails.isAssociatedToPacs ? (
                      <div style={{ display: 'inline-block' }}>
                        <FontAwesomeIcon className="fa fa-check" color={colors.success} style={{ fontSize: '1rem' }} />{' '}
                        Associated
                      </div>
                    ) : (
                      <div style={{ display: 'inline-block' }}>
                        <FontAwesomeIcon className="fa fa-ban" color={colors.danger} style={{ fontSize: '1rem' }} /> Not
                        associated
                      </div>
                    )
                  }
                  skipTextTransform
                />
              </Col>
            )}
          </Row>
          <Row className="mt-3">
            <Col>
              <DrivingEndorsementPicker
                isReadOnly={true}
                context={{
                  drivingEndorsementConfig: badgeDetails?.badgeTypeDetails?.config?.endorsements?.driving,
                  drivingSelection: getDrivingOption(),
                  setDrivingSelection: () => false,
                  shouldSkipDefaultSelection: true,
                  data: { badgeType: badgeDetails?.badgeTypeDetails },
                }}
              />
            </Col>
            <Col>
              <Row>
                <Col>
                  <EscortEndorsementPicker
                    isReadOnly={true}
                    context={{
                      escortEndorsementConfig: badgeDetails?.badgeTypeDetails?.config?.endorsements?.escort,
                      escortSelection: badgeDetails.isEscort ? { label: 'yes', value: 1 } : { label: 'no', value: 0 },
                      setEscortSelection: () => false,
                      shouldSkipDefaultSelection: true,
                      data: { badgeType: badgeDetails?.badgeTypeDetails },
                    }}
                  />
                </Col>
                <Col>
                  <ReadOnlyFormData label="LEO" value={badgeDetails.isLeo ? 'Yes' : 'No'} />
                </Col>
              </Row>
            </Col>
          </Row>

          <div>&nbsp;</div>

          <BadgeQuestions
            displayMode="view"
            question1Value={badgeDetails?.doors}
            question2Value={badgeDetails?.purpose}
            sm={12}
            md={6}
          />

          <div>&nbsp;</div>

          <div className="separator" style={{ marginTop: 15 }}>
            ACTIONS
          </div>

          <Row>
            <style>{`
            .MuiListItemIcon-root {
              min-width: 40px;
            }
          `}</style>
            <Col>
              <List>
                <StartRenewalAction
                  badge={badgeDetails}
                  history={history}
                  onEmailChanged={email =>
                    setBadgeDetails({
                      ...badgeDetails,
                      badgeHolderEmail: email,
                    })
                  }
                />
                <PreventRenewalAction
                  badge={badgeDetails}
                  onChange={updatedBadge => {
                    setBadgeDetails(updatedBadge);
                    onClose();
                  }}
                />
                {badgeDetails.badgeStatus === 'suspended' ? (
                  <ListItem button onClick={performAction('retain')}>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        className="fa-solid fa-box-archive"
                        color={colors.primary}
                        style={{ fontSize: '1.2em' }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Retain Badge" />
                  </ListItem>
                ) : (
                  !!badgeDetails.active && (
                    <ListItem button onClick={performAction('retain')}>
                      <ListItemIcon>
                        <FontAwesomeIcon
                          className={badgeDetails.collected ? 'fa-solid fa-box-open' : 'fa-solid fa-box-archive'}
                          color={colors.primary}
                          style={{ fontSize: '1.2em' }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={badgeDetails.collected ? 'Release Retained Badge' : 'Retain Badge'} />
                    </ListItem>
                  )
                )}
                <ListItem
                  button
                  onClick={performAction('receive')}
                  disabled={!(!badgeDetails.collected && !badgeDetails.active)}
                >
                  <ListItemIcon>
                    <MoveToInboxIcon style={{ color: primaryColor }} />
                  </ListItemIcon>
                  <ListItemText primary="Receive Badge" />
                </ListItem>
                {badgeDetails.badgeStatus === 'pending' && (
                  <ListItem button onClick={performAction('markNotIssued')}>
                    <ListItemIcon>
                      <ErrorIcon style={{ color: dangerColor }} />
                    </ListItemIcon>
                    <ListItemText primary="Mark Not Issued" />
                  </ListItem>
                )}
                {badgeDetails.active === 0 && (
                  <ListItem button onClick={performAction('activate')} disabled={isReactivateBadgeDisabled()}>
                    <ListItemIcon>
                      <i
                        className="fa fa-id-badge primary-text"
                        style={{ fontSize: 20, margin: 0, position: 'relative', left: 5, color: successColor }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Activate Badge" />
                  </ListItem>
                )}
                <ListItem button onClick={performAction('deactivate')} disabled={badgeDetails.active !== 1}>
                  <ListItemIcon>
                    <ReportIcon style={{ color: dangerColor }} />
                  </ListItemIcon>
                  <ListItemText primary="Deactivate Badge" />
                </ListItem>
                <ListItem button onClick={performAction('flag')}>
                  <ListItemIcon>
                    {badgeDetails.flagged ? (
                      <BlockIcon style={{ color: warningColor }} />
                    ) : (
                      <FlagIcon style={{ color: warningColor }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={badgeDetails.flagged ? 'Remove Flag' : 'Add Flag'} />
                </ListItem>
              </List>
            </Col>
            <Col>
              <List>
                <ListItem button onClick={performAction('replace')} disabled={isReplaceBadgeDisabled()}>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      className="fa-solid fa-retweet"
                      color={colors.danger}
                      style={{ fontSize: '1.5em' }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Replace Lost / Damaged Badge" />
                </ListItem>
                <Feature id="printing">
                  <ViewPrintedBadgeAction badge={badgeDetails} />
                </Feature>
                <ListItem button onClick={performAction('reassignSignatory')} disabled={!badgeDetails.active}>
                  <ListItemIcon>
                    <TransferWithinAStationIcon style={{ color: primaryColor }} />
                  </ListItemIcon>
                  <ListItemText primary="Reassign Signatory" />
                </ListItem>
                <ListItem button onClick={performAction('modifyEndorsements')} disabled={!badgeDetails.active}>
                  <ListItemIcon>
                    <BallotIcon style={{ color: primaryColor }} />
                  </ListItemIcon>
                  <ListItemText primary="Modify Endorsements / Extras" />
                </ListItem>
                <FixInvalidEmailAction badge={badgeDetails} onEmailFixed={() => getBadgeDetails()} />
              </List>
            </Col>
          </Row>

          <BadgeDetailsAdminActionsPanel
            badge={badgeDetails}
            onActionDone={args => {
              if (args?.shouldReload) getBadgeDetails();
            }}
          />

          <Row className="mt-4">
            <Col>
              <Collapsible
                id="badge-comments"
                title="Comments / Activity"
                icon={<i className="fa fa-comments-o mr-2" />}
                expanded={isCommentsExpanded}
                onChange={() => setIsCommentsExpanded(!isCommentsExpanded)}
                style={{ backgroundColor: '#f0f0f0' }}
              >
                <BadgeComments badge={badgeDetails} appFunctions={api} user={state.user} appState={state} />
              </Collapsible>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="secondary" onClick={onModalClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <PeopleListEditModal
        isOpen={isViewPersonModalOpen}
        onClose={onViewPersonModalClose}
        selectedPerson={badgeDetails.badgeHolder}
      />

      <ReassignSignatoryModal
        isOpen={isReassignSignatoryModalOpen}
        onClose={onReassignSignatoryClose}
        onSave={onReassignSignatorySave}
        badge={badgeDetails}
      />

      <ModifyEndorsementsModal
        isOpen={isModifyEndorsementsModalOpen}
        onClose={onModifyEndorsementsModalClose}
        onSave={onModifyEndorsementsModalSave}
        badge={badgeDetails}
      />

      <FlagBadgeModal
        isOpen={isFlagBadgeModalOpen}
        onClose={onFlagBadgeModalClose}
        onSave={onFlagBadgeModalSave}
        badge={badgeDetails}
      />

      <DeactivateBadgeModal
        isOpen={isDeactivateBadgeModalOpen}
        onClose={onDeactivateBadgeModalClose}
        onSave={onDeactivateBadgeModalSave}
        badge={badgeDetails}
      />

      <ReceiveBadgeModal
        isOpen={isReceiveBadgeModalOpen}
        onClose={onReceiveBadgeModalClose}
        onSave={onReceiveBadgeModalSave}
        badge={badgeDetails}
      />

      <ActivateBadgeModal
        isOpen={isActivateBadgeModalOpen}
        onClose={onActivateBadgeModalClose}
        onSave={onActivateBadgeModalSave}
        badge={badgeDetails}
      />

      <MarkBadgeNotIssuedModal
        isOpen={isMarkBadgeNotIssuedModalOpen}
        onClose={onMarkBadgeNotIssuedModalClose}
        onSave={onMarkBadgeNotIssuedModalSave}
        badge={badgeDetails}
      />

      <EditBadgeDataModal
        isOpen={isEditBadgeDataModalOpen}
        onClose={onEditBadgeDataModalClose}
        onSave={onEditBadgeDataModalSave}
        badge={badgeDetails}
        title={editTitle}
      />

      <RetainBadgeModal isOpen={isRetainBadgeModalOpen} onClose={onRetainBadgeModalClose} badge={badgeDetails} />

      <DeactivateUserModal
        isOpen={isDeactivateUserModalOpen}
        onClose={onDeactivateUserModalClose}
        user={badgeDetails}
      />

      <CancelRapbackModal isOpen={isCancelRapbackModalOpen} onClose={onCancelRapbackModalClose} user={badgeDetails} />

      {buildModal(badgeDetails)}
    </>
  );
}
