import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import HorizontalLinearStepper from '../../../../../shared/components/Stepper/HorizontalLinearStepper';
import AppointmentCreateRequest from '../../../../../ajax/Appointment/AppointmentCreateRequest';
import StaffGetAvailabilityRequest from '../../../../../ajax/Staff/StaffGetAvailabilityRequest';
import StaffGetAppointmentTypesRequest from '../../../../../ajax/Booking/BookingGetAvailableAppointmentTypesRequest';
import { handleError, isSmallScreen } from '../../../../../utils';

function getFullName(person) {
  if (person.fullName) {
    return person.fullName;
  }
  const firstName = person.firstName || person.first_name || '';
  const lastName = person.lastName || person.last_name || '';
  return `${firstName} ${lastName}`;
}

export default function BadgeApplicationAppointmentCreateModal({
  user,
  attendee = {},
  api,
  onClose,
  isOpen,
  timeZone,
  reload,
  onSave,
  badgeApplication,
  defaultTypeUuid,
}) {
  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);

  const [appointmentType, setAppointmentType] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [startDate, setStartDate] = useState(today);
  const [startTime, setStartTime] = useState(null);
  const [isLoadingAvailability, setIsLoadingAvailability] = useState(false);
  const holder = { label: getFullName(attendee), value: attendee.uuid };
  const staff = { value: null };

  const getAvailability = () => {
    if (appointmentType && startDate && holder) {
      setIsLoadingAvailability(true);
      StaffGetAvailabilityRequest(user, null, appointmentType, startDate, timeZone, null, holder)
        .then(({ availability }) => setAvailableSlots(availability))
        .catch(error => handleError({ error }))
        .finally(() => setIsLoadingAvailability(false));
    }
  };

  const getAppointmentType = defaultTypeUuid => {
    if (appointmentType) return;

    if (typeof defaultTypeUuid === 'object') {
      setAppointmentType({
        label: defaultTypeUuid.name,
        value: defaultTypeUuid.uuid,
      });
    } else {
      StaffGetAppointmentTypesRequest()
        .then(apptTypes => {
          apptTypes.forEach(apptType => {
            if (apptType.uuid === defaultTypeUuid) {
              setAppointmentType({
                label: apptType.name,
                value: apptType.uuid,
              });
            }
          });
        })
        .catch(error => handleError({ error }));
    }
  };

  const resetDefaults = () => {
    setIsLoadingAvailability(false);
    setAppointmentType(null);
    setStartDate(today);
    setStartTime(null);
    setAvailableSlots([]);
  };

  const closeModal = () => {
    onClose();
    resetDefaults();
  };

  const onCreateAppointmentClick = () => {
    const appointment = {
      staff: staff,
      appointmentType: appointmentType,
      startDate: startDate,
      startTime: startTime,
      timeZone: timeZone,
      user: holder,
      comment: document.getElementById('appointment-comment').value,
    };

    if (!appointmentType || !startDate || !startTime) {
      return handleError({ message: 'Missing required form data' });
    }

    api.toggleLoading(true);

    AppointmentCreateRequest(user, appointment, badgeApplication)
      .then(({ success, message }) => {
        if (success === false) {
          return handleError({ message: message || 'Appointment could not be created' });
        }

        onSave();
        closeModal();
        if (reload) reload();
      })
      .catch(error => handleError({ error }))
      .finally(() => api.toggleLoading(false));
  };

  const convertTime = time => {
    const hours = time > 1259 ? Math.floor(time / 100) - 12 : Math.floor(time / 100);
    const minutes = String(time).slice(-2) || '00';
    const meridian = time >= 1200 ? 'PM' : 'AM';
    return `${hours}:${minutes} ${meridian}`;
  };

  const CreateAppointmentStep1 = () => {
    // const [startTime, setStartTime] = useState(null);
    const timeSlot = (start, end) => {
      const selClass = start === startTime ? 'selected' : '';
      return (
        <div className={`time-slot ${selClass}`} key={`${start}-${end}`} onClick={() => setStartTime(start)}>
          {convertTime(start)}
        </div>
      );
    };
    return (
      <Container fluid={true}>
        <Row className="appointmentDateTime">
          <Col xs={12} sm={7} style={isSmallScreen() ? { textAlign: 'center' } : null}>
            <FormGroup>
              <DatePicker
                inline
                minDate={today}
                selected={startDate}
                onChange={date => {
                  date.setHours(0);
                  date.setMinutes(0);
                  date.setSeconds(0);

                  setStartDate(date);
                  setStartTime(null);
                }}
                className={'start-trigger'}
              />
            </FormGroup>
          </Col>

          <Col xs={12} sm={5} className="timeSlots">
            {isLoadingAvailability ? (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress color="primary" size={50} thickness={5} />
              </div>
            ) : (
              <FormGroup>{availableSlots.map(slot => timeSlot(slot.start, slot.end))}</FormGroup>
            )}
          </Col>
        </Row>
      </Container>
    );
  };

  const CreateAppointmentStep2 = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h5>{appointmentType ? appointmentType.label : ''}</h5>
        <p>
          {startDate.toDateString()} @ {convertTime(startTime)}
        </p>

        <div style={{ width: '75%', marginTop: 16 }}>
          <FormGroup>
            <Label>
              Notes for Badge Office <i style={{ fontSize: 'smaller' }}>(optional)</i>
            </Label>
            {/*
                TODO: Not using state for this input because the textarea loses keyboard focus with every keystroke
                and I don't have time to figure out why right now
            */}
            <Input id="appointment-comment" type="textarea" placeholder="" rows={5} />
          </FormGroup>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (isOpen && defaultTypeUuid) getAppointmentType(defaultTypeUuid);
  }, [isOpen, defaultTypeUuid]);

  useEffect(() => {
    if (isOpen && appointmentType?.value) {
      getAvailability();
    }
  }, [isOpen, appointmentType, startDate]);

  return (
    <Modal isOpen={isOpen} toggle={closeModal} size="lg" className="appointmentModal">
      <CustomModalHeader toggle={closeModal}>
        Schedule {`${appointmentType && appointmentType.label} ` || ''}Appointment
        <br />
        for <i>{holder && holder.label}</i>
      </CustomModalHeader>
      <ModalBody className="pt-0">
        <HorizontalLinearStepper
          steps={[<CreateAppointmentStep1 key={1} />, <CreateAppointmentStep2 key={2} />]}
          stepLabels={['Date & Time', 'Confirm Appointment']}
          validatedSteps={[Boolean(startTime && startDate), true]}
          finishCallback={() => {
            onCreateAppointmentClick();
          }}
          saveButtonLabel="Schedule Appointment"
        />
      </ModalBody>
    </Modal>
  );
}
