import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import { AppContext } from '../../../../../App';
import keysApi from '../../../../../ajax/Key/keysApi';
import { handleError } from '../../../../../utils';

const defaultSort = [{ id: 'issue_date', desc: false }];

export default function PersonEditKeys({ person, isOpen }) {
  const app = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [activeKeys, setActiveKeys] = useState([]);
  const [inactiveKeys, setInactiveKeys] = useState([]);

  const columns = [
    {
      id: 'key_name',
      Header: 'Key / Serial #',
      accessor: 'name',
      sortable: false,
    },
    {
      id: 'door',
      Header: 'Door / Type',
      accessor: 'door',
      sortable: false,
    },
    {
      id: 'issue_date',
      Header: 'Issued On',
      accessor: ({ issuedOnDate }) => moment.tz(issuedOnDate, app.timezone).format('MM/DD/YYYY'),
      width: 130,
      sortable: false,
    },
  ];

  const getActiveKeys = () => {
    return keysApi
      .getAll({
        data: {
          pageSize: 100,
          page: 0,
          sorted: defaultSort,
          filtered: [
            { id: 'user_uuid', value: person.uuid },
            { id: 'status', value: 'issued' },
          ],
        },
      })
      .then(({ data }) => setActiveKeys(data));
  };

  const getInactiveKeys = () => {
    return keysApi
      .getAll({
        data: {
          pageSize: 5,
          page: 0,
          sorted: defaultSort,
          filtered: [
            { id: 'user_uuid', value: person.uuid },
            { id: 'status', value: 'unissued' },
          ],
        },
      })
      .then(({ data }) => setInactiveKeys(data));
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      const activeKeysCall = getActiveKeys();
      const inactiveKeysCall = getInactiveKeys();
      Promise.all([activeKeysCall, inactiveKeysCall])
        .catch(error => handleError({ error }))
        .finally(() => setIsLoading(false));
    }
  }, [isOpen]);

  return (
    <Card>
      <CardBody>
        <div className="table-responsive" style={{ maxHeight: 150, minHeight: 150 }}>
          <ReactTable
            manual
            defaultSorted={defaultSort}
            className="border-0 -striped"
            loading={isLoading}
            data={activeKeys}
            columns={columns}
            pages={1}
            minRows={0}
            defaultPageSize={500}
            showPagination={false}
          />
        </div>

        <div className="separator mt-4 mb-4">Unissued Keys</div>

        <div className="table-responsive" style={{ maxHeight: 250, minHeight: 250 }}>
          <ReactTable
            manual
            defaultSorted={defaultSort}
            className="border-0 -striped"
            loading={isLoading}
            data={inactiveKeys}
            columns={columns}
            pages={1}
            minRows={0}
            defaultPageSize={500}
            showPagination={false}
          />
        </div>
      </CardBody>
    </Card>
  );
}
