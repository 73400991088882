import React, { createContext, useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import { Alert, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import moment from 'moment';
import 'moment-timezone';
import MuiFormGroup from '@material-ui/core/FormGroup';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { AppContext } from '../../../../App';
import request from '../../../../ajax/Request';
import BadgeCreateRequest from '../../../../ajax/Badge/BadgeCreateRequest';
import BadgesForUserRequest from '../../../../ajax/Badge/BadgesForUserRequest';
import AuthorizedSignatoryGetRequest from '../../../../ajax/AuthorizedSignatory/AuthorizedSignatoryGetRequest';
import ModalActions from '../../../../shared/components/ModalActions/ModalActions';
import CustomModalHeader from '../../../../shared/components/CustomModalHeader/CustomModalHeader';
import { buildFullName, handleError } from '../../../../utils';
import peopleApi from '../../../../ajax/People/peopleApi';
import AutoCompleteDropdown, {
  AutoCompleteUserOption,
  filterUserOptions,
} from '../../../../shared/components/AutoCompleteDropdown/AutoCompleteDropdown';
import badgeApi from '../../../../ajax/Badge/badgeApi';
import trim from 'lodash/trim';
import StaggeredDatePicker from '../../../../shared/components/DatePickers/StaggeredDatePicker';
import ReadOnlyFormData from '../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import BadgeEndorsementsPicker from '../../BadgeApplications/BadgeApplicationEndorsementsPicker/BadgeEndorsementsPicker';
import { getDrivingOptions } from '../../../../shared/BadgeTypes/drivingOptionsUtils';
import { uploadProfilePicForPerson } from '../../../../imageUtils';

// FYI: This logic is duplicated in DeactivateBadgeModal.js
//      It needs to be kept in sync or common logic needs to be shared in some way!
export const reasons = [
  { label: 'Terminated Employment', value: 'Terminated' },
  {
    label: 'Expired',
    value: 'Expired',
  },
  { label: 'Surrendered', value: 'Surrendered' },
  { label: 'Lost', value: 'Lost' },
  {
    label: 'Stolen',
    value: 'Stolen',
  },
  { label: 'Unaccounted for', value: 'Unaccounted For' },
  {
    label: 'Destroyed',
    value: 'Destroyed',
  },
  { label: 'Suspended', value: 'Suspended' },
  { label: 'Misprint', value: 'Misprint' },
  {
    label: 'Reactivated Other',
    value: 'Reactivated',
  },
];

export const nonCollectibleStatuses = ['Lost', 'Stolen', 'Unaccounted For'];

export const leoOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

const badgeStatusOptions = [
  { label: 'In Use', value: 'in_use' },
  {
    label: 'Pending',
    value: 'pending',
  },
  { label: 'Misprint', value: 'misprint' },
];

const defaultState = {
  selectedBadgeApplication: null,
  badgeHolder: null,
  group: null,
  badgeType: null,
  badgeStatus: null,
  startDate: moment().format('YYYY-MM-DD'),
  expirationDate: moment().endOf('month').add(2, 'years').format('YYYY-MM-DD'),
  badgeNumber: '',
  cardSerialNumber: '',
  parking: { label: 'No', value: 0 },
  driving: null,
  escort: null,
  leo: { label: 'No', value: 0 },
  firstName: '',
  lastName: '',
  email: '',
  badges: [],
  oldBadge: null,
  deactivationReason: { label: 'Reactivated Other', value: 'Reactivated' },
  deactivationComment: '',
  isMisprintWarningShown: false,
  isCollected: false,
  collectedDate: moment(),
};

let badgeHoldersRequestTimeout = null;
let badgeHoldersAbortController = null;

export const BadgeCreateContext = createContext();

export default function BadgeCreateModal({
  isOpen,
  onClose,
  badgeTypes,
  predefinedBadge = null,
  badgeSaved = null,
  shouldSkipDefaultEndorsementSelection,
  shouldShowExpiredBadgeStatus = false,
}) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [individualOptions, setIndividualOptions] = useState([]);
  const [authorizedSignatoryOptions, setAuthorizedSignatoryOptions] = useState([]);
  const [badges, setBadges] = useState([]);
  const [state, setState] = useState({ ...defaultState });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBadges, setIsLoadingBadges] = useState(false);
  const [isPacsEnabled, setIsPacsEnabled] = useState(false);
  const [isBadgeTypeDisabled, setIsBadgeTypeDisabled] = useState(false);
  const [isBadgeStatusDisabled, setIsBadgeStatusDisabled] = useState(false);
  const [isBadgeHoldersLoading, setIsBadgeHoldersLoading] = useState(false);
  const [isGroupsLoading, setIsGroupsLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [newGroupManualEntry, setNewGroupManualEntry] = useState('');
  const [isSignatoryDropdownDisabled, setIsSignatoryDropdownDisabled] = useState(false);
  const [selectedAuthorizedSignatory, setSelectedAuthorizedSignatory] = useState(null);
  const [shouldShowPacsSecurityGroups, setShouldShowPacsSecurityGroups] = useState(false);
  const [isLoadingPacsSecurityGroups, setIsLoadingPacsSecurityGroups] = useState(false);
  const [pacsSecurityGroups, setPacsSecurityGroups] = useState([]);
  const [selectedPacsSecurityGroups, setSelectedPacsSecurityGroups] = useState([]);

  const findBadgeHolders = event => {
    if (badgeHoldersRequestTimeout) {
      if (badgeHoldersAbortController) badgeHoldersAbortController.abort();
      clearTimeout(badgeHoldersRequestTimeout);
    }

    const fullName = get(event, 'target.value', null);
    if (!fullName || fullName.length < 3) {
      setIsBadgeHoldersLoading(false);
      return;
    }

    setIndividualOptions([]);
    setIsBadgeHoldersLoading(true);

    badgeHoldersRequestTimeout = setTimeout(() => {
      badgeHoldersAbortController = new AbortController();
      peopleApi
        .find({ fullName, withUserId: true }, badgeHoldersAbortController.signal)
        .then(({ success, matches }) => {
          if (success)
            setIndividualOptions(
              matches.map(m => ({
                name: buildFullName(m),
                value: m.uuid,
                uuid: m.uuid,
                pacsPersonId: m.pacs_person_id || m.pacsPersonId,
                full_name: buildFullName(m),
                original: m,
              }))
            );
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsBadgeHoldersLoading(false));
    }, 500);
  };

  const badgeTypeOptions = badgeTypes.map(type => {
    return {
      label: type.displayName,
      value: type.badgeTypeID,
      original: type,
    };
  });

  const loadData = async () => {
    try {
      const { user } = app;
      app.api.toggleLoading(true);
      setIsLoading(true);

      const authorizedSignatoriesResponse = await AuthorizedSignatoryGetRequest(user);
      const signatories = authorizedSignatoriesResponse
        .filter(x => x.active)
        .map(({ uuid, user: { first_name, last_name }, profile_id, companyName, companyUUID }) => ({
          label: `${first_name} ${last_name}, ${companyName}`,
          value: profile_id,
          profileUuid: uuid,
          companyName,
          companyUUID,
        }));
      setAuthorizedSignatoryOptions(signatories);

      if (predefinedBadge?.badge?.authorizedSignatoryProfileUuid) {
        onSignatorySelected(
          signatories.find(x => x.profileUuid === predefinedBadge?.badge?.authorizedSignatoryProfileUuid)
        );
      }

      setIsLoading(false);
    } catch (error) {
      handleError({ error, message: 'Unable to load badge data' });
    } finally {
      app.api.toggleLoading(false);
    }
  };

  const loadGroups = () => {
    setIsGroupsLoading(true);
    badgeApi
      .getGroups()
      .then(({ success, results = [] }) => {
        if (success) setGroups(results.map(r => ({ label: r, value: r })));
      })
      .catch(error => handleError({ error }))
      .finally(() => setIsGroupsLoading(false));
  };

  const loadPacsSecurityGroups = () => {
    let features = app?.airport?.pacsConfig?.features || [];
    const isPacsEnabled = !!app?.airport?.isPacsEnabled;
    const isPacsActivationEnabled = features.includes('allow_activation_from_airbadge');
    setShouldShowPacsSecurityGroups(isPacsActivationEnabled);
    setPacsSecurityGroups([]);
    if (isPacsEnabled && isPacsActivationEnabled) {
      setIsLoadingPacsSecurityGroups(true);

      request('authenticated-user', 'GET', 'pacs/security-groups')
        .then(({ groups }) => {
          const allGroups = (groups || [])
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ pacsSecurityGroupId, name }) => ({
              label: name,
              value: pacsSecurityGroupId,
            }));
          setPacsSecurityGroups(allGroups);
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoadingPacsSecurityGroups(false));
    }
  };

  const onSignatorySelected = signatory => {
    setSelectedAuthorizedSignatory(signatory);

    let features = app?.airport?.pacsConfig?.features || [];
    const isPacsEnabled = !!app?.airport?.isPacsEnabled;
    const isPacsActivationEnabled = features.includes('allow_activation_from_airbadge');
    if (isPacsEnabled && isPacsActivationEnabled) {
      setIsLoadingPacsSecurityGroups(true);
      request('authenticated-user', 'GET', `pacs/security-groups/${signatory.companyUUID}`)
        .then(({ groups }) => {
          const defaultGroups = (groups || [])
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ pacsSecurityGroupId, name }) => ({
              label: name,
              value: pacsSecurityGroupId,
            }));
          setSelectedPacsSecurityGroups(defaultGroups);
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoadingPacsSecurityGroups(false));
    }
  };

  const onModalClose = shouldReload => {
    setSelectedAuthorizedSignatory(null);
    setIsSignatoryDropdownDisabled(false);
    setIsModalOpen(false);
    setState({ ...defaultState });
    setIndividualOptions([]);
    setAuthorizedSignatoryOptions([]);
    setBadges([]);
    setIsLoading(true);
    setShouldShowPacsSecurityGroups(false);
    setIsLoadingPacsSecurityGroups(false);
    setPacsSecurityGroups([]);
    setSelectedPacsSecurityGroups([]);

    onClose(shouldReload);
  };

  const onModalClick = () => {
    const start = document.querySelector('.start-trigger .datetime-picker-popup');
    if (start && start.style.display !== 'none') {
      document.querySelector('#start').click();
    }

    const expiration = document.querySelector('.expiration-trigger .datetime-picker-popup');
    if (expiration && expiration.style.display !== 'none') {
      document.querySelector('#expiration').click();
    }

    const collectionDate = document.querySelector('.collectionDate-trigger .datetime-picker-popup');
    if (collectionDate && collectionDate.style.display !== 'none') {
      document.querySelector('#collectionDate').click();
    }

    const date = document.querySelector('.collectedDate-trigger .datetime-picker-popup');
    if (date && date.style.display !== 'none') {
      document.querySelector('#collectedDate').click();
    }
  };

  const isCollectibleStatus = () => {
    const { deactivationReason } = state;
    return deactivationReason && !nonCollectibleStatuses.includes(deactivationReason.value);
  };

  const getDefaultBadgeExpiration = badgeType => {
    if (!badgeType?.config?.badgeExpiration) return null;

    const { fixedDate, durationMonths, isExpiringEndOfMonth } = badgeType.config.badgeExpiration;

    if (fixedDate) {
      const fixedDateMoment = moment.tz(fixedDate, app.timezone);
      if (fixedDateMoment.isValid()) {
        return fixedDateMoment.format('YYYY-MM-DD');
      }
    } else if (durationMonths) {
      let durationMoment = moment().tz(app.timezone);
      durationMoment.add(durationMonths, 'months');
      if (isExpiringEndOfMonth) {
        durationMoment = durationMoment.endOf('month');
      }
      return durationMoment.format('YYYY-MM-DD');
    }

    return null;
  };

  const onSelectBadgeType = value => {
    const newState = { ...state, badgeType: value, driving: null, escort: null };
    const defaultBadgeExpiration = getDefaultBadgeExpiration(value?.original);
    if (defaultBadgeExpiration && !predefinedBadge?.badge?.expirationDate) {
      newState.expirationDate = defaultBadgeExpiration;
    }
    setState(newState);
  };

  const selectBadgeHolder = (badgeHolder, renewingBadgeId, newState = {}) => {
    if (!badgeHolder) {
      setBadges([]);
      setState({ ...state, ...newState, badgeHolder: null });
      return false;
    }

    if (isPacsEnabled && !badgeHolder.pacsPersonId) {
      app.api.confirmMessage(
        'PACS Notice',
        <Alert color="warning">
          <strong>{badgeHolder.full_name} </strong>
          <span>
            has not been reconciled with PACS. A badge cannot be issued to this person until the reconciliation is
            complete.
          </span>
        </Alert>,
        false,
        false
      );
    }

    setIsLoadingBadges(true);
    return BadgesForUserRequest(app.user, { userUuid: badgeHolder.uuid })
      .then(response => {
        let finalState;
        if (response && response.length) {
          let oldBadge = null;
          let deactivationReason = null;
          const badges = response
            .filter(x => x.active)
            .map(x => {
              const status = app.badgeStatuses.find(s => s.value === x.status) || { label: 'unknown' };
              const badge = { label: `${x.number} (${x.type.toLocaleUpperCase()}) (${status.label})`, value: x.uuid };
              if (renewingBadgeId && (x.badge_id === renewingBadgeId || x.uuid === renewingBadgeId)) {
                oldBadge = badge;
              }
              return badge;
            });

          setBadges(badges);
          finalState = { ...state, ...newState, badgeHolder, oldBadge, deactivationReason };
        } else {
          finalState = { ...state, ...newState, badgeHolder, oldBadge: null, deactivationReason: null };
        }
        if (finalState.badgeType?.original?.config) {
          const defaultBadgeExpiration = getDefaultBadgeExpiration(finalState.badgeType.original);
          if (defaultBadgeExpiration && !predefinedBadge?.badge?.expirationDate)
            finalState.expirationDate = defaultBadgeExpiration;
        }
        setState(finalState);
      })
      .finally(() => setIsLoadingBadges(false));
  };

  useEffect(() => {
    if (!predefinedBadge || isLoading) return;

    const badgeHolder = {
      label: `${predefinedBadge.person.firstName} ${predefinedBadge.person.lastName}`,
      full_name: `${predefinedBadge.person.firstName} ${predefinedBadge.person.lastName}`,
      value: predefinedBadge.person.uuid,
      ...predefinedBadge.person,
    };
    selectBadgeHolder(badgeHolder, predefinedBadge.renewingBadgeId || null);

    if (predefinedBadge.badge.type) {
      const typeToMatch = predefinedBadge.badge.type.toUpperCase();
      state.badgeType = badgeTypeOptions.find(x => x.label.toUpperCase() === typeToMatch);
    }

    state.badgeNumber = predefinedBadge.badge.badgeNumber;
    state.cardSerialNumber = predefinedBadge.badge.cardNumber;
    if (state?.badgeType?.original?.isBadgeNumberAndSerialNumberSame) {
      if (state.badgeNumber && !state.cardSerialNumber) {
        state.cardSerialNumber = state.badgeNumber;
      } else if (!state.badgeNumber && state.cardSerialNumber) {
        state.badgeNumber = state.cardSerialNumber;
      } else if (!state.badgeNumber && !state.cardSerialNumber) {
        state.cardSerialNumber = state.badgeNumber = '';
      } else if (state.badgeNumber && state.cardSerialNumber) {
        state.badgeNumber = state.cardSerialNumber;
      }
    }

    if (predefinedBadge.badge.expirationDate) {
      const expData = moment.tz(predefinedBadge.badge.expirationDate, app.timezone);
      state.expirationDate = expData.isValid() ? expData.format('YYYY-MM-DD') : null;
    } else {
      const defaultBadgeExpiration = getDefaultBadgeExpiration(state.badgeType?.original);
      if (defaultBadgeExpiration) state.expirationDate = defaultBadgeExpiration;
    }

    if (predefinedBadge.badge.status) {
      const statusToMatch = predefinedBadge.badge.status.toUpperCase();
      const options = shouldShowExpiredBadgeStatus
        ? [
            ...badgeStatusOptions,
            {
              label: 'Expired',
              value: 'expired',
            },
          ]
        : badgeStatusOptions;
      state.badgeStatus = options.find(
        x => x.label.toUpperCase() === statusToMatch || x.value.toUpperCase() === statusToMatch
      );
    }

    if (predefinedBadge.badge.template) {
      state.template = predefinedBadge.badge.template;
    }

    if (predefinedBadge.badge.escort === true) {
      state.escort = { label: 'yes', value: 1 };
    } else if (predefinedBadge.badge.escort === false) {
      state.escort = { label: 'no', value: 0 };
    }

    if (predefinedBadge.badge.driving) {
      const drivingOptions = getDrivingOptions(state.badgeType?.original?.config?.endorsements?.driving);
      state.driving = drivingOptions.find(option => option.value === predefinedBadge.badge.driving);
    }

    setState({ ...state });
    setIsBadgeTypeDisabled(!!state.badgeType);
    setIsBadgeStatusDisabled(!!state.badgeStatus);
  }, [predefinedBadge, isLoading]);

  const onCreateBadgeClick = () => {
    createBadge(state);
  };

  const buildBadgeData = state => {
    const effectiveDate = moment(state.startDate, 'YYYY-MM-DD');
    const expiresDate = moment(state.expirationDate, 'YYYY-MM-DD');

    if (effectiveDate.isAfter(expiresDate)) {
      handleError({ message: 'The Start / Effective Date cannot be after the Expiration Date' });
      return false;
    }

    let groupName = null;
    if (newGroupManualEntry) {
      groupName = newGroupManualEntry;
    } else if (state.group) {
      groupName = state.group.value;
    }

    const badge = {
      badge_application_id: state.badgeApplication ? state.badgeApplication.value : null,
      badge_holder_uuid: state.badgeHolder ? state.badgeHolder.uuid : null,
      authorized_signatory_profile_id: selectedAuthorizedSignatory?.value || null,
      type: state.badgeType ? state.badgeType.value : null,
      status: state.badgeStatus ? state.badgeStatus.value : null,
      driving: state.driving ? state.driving.value : null,
      group: trim(groupName),
      number: trim(state.badgeNumber),
      card_serial_number: trim(state.cardSerialNumber),
      effective: effectiveDate.format('YYYY-MM-DD HH:mm:ss'),
      expires: expiresDate.format('YYYY-MM-DD HH:mm:ss'),
      parking: state.parking.value,
      escort: state.escort.value,
      leo: state.leo.value,
      collected: state.collectionDate ? moment(state.collectionDate).format('YYYY-MM-DD HH:mm:ss') : null,
      template: state.template || null,
      pacsSecurityGroups: selectedPacsSecurityGroups.map(x => x.value),
    };

    if (
      badge.status === 'in_use' &&
      (!badge.effective || !badge.expires || !badge.number || !badge.card_serial_number)
    ) {
      handleError({ message: 'Missing required form data' });
      return false;
    }

    return badge;
  };

  const createBadge = state => {
    const { api, user } = app;
    const badge = buildBadgeData(state);
    const options = {
      notify_badge_holder: state.notifyBadgeHolder,
      notify_authorized_signatory: state.notifyAuthorizedSignatory,
    };

    api.toggleLoading(true);
    deactivateBadge()
      .then(() => {
        BadgeCreateRequest(user, badge, options)
          .then((success, message) => {
            if (success === false) {
              api.toggleLoading(false);
              return handleError({ message: message });
            }
            if (badgeSaved !== null) {
              badgeSaved();
            }

            if (state?.template?.sampledata[0]?.faceImage) {
              return uploadProfilePicForPerson({
                userUuid: state.badgeHolder.uuid,
                imageData: state.template.sampledata[0].faceImage,
              }).finally(() => onModalClose(true));
            } else {
              return onModalClose(true);
            }
          })
          .catch(error => {
            api.toggleLoading(false);
            handleError({ error, message: 'Badge could not be created' });
          });
      })
      .catch(error => {
        api.toggleLoading(false);
        handleError({ error, message: 'Badge could not be deactivated' });
      });
  };

  const deactivateBadge = () => {
    const { api, user } = app;
    const { oldBadge, deactivationReason, deactivationComment, isCollected, collectedDate } = state;

    if (!oldBadge) return Promise.resolve(true);

    const payload = {
      new_status: deactivationReason.value.toLocaleLowerCase(),
      comment: deactivationComment,
    };
    if (isCollectibleStatus()) {
      payload.collected = isCollected ? 1 : 0;
      payload.collectedDate = moment(collectedDate).format('YYYY-MM-DD HH:mm:ss');
    }

    api.toggleLoading(true);
    return request(user, 'PUT', `badges/${oldBadge.value}/status`, payload)
      .catch(error => handleError({ error, message: 'Unable to deactivate badge' }))
      .finally(() => api.toggleLoading(false));
  };

  const isSaveDisabled = () => {
    const {
      badgeHolder,
      badgeNumber,
      cardSerialNumber,
      startDate,
      expirationDate,
      badgeType,
      badgeStatus,
      driving,
      escort,
    } = state;
    if (!badgeHolder || !driving || !escort) {
      return true;
    }
    if (isPacsEnabled && !badgeHolder.pacsPersonId) {
      return true;
    }
    if (!selectedAuthorizedSignatory) {
      return true;
    }
    if (!badgeNumber || !cardSerialNumber || !startDate || !expirationDate || !badgeType || !badgeStatus) {
      return true;
    }
    if (state.oldBadge) {
      if (!state.deactivationReason) return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isOpen) return;
    setIsModalOpen(isOpen);
    loadGroups();
    loadPacsSecurityGroups();
    loadData();
  }, [isOpen]);

  useEffect(() => {
    if (!app.airport) return;
    setIsPacsEnabled(app.airport.isPacsEnabled);
  }, [app.airport]);

  const getDisabledClassName = property => {
    if (property === 'cardNumber' && predefinedBadge !== null) {
      return predefinedBadge.badge?.cardNumber ? 'custom-disabled' : undefined;
    } else if (property === 'badgeId' && state?.badgeType?.original?.isBadgeNumberAndSerialNumberSame) {
      return 'custom-disabled';
    } else if (property === 'badgeId' && predefinedBadge !== null) {
      const badgeNumber = predefinedBadge.badge?.badgeNumber;
      return badgeNumber && badgeNumber !== '0' ? 'custom-disabled' : undefined;
    }
    return undefined;
  };

  const isEffectiveDateDisabled = () => {
    return predefinedBadge?.badge?.effectiveDate ? 'custom-disabled' : undefined;
  };

  const isExpirationDateDisabled = () => {
    return predefinedBadge?.badge?.expirationDate ? 'custom-disabled' : undefined;
  };

  if (!isModalOpen) return null;

  return (
    <Modal size="lg" isOpen={isModalOpen} toggle={() => onModalClose(false)} onClick={onModalClick}>
      <CustomModalHeader toggle={() => onModalClose(false)}>Issue New Badge</CustomModalHeader>
      <ModalBody>
        <BadgeCreateContext.Provider value={{ data: state, setData: setState }}>
          {predefinedBadge?.person?.uuid ? (
            <>
              <div
                style={{
                  border: '1px solid #c8ced3',
                  backgroundColor: '#f0f0f0',
                  borderRadius: 5,
                  padding: 12,
                }}
              >
                <Row>
                  <Col sm={12} md={6}>
                    <ReadOnlyFormData
                      skipTextTransform
                      label="Badge Holder"
                      value={`${predefinedBadge.person.firstName} ${predefinedBadge.person.lastName}`}
                    />
                  </Col>
                  <Col sm={12} md={3}>
                    <ReadOnlyFormData
                      skipTextTransform
                      label="Airport Person ID"
                      value={
                        predefinedBadge?.person?.original?.airportPersonId ||
                        predefinedBadge?.person?.airportPersonId || <i>- missing -</i>
                      }
                    />
                  </Col>
                  <Col sm={12} md={3}>
                    <ReadOnlyFormData
                      skipTextTransform
                      label="Date of Birth"
                      value={
                        predefinedBadge?.person?.original?.dob || predefinedBadge?.person?.dob || <i>- missing -</i>
                      }
                    />
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="required">Badge Holder</Label>
                    <AutoCompleteDropdown
                      id="badge-holders"
                      value={state.badgeHolder}
                      onValueSelected={selectBadgeHolder}
                      onInputChange={findBadgeHolders}
                      options={individualOptions}
                      isLoading={isBadgeHoldersLoading}
                      placeholder="Search by first or last name"
                      size="small"
                      filterOptions={filterUserOptions}
                      renderOption={user => <AutoCompleteUserOption user={user} />}
                      disabled={predefinedBadge !== null}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="required">Authorized Signatory</Label>
                    <Select
                      classNamePrefix="airbadge"
                      options={authorizedSignatoryOptions}
                      className="form-select"
                      value={selectedAuthorizedSignatory}
                      placeholder=""
                      isDisabled={isSignatoryDropdownDisabled}
                      onChange={onSignatorySelected}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {!!state.badgeHolder && (
                <Row className="mb-3">
                  <Col md={3}>
                    <ReadOnlyFormData
                      skipTextTransform
                      label="Airport Person ID"
                      value={
                        state.badgeHolder.original.airportPersonId || state.badgeHolder.original.tsc_person_id || '-'
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <ReadOnlyFormData
                      skipTextTransform
                      label="Date of Birth"
                      value={state.badgeHolder.original.dob || state.badgeHolder.original.date_of_birth || '-'}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}

          <div>&nbsp;</div>

          <Row>
            {!!predefinedBadge?.person?.uuid && (
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label className="required">Authorized Signatory</Label>
                  <Select
                    classNamePrefix="airbadge"
                    options={authorizedSignatoryOptions}
                    className="form-select"
                    value={selectedAuthorizedSignatory}
                    placeholder=""
                    isDisabled={isSignatoryDropdownDisabled}
                    onChange={setSelectedAuthorizedSignatory}
                  />
                </FormGroup>
              </Col>
            )}
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>
                  Group <i style={{ fontSize: 'smaller' }}>(optional)</i>
                </Label>
                <AutoCompleteDropdown
                  id="newGroup"
                  value={state.group}
                  onValueSelected={option => setState({ ...state, group: option })}
                  options={groups}
                  placeholder=""
                  size="small"
                  isLoading={isGroupsLoading}
                  getOptionLabel={option => option.label || 'error'}
                  onInputChange={({ target: { value } }) => setNewGroupManualEntry(value)}
                  freeSolo
                  disableClearable
                />
              </FormGroup>
            </Col>
          </Row>

          <div>&nbsp;</div>

          <Row>
            <Col>
              <FormGroup>
                <Label className="required">Badge Type</Label>
                <Select
                  classNamePrefix="airbadge"
                  options={badgeTypeOptions}
                  className="form-select"
                  value={state.badgeType}
                  placeholder=""
                  isDisabled={isBadgeTypeDisabled}
                  onChange={onSelectBadgeType}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label className="required">Badge Status</Label>
                <Select
                  classNamePrefix="airbadge"
                  options={
                    shouldShowExpiredBadgeStatus
                      ? [
                          ...badgeStatusOptions,
                          {
                            label: 'Expired',
                            value: 'expired',
                          },
                        ]
                      : badgeStatusOptions
                  }
                  className="form-select"
                  value={state.badgeStatus}
                  placeholder=""
                  isDisabled={isBadgeStatusDisabled}
                  onChange={value => {
                    const newState = { ...state, badgeStatus: value };
                    if (value.value !== 'misprint') newState.collectionDate = null;
                    setState(newState);
                  }}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          {state.badgeStatus && state.badgeStatus.value === 'misprint' && (
            <>
              <div>&nbsp;</div>
              <Row>
                <Col sm="12" lg="6">
                  <FormGroup>
                    <Label className="required">Collection Date</Label>
                    <DatetimePickerTrigger
                      className="collectionDate-trigger"
                      moment={state.collectionDate ? moment(state.collectionDate) : moment()}
                      showTimePicker={false}
                      onChange={moment => setState({ ...state, collectionDate: moment.format('MM/DD/YYYY') })}
                    >
                      <Input type="text" id="collectionDate" value={state.collectionDate || ''} readOnly />
                    </DatetimePickerTrigger>
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}

          <div>&nbsp;</div>

          <Row>
            <Col>
              <FormGroup>
                <Label className="required">Start / Effective Date</Label>
                {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
                <StaggeredDatePicker
                  startingYear={new Date().getFullYear() - 9}
                  endingYear={new Date().getFullYear()}
                  defaultValue={state.startDate}
                  onValidDate={({ year, month, day }) =>
                    setState({
                      ...state,
                      startDate: `${year}-${month}-${day}`,
                    })
                  }
                  onInvalidDate={() => setState({ ...state, startDate: null })}
                  isDisabled={isEffectiveDateDisabled()}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label className="required">Expiration Date</Label>
                {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
                <StaggeredDatePicker
                  startingYear={new Date().getFullYear()}
                  endingYear={new Date().getFullYear() + 5}
                  defaultValue={state.expirationDate}
                  onValidDate={({ year, month, day }) =>
                    setState({
                      ...state,
                      expirationDate: `${year}-${month}-${day}`,
                    })
                  }
                  onInvalidDate={() => setState({ ...state, expirationDate: null })}
                  isDisabled={isExpirationDateDisabled()}
                />
              </FormGroup>
            </Col>
          </Row>

          <div>&nbsp;</div>

          <Row>
            <Col>
              <FormGroup>
                <Label className="required">Badge ID</Label>
                <Input
                  id="badgeId"
                  value={state.badgeNumber}
                  onChange={({ target }) => setState({ ...state, badgeNumber: target.value })}
                  className={getDisabledClassName('badgeId')}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label className="required">Card Number</Label>
                <Input
                  id="cardNumber"
                  value={state.cardSerialNumber}
                  onChange={({ target }) => {
                    const updates = { ...state, cardSerialNumber: target.value };
                    if (state?.badgeType?.original?.isBadgeNumberAndSerialNumberSame) {
                      updates.badgeNumber = target.value;
                    }
                    setState(updates);
                  }}
                  className={getDisabledClassName('cardNumber')}
                />
              </FormGroup>
            </Col>
          </Row>

          <div>&nbsp;</div>

          <BadgeEndorsementsPicker
            shouldSkipDefaultSelection={shouldSkipDefaultEndorsementSelection}
            displayAsResponsiveRow
          />

          <div>&nbsp;</div>

          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label>Is this individual a Law Enforcement Officer (LEO)?</Label>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="leo"
                    name="leo"
                    value={state?.leo?.value === 1 ? 'yes' : 'no'}
                    onChange={event => {
                      if (event.target.value === 'yes') {
                        setState({ ...state, leo: { label: 'Yes', value: 1 } });
                      } else {
                        setState({ ...state, leo: { label: 'No', value: 0 } });
                      }
                    }}
                  >
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </FormGroup>
            </Col>
          </Row>

          {shouldShowPacsSecurityGroups && (
            <>
              <Row>
                <Col sm={12} md={6}>
                  <Label>
                    PACS Security Groups <i style={{ fontSize: 'smaller' }}>(optional)</i>
                  </Label>
                  <Select
                    isMulti
                    isLoading={isLoadingPacsSecurityGroups}
                    placeholder=""
                    classNamePrefix="airbadge"
                    className="form-select"
                    options={pacsSecurityGroups}
                    value={selectedPacsSecurityGroups}
                    onChange={setSelectedPacsSecurityGroups}
                  />
                </Col>
              </Row>

              <div>&nbsp;</div>
            </>
          )}

          {state.badgeHolder && state.badgeHolder.uuid && (
            <>
              <div className="separator" style={{ margin: '8px 0 8px 0' }}>
                Deactivation
              </div>

              <div style={{ margin: '16px 0' }}>
                Does this individual have an active badge that needs to be deactivated?
              </div>

              <Row>
                <Col xs="12" sm="6">
                  <FormGroup>
                    <Label>Badge to be deactivated</Label>
                    <Select
                      classNamePrefix="airbadge"
                      options={badges}
                      className="form-select"
                      value={state.oldBadge}
                      placeholder=""
                      isClearable={!predefinedBadge?.renewingBadgeId}
                      isDisabled={!state.badgeHolder}
                      isLoading={isLoadingBadges}
                      noOptionsMessage={() => 'No active badges to deactivate'}
                      onChange={value => setState({ ...state, oldBadge: value })}
                    />
                  </FormGroup>
                </Col>
                {state.oldBadge && (
                  <Col xs="12" sm="6">
                    <FormGroup>
                      <Label className="required">Deactivation Reason</Label>
                      <Select
                        classNamePrefix="airbadge"
                        options={reasons}
                        className="form-select"
                        value={state.deactivationReason}
                        placeholder=""
                        isDisabled={!state.oldBadge}
                        onChange={x => {
                          setState({
                            ...state,
                            deactivationReason: x,
                            isMisprintWarningShown: false,
                            isCollected: !nonCollectibleStatuses.includes(x.value),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              {isCollectibleStatus() && state.oldBadge && (
                <Row>
                  <Col>
                    <MuiFormGroup row>
                      <MuiFormControlLabel
                        control={<Switch color="primary" />}
                        label="Badge Was Collected"
                        checked={state.isCollected}
                        disabled={!state.oldBadge}
                        onChange={({ target }) => {
                          const checked = target.checked;
                          setState({
                            ...state,
                            isCollected: checked,
                            isMisprintWarningShown: !checked && state.deactivationReason.label === 'Misprint',
                          });
                        }}
                      />
                    </MuiFormGroup>
                  </Col>
                  {state.isCollected && state.oldBadge && (
                    <Col>
                      <FormGroup>
                        <Label className="required">Collected Date</Label>
                        <DatetimePickerTrigger
                          className="collectedDate-trigger"
                          moment={state.collectedDate}
                          showTimePicker={false}
                          onChange={moment => setState({ ...state, collectedDate: moment })}
                        >
                          <Input
                            type="text"
                            id="collectedDate"
                            value={state.collectedDate.format('MM/DD/YYYY')}
                            readOnly
                          />
                        </DatetimePickerTrigger>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              )}
              {state.isMisprintWarningShown && (
                <Row>
                  <Col>
                    <Alert color="warning">
                      If this badge was issued, select a different status to represent why it is being deactivated.
                    </Alert>
                  </Col>
                </Row>
              )}
              {state.oldBadge && (
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        Deactivation Comment <i style={{ fontSize: 'smaller' }}>(optional)</i>
                      </Label>
                      <Input
                        disabled={!state.oldBadge}
                        type="textarea"
                        value={state.deactivationComment}
                        onChange={({ target }) => setState({ ...state, deactivationComment: target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </>
          )}
        </BadgeCreateContext.Provider>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Close"
          onClose={() => onModalClose(false)}
          saveLabel="Issue Badge"
          onSave={onCreateBadgeClick}
          saveDisabled={isSaveDisabled()}
        />
      </ModalFooter>
    </Modal>
  );
}
