import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import { Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import AuthorizedSignatoryGetRequest from '../../../../ajax/AuthorizedSignatory/AuthorizedSignatoryGetRequest';
import IndividualGetAllRequest from '../../../../ajax/Individual/IndividualGetAllRequest';
import BadgeApplicationCreateRequest from '../../../../ajax/BadgeApplication/BadgeApplicationCreateRequest';
import badgeTypeApi from '../../../../ajax/BadgeType/badgeTypeApi';
import ModalActions from '../../../../shared/components/ModalActions/ModalActions';
import CustomModalHeader from '../../../../shared/components/CustomModalHeader/CustomModalHeader';
import CreateNewPersonForBadgeApplicationModal from './CreateNewPersonForBadgeApplicationModal';
import AutoCompleteDropdown, {
  AutoCompleteUserOption,
  filterUserOptions,
} from '../../../../shared/components/AutoCompleteDropdown/AutoCompleteDropdown';
import { buildFullName, handleError } from '../../../../utils';
import badgeApplicationApi from '../../../../ajax/BadgeApplication/badgeApplicationApi';
import badgeApi from '../../../../ajax/Badge/badgeApi';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import { AppContext, basePaymentChoices, invoicePaymentChoice, noChargePaymentChoice } from '../../../../App';
import peopleApi from '../../../../ajax/People/peopleApi';
import DocSpringDocument from '../../DocSpringDocument/DocSpringDocument';
import useConfirm from '../../../../shared/useConfirm';
import Feature from '../../../../shared/Feature';
import BadgeApplicationEndorsementsPicker from '../BadgeApplicationEndorsementsPicker/BadgeApplicationEndorsementsPicker';
import { getDrivingOptions } from '../../../../shared/BadgeTypes/drivingOptionsUtils';
import BadgeQuestions from '../../Badges/BadgeQuestions/BadgeQuestions';

export function FixEmailModal({ isOpen, onDone, user }) {
  const app = useContext(AppContext);
  const [email, setEmail] = useState('');

  const onCancel = () => {
    setEmail('');
    onDone(false);
  };

  const onSave = () => {
    app.api.toggleLoading(true);
    peopleApi
      .patch({ uuid: user.uuid, updates: { email, force: true } })
      .then(({ success, message }) => {
        if (!success) {
          return handleError({ message: message || 'Unable to update email address' });
        }
        setEmail('');
        onDone(true, email);
      })
      .catch(error => handleError({ error, message: 'Unable to update email address' }))
      .finally(() => app.api.toggleLoading(false));
  };

  const isSaveDisabled = () => {
    return !email;
  };

  if (!isOpen || !user) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Update Email Address"
      isOpen={isOpen}
      onClose={onCancel}
      closeLabel="Cancel"
      onSave={onSave}
      saveLabel="Update Email Address"
      saveDisabled={isSaveDisabled()}
    >
      <Alert color="warning">
        <p>
          <strong>{buildFullName(user)} does not have a valid email address.</strong>
        </p>
        <p>Before this badge application can be started, a valid email for this person must be provided.</p>
      </Alert>
      <FormGroup>
        <Label className="required">Email Address</Label>
        <Input type="text" value={email} onChange={event => setEmail(event?.target?.value || '')} />
      </FormGroup>
    </AirBadgeModal>
  );
}

const defaultApplicationData = {
  applicationType: null,
  user: null,
  badgeType: null,
  badge: null,
  driving: null,
  escort: null,
  doors: '',
  purpose: '',
  willSupplyOwnChrc: null,
  paymentChoice: null,
};

const OptionRow = ({ label, value }) => (
  <div style={{ display: 'flex' }}>
    <div style={{ width: 60, textAlign: 'right', fontStyle: 'italic', paddingRight: 10 }}>{label}:</div>
    <div>{value}</div>
  </div>
);
const CustomIndividualOption = props => {
  const { innerProps, innerRef, data, getStyles } = props;
  const styles = getStyles('option', props);
  return (
    <div ref={innerRef} {...innerProps} style={styles}>
      <div>
        <strong>{data.label}</strong>
      </div>
      <OptionRow label="Type" value={data.typeLabel} />
      <OptionRow label="Status" value={data.status} />
      <OptionRow label="Expires" value={moment(data.expires).format('MM/DD/YYYY')} />
    </div>
  );
};

const BadgeTypeOption = props => {
  const { innerProps, innerRef, data, getStyles } = props;
  const styles = getStyles('option', props);
  const { label, original } = data;
  return (
    <div ref={innerRef} {...innerProps} style={styles}>
      <div>
        <strong style={{ textTransform: 'capitalize' }}>{label}</strong>
      </div>
      {!!original?.description && (
        <div>
          <i>{original?.description}</i>
        </div>
      )}
    </div>
  );
};

let usersRequestTimeout = null;
let usersAbortController = null;

let badgesRequestTimeout = null;
let badgesApiAbortController = null;

export const BadgeApplicationContext = createContext();

export default function BadgeApplicationModal({ isOpen, onClose, state, api }) {
  const showConfirm = useConfirm();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPeopleCreateModalOpen, setIsPeopleCreateModalOpen] = useState(false);
  const [individuals, setIndividuals] = useState([]);
  const [badgeTypes, setBadgeTypes] = useState([]);
  const [authorizedSignatories, setAuthorizedSignatories] = useState([]);
  const [badges, setBadges] = useState([]);
  const [applicationData, setApplicationData] = useState({ ...defaultApplicationData });
  const [isRenewalMode, setIsRenewalMode] = useState(false);
  const [isSignatory, setIsSignatory] = useState(false);
  const [authorizedSignatory, setAuthorizedSignatory] = useState(null);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isFixEmailModalOpen, setIsFixEmailModalOpen] = useState(false);
  const [transactionUuid, setTransactionUuid] = useState(null);
  const [paymentChoice, setPaymentChoice] = useState(basePaymentChoices.find(x => x.value === 'signatory'));

  const findUsers = event => {
    if (usersRequestTimeout) {
      if (usersAbortController) usersAbortController.abort();
      clearTimeout(usersRequestTimeout);
    }

    const fullName = event?.target?.value;
    if (!fullName || fullName.length < 3) {
      setIsUsersLoading(false);
      return;
    }

    setIsUsersLoading(true);

    usersRequestTimeout = setTimeout(() => {
      usersAbortController = new AbortController();
      badgeApplicationApi
        .findApplicants({
          criteria: {
            fullName,
            badgeApplicationType: isRenewalMode ? 'renewal' : 'new',
          },
          signal: usersAbortController.signal,
        })
        .then(({ success, matches }) => {
          if (success) {
            const currentUserUuid = state?.user?.uuid;
            setIndividuals(
              matches.map(m => {
                let name = buildFullName(m);
                if (currentUserUuid === m.uuid) {
                  name = `${name} (myself)`;
                }
                return { name, value: m.userId, uuid: m.uuid, original: m };
              })
            );
          }
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsUsersLoading(false));
    }, 500);
  };

  const loadIndividuals = async applicationType => {
    setIndividuals([]);
    api.toggleLoading(true);
    const { user } = state;
    const response = await IndividualGetAllRequest(user, true, applicationType || applicationData.applicationType);
    const currentUserUuid = state?.user?.uuid;
    setIndividuals(
      response.map(({ first_name, last_name, middle_name, suffix, email, user_id, uuid }) => {
        let label = buildFullName({ firstName: first_name, lastName: last_name, middleName: middle_name, suffix });
        if (currentUserUuid === uuid) {
          label = `${label} (myself)`;
        }
        return {
          label,
          value: user_id,
          uuid,
          original: {
            uuid,
            firstName: first_name,
            lastName: last_name,
            middleName: middle_name,
            suffix,
            email,
          },
        };
      })
    );
    api.toggleLoading(false);
  };

  const loadData = async () => {
    try {
      api.toggleLoading(true);

      const { user } = state;
      const { activeProfile } = user;
      const badgeTypesPromise = badgeTypeApi.activeBadgeTypes();
      const signatoriesPromise = AuthorizedSignatoryGetRequest(user);
      const [signatoriesResponse, badgeTypesResponse] = await Promise.all([signatoriesPromise, badgeTypesPromise]);

      const tempBadgeTypes = badgeTypesResponse.data.map(type => {
        const accessLevels = [];
        let requiresChrc = false;
        if (type.isAOA) {
          accessLevels.push('AOA');
        }
        if (type.isSIDA) {
          accessLevels.push('SIDA');
          requiresChrc = true;
        }
        if (type.isSecured) {
          accessLevels.push('SECURED');
          requiresChrc = true;
        }
        if (type.isSterile) {
          accessLevels.push('STERILE');
          requiresChrc = true;
        }
        if (type.isPublic) {
          accessLevels.push('PUBLIC');
        }
        const accessLevelsString = accessLevels.length ? ` (${accessLevels.join(', ')})` : '';
        return {
          label: `${type.displayName} ${accessLevelsString}`,
          value: type.badgeTypeID,
          uuid: type.uuid,
          requiresChrc,
          original: type,
        };
      });
      setBadgeTypes(tempBadgeTypes);

      const signatoryOptions = signatoriesResponse.map(
        ({
          user,
          profile_id,
          uuid: profileUuid,
          companyName,
          active,
          companyWillSupplyOwnChrc,
          shouldNeverChargePayment,
          shouldAllowPaymentInvoice,
        }) => {
          const { first_name, last_name } = user;
          return {
            label: `${first_name} ${last_name}, ${companyName}`,
            value: profile_id,
            profileUuid,
            active,
            companyName,
            companyWillSupplyOwnChrc: !!companyWillSupplyOwnChrc,
            shouldNeverChargePayment: !!shouldNeverChargePayment,
            shouldAllowPaymentInvoice: !!shouldAllowPaymentInvoice,
          };
        }
      );
      setAuthorizedSignatories(signatoryOptions.filter(x => x.active));

      if (activeProfile.role.name === 'authorized-signatory') {
        setIsSignatory(true);
        setApplicationData({ ...defaultApplicationData });
        setAuthorizedSignatory(signatoryOptions.find(x => x.value === activeProfile.profile_id));
      }
    } catch (error) {
      handleError({ error, message: 'Unable to load form data' });
    } finally {
      api.toggleLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    setIsModalOpen(isOpen);
    setAuthorizedSignatory(null);
    setApplicationData({ ...defaultApplicationData });
    loadData();
  }, [isOpen]);

  const onModalClose = () => {
    setIsModalOpen(false);
    setIsPeopleCreateModalOpen(false);
    setIndividuals([]);
    setBadgeTypes([]);
    setAuthorizedSignatories([]);
    setBadges([]);
    setApplicationData({ ...defaultApplicationData });
    setIsRenewalMode(true);
    setIsSignatory(false);
    setIsFixEmailModalOpen(false);
    setAuthorizedSignatory(null);
    setPaymentChoice(basePaymentChoices.find(x => x.value === 'signatory'));
    onClose();
  };

  const loadBadgesForUser = ({ userUuid, onSuccess }) => {
    if (badgesRequestTimeout) {
      if (badgesApiAbortController) badgesApiAbortController.abort();
      clearTimeout(badgesRequestTimeout);
    }

    api.toggleLoading(true);

    badgesRequestTimeout = setTimeout(() => {
      badgesApiAbortController = new AbortController();
      badgeApi
        .list({
          pageSize: null,
          page: null,
          sorts: [{ id: 'issue_date', desc: false }],
          filters: {
            0: {
              id: 'status',
              value: 'all',
            },
            filterByAuthorizedSignatory: null,
            filterByUser: userUuid,
          },
          signal: badgesApiAbortController.signal,
        })
        .then(({ data }) => {
          if (data) onSuccess(data);
        })
        .catch(error => handleError({ error, message: 'Unable to load badges for this applicant' }))
        .finally(() => api.toggleLoading(false));
    }, 500);
  };

  const onFixEmailDone = (isSuccess, selectedUser) => {
    setIsFixEmailModalOpen(false);

    if (!isSuccess) {
      return setApplicationData({ ...applicationData, user: null });
    }

    // Only need to proceed to loading badges
    // when this is for a badge renewal
    if (!isRenewalMode) return;

    loadBadgesForUser({
      userUuid: selectedUser?.uuid || applicationData?.user?.uuid,
      onSuccess: badges => {
        const mapped = badges.map(b => {
          const {
            active,
            status,
            number,
            type,
            badge_id,
            uuid,
            driving,
            authorized_signatory_profile_id,
            expires,
            badge_type_id,
            isBadgeTypeActive,
            badgeTypeUuid,
            renewsIntoBadgeTypeUuid,
            doors,
            purpose,
          } = b;
          const matchingStatus = state.badgeStatuses.find(s => s.value === status);
          const matchingType = badgeTypes.find(bt => bt.value === badge_type_id);
          return {
            label: number,
            value: badge_id,
            isActive: !!active,
            status: matchingStatus ? matchingStatus.label : 'Unknown',
            uuid,
            driving,
            authorized_signatory_profile_id,
            expires,
            typeLabel: matchingType ? matchingType.label : type,
            badge_type_id,
            isBadgeTypeActive: !!isBadgeTypeActive,
            badgeTypeUuid,
            renewsIntoBadgeTypeUuid,
            doors,
            purpose,
          };
        });
        setBadges(mapped);

        if (mapped && mapped.length === 1 && isRenewalMode) {
          const selectedBadge = mapped[0];
          const updatedData = { ...applicationData, user: selectedUser, badge: selectedBadge };

          let badgeTypeUuid = selectedBadge.badgeTypeUuid;
          if (!selectedBadge.isBadgeTypeActive && selectedBadge.renewsIntoBadgeTypeUuid) {
            badgeTypeUuid = selectedBadge.renewsIntoBadgeTypeUuid;
          }

          updatedData.badgeType = badgeTypes.find(x => x.uuid === badgeTypeUuid);
          setApplicationData(updatedData);
        }
      },
    });
  };

  const onSelectUser = selectedUser => {
    const newData = {
      ...applicationData,
      user: selectedUser?.value === -1 ? null : selectedUser,
      badge: null,
      badgeType: null,
    };
    setApplicationData(newData);

    if (!selectedUser) return;

    if (selectedUser?.value === -1) {
      return setIsPeopleCreateModalOpen(true);
    }

    const selectedUserEmail = (selectedUser?.original?.email || '').toLocaleLowerCase();
    if (!selectedUserEmail || selectedUserEmail.includes('fake')) {
      setIsFixEmailModalOpen(true);
    } else {
      onFixEmailDone(true, selectedUser);
    }
  };

  const onSelectBadge = option => {
    let badgeTypeUuid = option.badgeTypeUuid;
    if (!option.isBadgeTypeActive && option.renewsIntoBadgeTypeUuid) {
      badgeTypeUuid = option.renewsIntoBadgeTypeUuid;
    }
    const badgeType = badgeTypes.find(x => x.uuid === badgeTypeUuid);

    const drivingOptions = getDrivingOptions(badgeType?.original?.config?.endorsements?.driving);
    const driving = drivingOptions.find(x => x.value === option.driving);
    const escort = option.escort ? { label: 'yes', value: 1 } : { label: 'no', value: 0 };

    setApplicationData({
      ...applicationData,
      badge: option,
      badgeType,
      driving,
      escort,
    });

    const originalSignatory = authorizedSignatories.find(x => x.value === option.authorized_signatory_profile_id);
    if (!authorizedSignatory && originalSignatory) {
      setAuthorizedSignatory(originalSignatory);
    }

    if (isRenewalMode) {
      const question1Value = option.doors;
      const question2Value = option.purpose;
      if (question1Value || question2Value) {
        showConfirm({
          title: 'Badge Questions',
          content: (
            <div>
              <div>
                Do you want to use the same responses for the badge questions below that were given in the previous
                application?
              </div>
              <div>&nbsp;</div>
              <BadgeQuestions displayMode="view" question1Value={question1Value} question2Value={question2Value} />
              <div>&nbsp;</div>
            </div>
          ),
          onConfirm: () => {
            setApplicationData({
              ...applicationData,
              badge: option,
              badgeType,
              driving,
              escort,
              doors: question1Value || '',
              purpose: question2Value || '',
            });
          },
          okButtonText: 'Copy Answers',
        });
      }
    }
  };

  const onSelectBadgeType = event => {
    const selection = event.target.value;
    if (isSignatory && selection === 'renewal') {
      setApplicationData({
        ...applicationData,
        applicationType: null,
      });
      return showConfirm({
        title: 'Notice',
        content: (
          <div>
            Badge renewals must now be started from the Badge details screen. Contact the Badge Office if you have any
            questions or need help getting the renewal started.
          </div>
        ),
        onConfirm: () => {
          history.replace('/default/badges');
        },
        okButtonText: 'Go To Badges',
      });
    }

    setApplicationData({
      ...applicationData,
      applicationType: selection,
      user: null,
      badgeType: null,
      badge: null,
      driving: null,
      escort: null,
      willSupplyOwnChrc: null,
    });
    setIsRenewalMode(selection === 'renewal');
    if (isSignatory) {
      loadIndividuals(selection);
    }
  };

  const onCancelled = () => {
    setTransactionUuid(null);
    onModalClose();
  };

  const onSubmitSuccess = () => {
    onModalClose();
  };

  const onCreateClick = () => {
    api.toggleLoading(true);
    const badgeApplication = {
      application_type: applicationData.applicationType,
      applicant_uuid: applicationData.user.uuid,
      badge_type_uuid: applicationData.badgeType.uuid,
      renewing_badge_uuid: isRenewalMode ? applicationData.badge.uuid : null,
      authorized_signatory_profile_uuid: authorizedSignatory.profileUuid,
      driving: applicationData.driving.value,
      escort: applicationData.escort?.value,
      willSupplyOwnChrc: isRenewalMode ? null : applicationData.willSupplyOwnChrc,
      doors: applicationData.doors,
      purpose: applicationData.purpose,
      paymentChoice: paymentChoice?.value,
    };

    BadgeApplicationCreateRequest(state.user, badgeApplication)
      .then(({ success, message, documentTransactionUuid }) => {
        if (!success) {
          return handleError({ message: message || 'Could not create badge application' });
        }
        if (isSignatory && documentTransactionUuid) {
          setTransactionUuid(documentTransactionUuid);
        } else {
          onModalClose();
        }
      })
      .catch(error => handleError({ error, message: 'Could not create badge application' }))
      .finally(() => {
        api.toggleLoading(false);
      });
  };

  const onPeopleListCreateModalClose = ({ user }) => {
    setIsPeopleCreateModalOpen(false);
    if (user) {
      // Is the user already in the list?
      const existing = individuals.find(i => i.value === user.id);
      if (existing) {
        setApplicationData({ ...applicationData, user: existing });
      } else {
        const newUser = {
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
          uuid: user.uuid,
          original: user,
        };
        setApplicationData({ ...applicationData, user: newUser });
        const sorted = [...individuals, newUser].sort((a, b) => a.label.localeCompare(b.label));
        setIndividuals(sorted);
      }
    }
  };

  const doesRequireChrc = () => {
    if (isRenewalMode) {
      return false;
    }
    return applicationData?.badgeType?.requiresChrc && authorizedSignatory?.companyWillSupplyOwnChrc;
  };

  const isCreateButtonDisabled = () => {
    if (isRenewalMode) {
      return (
        applicationData.applicationType === null ||
        applicationData.user === null ||
        applicationData.badgeType === null ||
        authorizedSignatory === null ||
        applicationData.user === null ||
        applicationData.driving === null ||
        applicationData.escort === null ||
        !applicationData.doors ||
        !applicationData.purpose ||
        !paymentChoice
      );
    }
    return (
      applicationData.applicationType === null ||
      applicationData.user === null ||
      applicationData.badgeType === null ||
      authorizedSignatory === null ||
      (doesRequireChrc() && applicationData.willSupplyOwnChrc === null) ||
      applicationData.driving === null ||
      applicationData.escort === null ||
      !applicationData.doors ||
      !applicationData.purpose
    );
  };

  const getIndividualsForDropdown = () => {
    if (!isRenewalMode && applicationData?.applicationType === 'new') {
      const placeholderForCreateNew = {
        label: '-- Create new individual --',
        name: '-- Create new individual --',
        value: -1,
        uuid: '',
        original: {},
      };
      if (!individuals.find(i => i.uuid === state?.user?.uuid)) {
        return [
          placeholderForCreateNew,
          {
            label: `${buildFullName(state.user)} (myself)`,
            name: buildFullName(state.user),
            value: 0,
            uuid: state.user.uuid,
            original: state.user,
          },
          ...individuals,
        ];
      }
      return [placeholderForCreateNew, ...individuals];
    }
    return individuals;
  };

  const getPaymentOptions = useCallback(() => {
    if (!state?.airport?.payments) return [];

    const { isApplicantPaymentEnabled, isSignatoryPaymentEnabled, isStripeEnabled, isInPersonPaymentEnabled } =
      state.airport.payments;

    let choices = [];

    if (isStripeEnabled) {
      if (isApplicantPaymentEnabled) {
        choices.push(basePaymentChoices.find(x => x.value === 'applicant'));
      }
      if (isSignatoryPaymentEnabled) {
        choices.push(basePaymentChoices.find(x => x.value === 'signatory'));
      }
    }

    if (isInPersonPaymentEnabled) {
      choices.push(basePaymentChoices.find(x => x.value === 'in-person'));
    }

    if (authorizedSignatory) {
      if (authorizedSignatory.shouldNeverChargePayment) {
        choices.push(noChargePaymentChoice);
      } else if (authorizedSignatory.shouldAllowPaymentInvoice) {
        choices.push(invoicePaymentChoice);
      }
    }

    return choices;
  }, [authorizedSignatory]);

  // Update the payment choice after the authorized signatory is selected
  useEffect(() => {
    if (!state?.airport?.payments) return;

    const { isApplicantPaymentEnabled, isSignatoryPaymentEnabled, isStripeEnabled, isInPersonPaymentEnabled } =
      state.airport.payments;

    if (authorizedSignatory) {
      if (authorizedSignatory.shouldNeverChargePayment) {
        setPaymentChoice(noChargePaymentChoice);
      } else if (authorizedSignatory.shouldAllowPaymentInvoice) {
        setPaymentChoice(invoicePaymentChoice);
      } else {
        if (!isStripeEnabled) {
          if (isInPersonPaymentEnabled) {
            setPaymentChoice(basePaymentChoices.find(x => x.value === 'in-person'));
          } else {
            setPaymentChoice(invoicePaymentChoice);
          }
        } else if (isApplicantPaymentEnabled) {
          setPaymentChoice(basePaymentChoices.find(x => x.value === 'applicant'));
        } else if (isSignatoryPaymentEnabled) {
          setPaymentChoice(basePaymentChoices.find(x => x.value === 'signatory'));
        } else if (!isInPersonPaymentEnabled) {
          setPaymentChoice(invoicePaymentChoice);
        } else {
          setPaymentChoice(basePaymentChoices.find(x => x.value === 'in-person'));
        }
      }
    }
  }, [authorizedSignatory]);

  // Set the initial payment choice based on the airport settings
  useEffect(() => {
    if (!state?.airport?.payments) return;

    const { isApplicantPaymentEnabled, isSignatoryPaymentEnabled, isStripeEnabled, isInPersonPaymentEnabled } =
      state.airport.payments;

    if (!isStripeEnabled) {
      if (isInPersonPaymentEnabled) {
        setPaymentChoice(basePaymentChoices.find(x => x.value === 'in-person'));
      } else {
        setPaymentChoice(invoicePaymentChoice);
      }
    } else if (isApplicantPaymentEnabled) {
      setPaymentChoice(basePaymentChoices.find(x => x.value === 'applicant'));
    } else if (isSignatoryPaymentEnabled) {
      setPaymentChoice(basePaymentChoices.find(x => x.value === 'signatory'));
    } else if (!isInPersonPaymentEnabled) {
      setPaymentChoice(invoicePaymentChoice);
    } else {
      setPaymentChoice(basePaymentChoices.find(x => x.value === 'in-person'));
    }
  }, [isOpen, state.airport]);

  if (!isModalOpen) return false;

  return (
    <>
      <Modal size="md" isOpen={isModalOpen} toggle={onModalClose}>
        <CustomModalHeader label="New Badge Application" onClick={onModalClose} />
        <ModalBody>
          <BadgeApplicationContext.Provider value={{ applicationData, setApplicationData }}>
            <FormGroup>
              <Label className="required">Badge Application Type</Label>
              <br />
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="Type"
                  name="rgType"
                  value={applicationData.applicationType}
                  onChange={onSelectBadgeType}
                >
                  <FormControlLabel
                    label="New"
                    value="new"
                    control={<Radio color="primary" />}
                    style={{ marginRight: 32 }}
                  />
                  <FormControlLabel label="Renewal" value="renewal" control={<Radio color="primary" />} />
                </RadioGroup>
              </FormControl>
            </FormGroup>
            <FormGroup>
              <Label className="required">Authorized Signatory</Label>
              <Select
                classNamePrefix="airbadge"
                options={authorizedSignatories}
                className="form-select"
                value={authorizedSignatory}
                placeholder=""
                isDisabled={isSignatory || applicationData.applicationType === null}
                onChange={option => {
                  setApplicationData({ ...applicationData, willSupplyOwnChrc: null });
                  setAuthorizedSignatory(option);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label className="required">Individual</Label>
              {isSignatory ? (
                <Select
                  classNamePrefix="airbadge"
                  options={getIndividualsForDropdown()}
                  className="form-select"
                  value={applicationData.user}
                  placeholder=""
                  onChange={onSelectUser}
                  isDisabled={applicationData.applicationType === null}
                />
              ) : (
                <>
                  <AutoCompleteDropdown
                    id="selectedUser"
                    value={applicationData.user}
                    onInputChange={findUsers}
                    onValueSelected={onSelectUser}
                    options={individuals}
                    isLoading={isUsersLoading}
                    placeholder="Search by first or last name"
                    size="small"
                    filterOptions={filterUserOptions}
                    renderOption={user => <AutoCompleteUserOption user={user} />}
                    disabled={applicationData.applicationType === null}
                  />
                  {!isRenewalMode && applicationData.applicationType && applicationData.applicationType === 'new' && (
                    <div style={{ width: '100%', textAlign: 'right', marginTop: 10 }}>
                      <Button outline color="primary" onClick={() => setIsPeopleCreateModalOpen(true)}>
                        Create New Individual
                      </Button>
                    </div>
                  )}
                </>
              )}
            </FormGroup>
            {isRenewalMode && (
              <FormGroup>
                <Label className="required">Badge Number</Label>
                <Select
                  classNamePrefix="airbadge"
                  options={badges}
                  components={{ Option: CustomIndividualOption }}
                  className="form-select"
                  value={applicationData.badge}
                  placeholder=""
                  isDisabled={applicationData.applicationType === null || applicationData.user === null}
                  onChange={onSelectBadge}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Label className="required">Badge Type (Access Level)</Label>
              <Select
                classNamePrefix="airbadge"
                options={badgeTypes}
                className="form-select"
                value={applicationData.badgeType}
                placeholder=""
                isDisabled={isRenewalMode || applicationData.applicationType === null}
                onChange={option =>
                  setApplicationData({
                    ...applicationData,
                    badgeType: option,
                    willSupplyOwnChrc: null,
                  })
                }
                components={{ Option: BadgeTypeOption }}
              />
            </FormGroup>

            <BadgeApplicationEndorsementsPicker shouldSkipDefaultSelection={isRenewalMode} />

            {doesRequireChrc() ? (
              <div>
                <Label className="required">
                  Will <strong>{authorizedSignatory.companyName}</strong> be providing the CHRC for this applicant?
                </Label>
                <br />
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="company providing chrc question"
                    name="rgChrc"
                    value={
                      applicationData.willSupplyOwnChrc === true
                        ? 'yes'
                        : applicationData.willSupplyOwnChrc === false
                          ? 'no'
                          : null
                    }
                    onChange={({ target }) => {
                      if (target.value === 'yes') {
                        setApplicationData({ ...applicationData, willSupplyOwnChrc: true });
                      } else {
                        setApplicationData({ ...applicationData, willSupplyOwnChrc: false });
                      }
                    }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" disabled={applicationData.applicationType === null} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" disabled={applicationData.applicationType === null} />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            ) : null}

            <div>&nbsp;</div>

            <BadgeQuestions
              displayMode="edit"
              question1Value={applicationData.doors}
              onQuestion1Changed={value => setApplicationData({ ...applicationData, doors: value })}
              question2Value={applicationData.purpose}
              onQuestion2Changed={value => setApplicationData({ ...applicationData, purpose: value })}
            />

            <div>&nbsp;</div>

            <Feature id="payments">
              <FormGroup>
                <Label className="required">Who is going to pay for this badge application?</Label>
                <Select
                  placeholder=""
                  classNamePrefix="airbadge"
                  className="form-select"
                  options={getPaymentOptions()}
                  value={paymentChoice}
                  onChange={setPaymentChoice}
                />
              </FormGroup>
            </Feature>
          </BadgeApplicationContext.Provider>
        </ModalBody>
        <ModalFooter>
          <ModalActions
            closeLabel="Close"
            onClose={onModalClose}
            saveLabel="Create"
            onSave={onCreateClick}
            saveDisabled={isCreateButtonDisabled()}
          />
        </ModalFooter>
      </Modal>

      <CreateNewPersonForBadgeApplicationModal
        isOpen={isPeopleCreateModalOpen}
        onClose={onPeopleListCreateModalClose}
      />

      <FixEmailModal
        isOpen={isFixEmailModalOpen}
        onDone={isSuccess => onFixEmailDone(isSuccess, applicationData?.user?.original)}
        user={applicationData?.user?.original}
      />

      <DocSpringDocument
        transactionUuid={transactionUuid}
        onCancelled={onCancelled}
        onSubmitSuccess={onSubmitSuccess}
      />
    </>
  );
}
