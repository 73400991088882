import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import NextButton from '../components/NextButton';
import { BlockFormLabel } from '../components/styledComponents';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useConfirm from '../../../shared/useConfirm';

export default function SupplementalDocuments({ isHidden, value, data, onChange, onNext }) {
  const showConfirm = useConfirm();
  const { t: translate } = useTranslation('translation', { keyPrefix: 'badger' });
  const [isNextHidden, setIsNextHidden] = useState(true);

  if (isHidden) return null;

  const shouldOnlyShowI94Option = () => {
    const listADocument = data?.listADocuments?.value;
    return ['permanent-resident-card', 'i-766'].indexOf(listADocument) > -1;
  };

  const shouldShowCertificateOfNaturalizationOption = () => {
    const isNaturalizedCitizen = data?.citizenship?.value === 'usa_naturalized';
    const listCDocument = data?.listCDocuments?.value;
    return isNaturalizedCitizen && listCDocument !== 'certificate-of-naturalization';
  };

  const onNextClicked = () => {
    if (shouldShowCertificateOfNaturalizationOption && value === 'none') {
      showConfirm({
        title: translate('actions.start-over-confirm'),
        content: (
          <Alert color="warning">
            <p>{translate('supplementalDocuments.warning')}</p>
            <div>{translate('supplementalDocuments.warning-confirm-text')}</div>
          </Alert>
        ),
        onConfirm: () => onNext(),
        okButtonText: translate('supplementalDocuments.warning-confirm-button'),
      });
    } else {
      onNext();
    }
  };

  return (
    <>
      <BlockFormLabel>
        <Typography variant="h6">{translate('supplementalDocuments.question1.label')}</Typography>
      </BlockFormLabel>

      {shouldShowCertificateOfNaturalizationOption && (
        <Alert color="warning">{translate('supplementalDocuments.warning')}</Alert>
      )}

      <FormControl>
        <RadioGroup
          aria-label="supplemental documents"
          name="supplementalDocuments"
          value={value}
          onChange={({ target }) => {
            onChange(target.value);
            setIsNextHidden(!target.value);
          }}
        >
          <FormControlLabel
            value="i-94"
            control={<Radio color="primary" />}
            label={translate('supplementalDocuments.question1.answer1')}
          />

          {!shouldOnlyShowI94Option() && (
            <FormControlLabel
              value="arn"
              control={<Radio color="primary" />}
              label={translate('supplementalDocuments.question1.answer2')}
            />
          )}

          {shouldShowCertificateOfNaturalizationOption && (
            <FormControlLabel
              value="certificate-of-naturalization"
              control={<Radio color="primary" />}
              label={translate('listCDocuments.question1.answer5')}
            />
          )}

          <FormControlLabel
            value="none"
            control={<Radio color="primary" />}
            label={translate('supplementalDocuments.question1.answer3')}
          />
        </RadioGroup>
      </FormControl>

      <NextButton isDisabled={isNextHidden} onClick={onNextClicked} />
    </>
  );
}
