import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Select from 'react-select';
import { Alert, FormGroup, Input, Label } from 'reactstrap';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MuiFormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import request from '../../../../ajax/Request';
import { AppContext } from '../../../../App';
import { handleError } from '../../../../utils';
import ReadOnlyFormData from '../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import AutoCompleteDropdown from '../../../../shared/components/AutoCompleteDropdown/AutoCompleteDropdown';
import badgeApi from '../../../../ajax/Badge/badgeApi';
import BadgesForUserRequest from '../../../../ajax/Badge/BadgesForUserRequest';
import badgeApplicationApi from '../../../../ajax/BadgeApplication/badgeApplicationApi';
import { nonCollectibleStatuses, reasons } from './BadgeCreateModal';
import EscortEndorsementPicker from '../../BadgeApplications/BadgeApplicationEndorsementsPicker/components/EscortEndorsementPicker';
import DrivingEndorsementPicker from '../../BadgeApplications/BadgeApplicationEndorsementsPicker/components/DrivingEndorsementPicker';
import { getDrivingOptions } from '../../../../shared/BadgeTypes/drivingOptionsUtils';
import IssueBadgeApplicationBadgeConfirmationModal from './CreateBadgeApplicationBadgeModal/components/IssueBadgeApplicationBadgeConfirmationModal';
import { uploadProfilePicForPerson } from '../../../../imageUtils';

export function BadgeEntry({ badge }) {
  const app = useContext(AppContext);
  const { type } = badge;
  const effectiveDates = parseDates(badge, app.timezone);
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '8px 0' }}>
      <div style={{ marginRight: 24 }}>
        <ReadOnlyFormData skipTextTransform label="Badge Type" value={type?.displayName || <i>Not Set</i>} />
      </div>
      <div style={{ marginRight: 24 }}>
        <ReadOnlyFormData
          skipTextTransform
          label="Card Number (Serial Number)"
          value={`${badge.number || '-'} (${badge.cardSerialNumber || '-'})`}
        />
      </div>
      <div style={{ flex: 1 }}>
        <ReadOnlyFormData skipTextTransform label="Effective Dates" value={effectiveDates} />
      </div>
    </div>
  );
}

export function CustomBadgeDropdownOption(props) {
  const { innerProps, innerRef, data, getStyles } = props;
  const styles = getStyles('option', props);
  const { original } = data;
  return (
    <div ref={innerRef} {...innerProps} style={styles}>
      <BadgeEntry badge={original} />
    </div>
  );
}

export function RowWithSpacer({ spacing, children }) {
  return (
    <Grid container spacing={spacing || 4}>
      {children}
    </Grid>
  );
}

export function ColumnWithData({ children }) {
  return (
    <Grid item sm={12} md={6}>
      {children}
    </Grid>
  );
}

export const parseDates = ({ effective, expires }, timezone) => {
  let start = 'MISSING';
  let end = 'MISSING';

  if (effective) start = moment.tz(effective, timezone).format('MM/DD/YYYY');
  if (expires) end = moment.tz(expires, timezone).format('MM/DD/YYYY');

  return (
    <div>
      {start}
      <span style={{ padding: '0 8px' }}>-</span>
      {end}
    </div>
  );
};

export const defaultBadgeData = { pacsBadgeId: null, group: null, badgeType: null, driving: null, parking: null };

export default function IssuePacsBadgeModal({ isOpen, onClose, badgeApplication, badgeTypes }) {
  const theme = useTheme();
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newBadge, setNewBadge] = useState({ ...defaultBadgeData });
  const [groups, setGroups] = useState([]);
  const [unmatchedBadges, setUnmatchedBadges] = useState([]);
  const [hasBadgeToDeactivate, setHasBadgeToDeactivate] = useState(null);
  const [isLoadingBadges, setIsLoadingBadges] = useState(false);
  const [badges, setBadges] = useState([]);
  const [oldBadge, setOldBadge] = useState(null);
  const [deactivationReason, setDeactivationReason] = useState(null);
  const [isMisprintWarningShown, setsMisprintWarningShown] = useState(false);
  const [isCollected, setIsCollected] = useState(false);
  const [collectedDate, setCollectedDate] = useState(moment());
  const [selectedPacsBadge, setSelectedPacsBadge] = useState(null);
  const [remainingPacsBadges, setRemainingPacsBadges] = useState([]);
  const [signatoryOption, setSignatoryOption] = useState('no');
  const [isIssueBadgeApplicationBadgeConfirmationModalOpen, setIsIssueBadgeApplicationBadgeConfirmationModalOpen] =
    useState(false);

  const onModalClose = (shouldReload = false) => {
    setRemainingPacsBadges([]);
    setSelectedPacsBadge(null);
    setCollectedDate(moment());
    setIsCollected(false);
    setsMisprintWarningShown(false);
    setDeactivationReason(null);
    setOldBadge(null);
    setBadges([]);
    setIsLoadingBadges(false);
    setHasBadgeToDeactivate(null);
    setNewBadge({ ...defaultBadgeData });
    setGroups([]);
    setUnmatchedBadges([]);
    setIsModalOpen(false);
    onClose(shouldReload);
  };

  const badgeTypeOptions = (badgeTypes || []).map(type => ({
    label: type.displayName,
    value: type.badgeTypeID,
    original: type,
  }));

  const processApiResponseForGroups = response => {
    setGroups(response.results.map(r => ({ label: r, value: r })));
  };

  const processApiResponseForUnmatchedBadges = ({ unmatchedBadges: badges }) => {
    const { driving } = badgeApplication;

    const drivingOptions = getDrivingOptions(badgeApplication?.badgeType?.config?.endorsements?.driving);
    const drivingOption = drivingOptions.find(x => x.value === driving) || drivingOptions[0];

    badges = badges.map(b => {
      const badgeType = badgeTypeOptions.find(x => x.value === b?.type?.badgeTypeID)?.original;
      return {
        ...b,
        badgeType: badgeType,
        type: badgeType,
        effectiveDates: parseDates(b, app.timezone),
        driving: drivingOption,
      };
    });

    setUnmatchedBadges(badges.map(b => ({ label: b.number, value: b.pacsBadgeId, original: b })));

    if (badges.length === 1) {
      const match = badges[0];
      setNewBadge({ ...defaultBadgeData, ...match, badgeType: match?.badgeType || badgeApplication.badgeType });
    }
  };

  const processApiResponseForExistingBadges = badges => {
    const { renewingBadge } = badgeApplication;
    const filteredAndMapped = badges
      .filter(x => x.active)
      .map(x => {
        const status = app.badgeStatuses.find(s => s.value === x.status) || { label: 'unknown' };
        const badge = { label: `${x.number} (${x.type.toLocaleUpperCase()}) (${status.label})`, value: x.uuid };
        if (renewingBadge && x.uuid === renewingBadge.uuid) {
          setOldBadge(badge);
          setHasBadgeToDeactivate('yes');
        }
        return badge;
      });

    setBadges(filteredAndMapped);
  };

  const loadData = () => {
    const {
      applicant: { uuid: applicantUuid, tscPersonId },
    } = badgeApplication;
    app.api.toggleLoading(true);

    Promise.all([
      badgeApi.getGroups(),
      request('authenticated-user', 'GET', `pacs/unmatched-badges/${tscPersonId}`),
      BadgesForUserRequest(null, { userUuid: applicantUuid }),
    ])
      .then(([groupsResponse, unmatchedBadgesResponse, applicantBadgesResponse]) => {
        if (groupsResponse.success) processApiResponseForGroups(groupsResponse);
        if (unmatchedBadgesResponse.success) processApiResponseForUnmatchedBadges(unmatchedBadgesResponse);
        processApiResponseForExistingBadges(applicantBadgesResponse || []);
      })
      .catch(error => handleError({ error, message: 'Unable to load badges from access control' }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onIssueBadgeClicked = () => {
    setIsIssueBadgeApplicationBadgeConfirmationModalOpen(true);
  };

  const onIssueBadgeApplicationBadgeConfirmationModalClose = didConfirm => {
    setIsIssueBadgeApplicationBadgeConfirmationModalOpen(false);
    if (didConfirm) {
      onIssueBadge();
    }
  };

  const onIssueBadge = () => {
    const { uuid: badgeApplicationUuid, applicant } = badgeApplication;
    const groupNameElement = document.getElementById('newGroup');
    const deactivationCommentElement = document.getElementById('deactivationComment');
    const groupName = groupNameElement ? groupNameElement.value : null;
    const deactivationComment = deactivationCommentElement ? deactivationCommentElement.value : null;
    const reason = deactivationReason ? deactivationReason.value.toLocaleLowerCase() : null;
    const collected =
      isCollected && collectedDate ? moment.tz(collectedDate, app.timezone).format('YYYY-MM-DD HH:mm:ss') : null;
    const data = {
      isActive: true,
      badgeApplicationUuid,
      pacsBadgeId: newBadge.pacsBadgeId,
      badgeTypeUuid: newBadge.badgeType?.uuid || newBadge.type?.uuid || null,
      group: groupName,
      deactivatedBadge: {
        uuid: oldBadge ? oldBadge.value : null,
        reason,
        collected,
        comment: deactivationComment,
      },
      accessControlBadgesToCleanup: remainingPacsBadges.map(b => ({
        pacsBadgeId: b.pacsBadgeId,
        badgeTypeUuid: b.badgeType?.uuid || b.type?.uuid || null,
        resolution: b.resolution,
      })),
      shouldIssueToOriginalSignatory: signatoryOption === 'yes',
    };

    app.api.toggleLoading(true);
    badgeApplicationApi
      .issuePacsBadge(data)
      .then(({ success, headshotImageData }) => {
        if (!success) return;
        if (headshotImageData) {
          return uploadProfilePicForPerson({
            userUuid: applicant.uuid,
            imageData: headshotImageData,
          }).finally(() => onModalClose(true));
        } else {
          onModalClose(true);
        }
      })
      .catch(error =>
        handleError({
          error,
          message: 'Unable to issue badge',
        })
      )
      .finally(() => app.api.toggleLoading(false));
  };

  const onBadgeSelectedFromQueue = ({ badge }) => {
    let updatedPacsBadgeData = { ...badge };
    if (!badge?.badgeType?.displayName) {
      updatedPacsBadgeData = { ...badge, badgeType: badgeApplication.badgeType };
    }
    const remaining = unmatchedBadges
      .filter(b => b.value !== updatedPacsBadgeData.pacsBadgeId)
      .map(b => ({
        ...b.original,
        badgeType: b.original.badgeType || updatedPacsBadgeData.badgeType || null,
        type: b.original.badgeType || updatedPacsBadgeData.badgeType || null,
        resolved: false,
        resolution: null,
      }));
    setSelectedPacsBadge(updatedPacsBadgeData);
    setRemainingPacsBadges(remaining);
    setNewBadge({ ...defaultBadgeData, ...updatedPacsBadgeData });
  };

  const isSaveDisabled = () => {
    const { badgeType, cardSerialNumber, number, effective, expires } = newBadge;
    if (!badgeType || !cardSerialNumber || !number || !effective || !expires) {
      return true;
    }
    if (!hasBadgeToDeactivate || (hasBadgeToDeactivate === 'yes' && !oldBadge)) {
      return true;
    }
    if (oldBadge && !deactivationReason) {
      return true;
    }
    return !!remainingPacsBadges.find(b => b.resolved === false);
  };

  const isCollectibleStatus = () => {
    return deactivationReason && !nonCollectibleStatuses.includes(deactivationReason.value);
  };

  const getDrivingOption = () => {
    const drivingOptions = getDrivingOptions(newBadge?.badgeType?.config?.endorsements?.driving);
    if (typeof newBadge?.driving === 'string' || newBadge?.driving instanceof String) {
      return drivingOptions.find(option => option.value === newBadge?.driving) || 'ERROR';
    } else if (newBadge?.driving) {
      return newBadge?.driving;
    } else {
      return drivingOptions[0];
    }
  };

  useEffect(() => {
    if (!isOpen || !badgeApplication) return;

    setIsModalOpen(true);
    loadData();
  }, [isOpen, badgeApplication]);

  if (!isOpen || !badgeApplication) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title="Issue Badge"
      isOpen={isModalOpen}
      onClose={onModalClose}
      closeLabel="Cancel"
      onSave={onIssueBadgeClicked}
      saveLabel="Issue Badge"
      saveDisabled={isSaveDisabled()}
    >
      {!!badgeApplication?.originalSignatory && !!badgeApplication?.originalSignatory?.isActive && (
        <Alert color="warning">
          <i>{badgeApplication?.signatory?.fullName}</i> replaced <i>{badgeApplication?.originalSignatory?.fullName}</i>{' '}
          as the signatory on this badge application.
          <br />
          <br />
          Would you like to make <i>{badgeApplication?.originalSignatory?.fullName}</i> the signatory on the issued
          badge?
          <RadioGroup
            value={signatoryOption}
            onChange={event => {
              event.stopPropagation();
              setSignatoryOption(event?.target?.value);
            }}
            row
          >
            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </Alert>
      )}

      <RowWithSpacer>
        <ColumnWithData>
          <ReadOnlyFormData label="Badge Holder" value={badgeApplication?.applicant?.fullName || 'ERROR'} />
        </ColumnWithData>
        <ColumnWithData>
          <ReadOnlyFormData
            label="Authorized Signatory"
            value={
              signatoryOption === 'yes'
                ? badgeApplication?.originalSignatory?.fullName
                : badgeApplication?.signatory?.fullName
            }
          />
        </ColumnWithData>
      </RowWithSpacer>

      {unmatchedBadges.length === 0 && (
        <Alert color="warning" style={{ marginTop: 16 }}>
          No badges found waiting in the queue. The badge needs to be first created in access control before the badge
          can be issued in AirBadge. Make sure you wait at least 90 seconds after creating the badge in access control
          before continuing the process here in AirBadge.
        </Alert>
      )}

      {unmatchedBadges.length > 1 && !selectedPacsBadge && (
        <div>
          <Alert color="info" style={{ marginBottom: 16, marginTop: 16 }}>
            <i>{unmatchedBadges.length} access control badges were found waiting in the queue.</i>
          </Alert>
          <FormGroup>
            <Label>Which badge should be issued?</Label>
            <Select
              classNamePrefix="airbadge"
              name="pacsBadge"
              options={unmatchedBadges}
              value={selectedPacsBadge}
              onChange={entry => onBadgeSelectedFromQueue({ badge: entry.original })}
              className="form-select"
              placeholder=""
              components={{ Option: CustomBadgeDropdownOption }}
            />
          </FormGroup>
        </div>
      )}

      {newBadge.pacsBadgeId && (
        <>
          <Alert color="info" fade={false} style={{ marginTop: theme.spacing(2) }}>
            If any of the data below is incorrect, it will need to be corrected in access control before this badge can
            be issued.
          </Alert>
          <RowWithSpacer>
            <ColumnWithData>
              <ReadOnlyFormData
                skipTextTransform
                label="Badge Type"
                value={newBadge?.badgeType?.label || newBadge?.badgeType?.displayName || 'Not Set'}
              />
            </ColumnWithData>
            <ColumnWithData>
              <ReadOnlyFormData skipTextTransform label="Effective Dates" value={newBadge.effectiveDates} />
            </ColumnWithData>
          </RowWithSpacer>
          <RowWithSpacer>
            <ColumnWithData>
              <ReadOnlyFormData label="Badge ID" value={newBadge.number} />
            </ColumnWithData>
            <ColumnWithData>
              <ReadOnlyFormData label="Card Number" value={newBadge.cardSerialNumber} />
            </ColumnWithData>
          </RowWithSpacer>
          <RowWithSpacer>
            <ColumnWithData>
              <EscortEndorsementPicker
                isReadOnly={true}
                context={{
                  escortEndorsementConfig: newBadge?.badgeType?.config?.endorsements?.escort,
                  escortSelection: newBadge.isEscort ? { label: 'yes', value: 1 } : { label: 'no', value: 0 },
                  setEscortSelection: () => false,
                  shouldSkipDefaultSelection: true,
                  data: { badgeType: newBadge?.badgeType },
                }}
              />
            </ColumnWithData>
            <ColumnWithData>
              <ReadOnlyFormData label="Law Enforcement Officer (LEO)" value={newBadge.isLEO ? 'Yes' : 'No'} />
            </ColumnWithData>
          </RowWithSpacer>
          <RowWithSpacer>
            <ColumnWithData>
              <DrivingEndorsementPicker
                isReadOnly={true}
                context={{
                  drivingEndorsementConfig: newBadge?.badgeType?.config?.endorsements?.driving,
                  drivingSelection: getDrivingOption(),
                  setDrivingSelection: () => false,
                  shouldSkipDefaultSelection: true,
                  data: { badgeType: newBadge?.badgeType },
                }}
              />
            </ColumnWithData>
          </RowWithSpacer>
          <RowWithSpacer>
            <ColumnWithData>
              <FormGroup>
                <Label>
                  Group <i style={{ fontSize: 'smaller' }}>(optional)</i>
                </Label>
                <AutoCompleteDropdown
                  id="newGroup"
                  value={newBadge.group}
                  onValueSelected={option => setNewBadge({ ...newBadge, group: option })}
                  options={groups}
                  placeholder=""
                  size="small"
                  getOptionLabel={option => option.label || 'error'}
                  // TODO: not using event handler for this because the keyboard loses focus when typing
                  //       and I have no idea why
                  // onInputChange={event => {
                  //   if (event && event.target) {
                  //     if (event.target.value) setNewGroupManualEntry(event.target.value);
                  //   }
                  // }}
                  freeSolo
                  disableClearable
                />
              </FormGroup>
            </ColumnWithData>
          </RowWithSpacer>

          <div className="separator" style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(3) }}>
            Deactivation
          </div>

          <Grid container spacing={1}>
            <Grid item>
              Does <strong>{badgeApplication?.applicant?.fullName || 'ERROR'}</strong> have an active badge that needs
              to be deactivated?
            </Grid>
          </Grid>

          <RowWithSpacer>
            <ColumnWithData>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="hasBadgeToDeactivate"
                  name="hasBadgeToDeactivate"
                  value={hasBadgeToDeactivate}
                  onChange={({ target: { value } }) => setHasBadgeToDeactivate(value)}
                >
                  <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                </RadioGroup>
              </FormControl>
            </ColumnWithData>
          </RowWithSpacer>

          {hasBadgeToDeactivate === 'yes' && (
            <>
              <Grid container spacing={4}>
                <ColumnWithData>
                  <FormGroup>
                    <Label className="required">Badge to be deactivated</Label>
                    <Select
                      classNamePrefix="airbadge"
                      options={badges}
                      className="form-select"
                      value={oldBadge}
                      placeholder=""
                      isClearable
                      isDisabled={hasBadgeToDeactivate !== 'yes'}
                      isLoading={isLoadingBadges}
                      noOptionsMessage={() => 'No active badges to deactivate'}
                      onChange={value => setOldBadge(value)}
                    />
                  </FormGroup>
                </ColumnWithData>
                {oldBadge && (
                  <ColumnWithData xs="12" sm="6">
                    <FormGroup>
                      <Label className="required">Deactivation Reason</Label>
                      <Select
                        classNamePrefix="airbadge"
                        options={reasons}
                        className="form-select"
                        value={deactivationReason}
                        placeholder=""
                        isDisabled={!oldBadge}
                        onChange={x => {
                          setDeactivationReason(x);
                          setsMisprintWarningShown(false);
                          setIsCollected(!nonCollectibleStatuses.includes(x.value));
                        }}
                      />
                    </FormGroup>
                  </ColumnWithData>
                )}
              </Grid>
              {isCollectibleStatus() && oldBadge && (
                <Grid
                  container
                  spacing={4}
                  style={!isCollected && oldBadge ? { marginBottom: theme.spacing(1) } : null}
                >
                  <ColumnWithData>
                    <MuiFormGroup row>
                      <FormControlLabel
                        control={<Switch color="primary" />}
                        label={isCollected ? 'Badge was collected' : 'Badge was not collected'}
                        checked={isCollected}
                        disabled={!oldBadge}
                        onChange={({ target: { checked } }) => {
                          setIsCollected(checked);
                          setsMisprintWarningShown(!checked && deactivationReason.label === 'Misprint');
                        }}
                      />
                    </MuiFormGroup>
                  </ColumnWithData>
                  {isCollected && oldBadge && (
                    <ColumnWithData>
                      <FormGroup>
                        <Label className="required">Collected Date</Label>
                        <DatetimePickerTrigger
                          className="collectedDate-trigger"
                          moment={collectedDate}
                          showTimePicker={false}
                          onChange={moment => setCollectedDate(moment)}
                        >
                          <Input type="text" id="collectedDate" value={collectedDate.format('MM/DD/YYYY')} readOnly />
                        </DatetimePickerTrigger>
                      </FormGroup>
                    </ColumnWithData>
                  )}
                </Grid>
              )}
              {isMisprintWarningShown && (
                <RowWithSpacer>
                  <Grid item>
                    <Alert color="warning" fade={false}>
                      If this badge was issued, select a different status to represent why it is being deactivated.
                    </Alert>
                  </Grid>
                </RowWithSpacer>
              )}
              {oldBadge && (
                <RowWithSpacer>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Label>
                        Deactivation Comment <i style={{ fontSize: 'smaller' }}>(optional)</i>
                      </Label>
                      <Input
                        id="deactivationComment"
                        required
                        disabled={!oldBadge}
                        type="textarea"
                        // TODO: not using event handler for this because the keyboard loses focus when typing
                        //       and I have no idea why
                        // value={deactivationComment}
                        // onChange={({ target: { value } }) => setDeactivationComment(value)}
                      />
                    </FormGroup>
                  </Grid>
                </RowWithSpacer>
              )}
            </>
          )}
        </>
      )}

      {!!remainingPacsBadges.length && (
        <>
          <div className="separator" style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(3) }}>
            What should be done with the other access control badges in the queue?
          </div>

          <Grid container direction="column">
            <style scoped>{`
              .pacs-resolution-label {
                font-size: 0.9rem;
              }
            `}</style>
            {remainingPacsBadges.map((badge, index) => (
              <Grid
                key={badge.pacsBadgeId}
                item
                style={{ borderBottom: '1px dashed black', marginBottom: theme.spacing(2) }}
              >
                <BadgeEntry badge={badge} />
                <FormControl>
                  <RadioGroup
                    aria-label="PACS badge resolution"
                    name="resolution"
                    value={badge.resolution}
                    onChange={({ target: { value } }) => {
                      badge.resolved = true;
                      badge.resolution = value;
                      remainingPacsBadges.splice(index, 1, badge);
                      setRemainingPacsBadges([...remainingPacsBadges]);
                    }}
                  >
                    <FormControlLabel
                      value="not_printed"
                      control={<Radio color="primary" size="small" />}
                      label="Not printed"
                      classes={{ label: 'pacs-resolution-label' }}
                    />
                    <FormControlLabel
                      value="misprint_collected"
                      control={<Radio color="primary" size="small" />}
                      label={
                        <div>
                          Misprint <i style={{ fontSize: 'smaller' }}>(not issued or collected)</i>
                        </div>
                      }
                      classes={{ label: 'pacs-resolution-label' }}
                    />
                    <FormControlLabel
                      value="misprint_not_collected"
                      control={<Radio color="primary" size="small" />}
                      label={
                        <div>
                          Misprint <i style={{ fontSize: 'smaller' }}>(issued and not collected)</i>
                        </div>
                      }
                      classes={{ label: 'pacs-resolution-label' }}
                    />
                    <FormControlLabel
                      value="not_related"
                      control={<Radio color="primary" size="small" />}
                      label={
                        <div>
                          Not related to this badge application <i style={{ fontSize: 'smaller' }}>(very uncommon)</i>
                        </div>
                      }
                      classes={{ label: 'pacs-resolution-label' }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <IssueBadgeApplicationBadgeConfirmationModal
        isOpen={isIssueBadgeApplicationBadgeConfirmationModalOpen}
        onClose={onIssueBadgeApplicationBadgeConfirmationModalClose}
        isDeactivatingBadge={hasBadgeToDeactivate === 'yes'}
      />
    </AirBadgeModal>
  );
}
