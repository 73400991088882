import moment from 'moment';
import 'moment-timezone';
import { toast } from 'react-toastify';

export const validEmailAddressRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export const getScreenWidth = () => {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
};

export const isSmallScreen = () => {
  return getScreenWidth() < 768;
};

export const getLMSLabel = code => {
  let result;
  switch (code) {
    case 'iet':
      result = 'IET';
      break;
    case 'ssi':
      result = 'SSI';
      break;
    case 'digicast':
      result = 'DIGICAST';
      break;
    case 'airbadge_lms':
      result = 'AirBadge LMS';
      break;
    default:
      result = 'Unknown';
      break;
  }
  return result;
};

export const sanitizePhoneNumber = phoneNumber => {
  return phoneNumber.replace('+', '').replace(/\(/gi, '').replace(/\)/gi, '').replace(/-/gi, '').replace(/\s/gi, '');
};

export const formatPhoneNumber = str => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
};

export const capitalize = string => {
  const firstLetter = string.substr(0, 1);
  return firstLetter.toUpperCase() + string.substr(1);
};

export const getNotifyViaDisplayText = notifyVia => {
  switch (notifyVia) {
    case 'both':
      return 'Email & Text';
    case 'email':
      return 'Email';
    case 'text':
      return 'Text';
    default:
      return 'Not Set';
  }
};

export const getPriorityTextClass = priority => {
  switch (priority) {
    case 'low':
      return 'text-info';
    case 'normal':
      return 'text-primary';
    case 'high':
      return 'text-danger';
    default:
      return '';
  }
};

export const getPriorityIconClass = priority => {
  switch (priority) {
    case 'low':
    case 'normal':
    default:
      return '';
    case 'high':
      return 'fa fa-exclamation-triangle';
  }
};

export const downloadFile = ({ data, type, name = 'airbadge_download' }) => {
  const a = document.createElement('a');
  a.href = `data:${type};base64,${data}`;
  a.download = name;
  a.rel = 'noopener';
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const buildFullName = person => {
  if (person.fullName || person.full_name) return person.fullName || person.full_name;

  const firstName = person.firstName || person.first_name || person.first;
  const middleName = person.middleName || person.middle_name || person.middle;
  const lastName = person.lastName || person.last_name || person.last;
  const suffix = person.suffix;
  const nickname = person.nickname;

  let name = '';
  if (firstName) {
    name = `${firstName.toLocaleUpperCase()}`;
  }
  if (middleName) {
    name = `${name} ${middleName.toLocaleUpperCase()}`;
  }
  if (lastName) {
    name = `${name} ${lastName.toLocaleUpperCase()}`;
  }
  if (suffix) {
    name = `${name}, ${suffix.toLocaleUpperCase()} `;
  }
  if (nickname) {
    name = `${name} "${nickname}"`;
  }
  return name;
};

export const buildFullNameWithExtras = ({ firstName, middleName, lastName, suffix, nickname, dob }) => {
  let fullName = buildFullName({ firstName, middleName, lastName, suffix });
  if (nickname) fullName = `${fullName} "${nickname}"`;
  if (dob) fullName = `${fullName} (dob: ${dob || 'not set'})`;
  return fullName;
};

export const buildSortURIString = sorted => {
  let sort_string = '';
  sorted.forEach((item, key) => {
    sort_string += `sorted[${key}][id]=${item.id}&sorted[${key}][desc]=${item.desc}&`;
  });
  return sort_string;
};

export const buildFilterURIString = filter => {
  let filter_string = '';
  filter.forEach((item, key) => {
    filter_string += `filtered[${key}][id]=${item.id}&filtered[${key}][value]=${item.value}&`;
  });
  return filter_string;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const isValidDate = date => {
  if (
    moment(date, 'YYYY-MM-DD HH:mm:ss', true).isValid() ||
    moment(date, 'YYYY-MM-DD', true).isValid() ||
    moment(date, 'MM/DD/YYYY', true).isValid() ||
    moment(date, 'M/D/YYYY', true).isValid() ||
    moment(date, 'MM/D/YYYY', true).isValid() ||
    moment(date, 'M/DD/YYYY', true).isValid()
  ) {
    return true;
  } else {
    return false;
  }
};

export const formatDateWithoutTimeAndTimezone = ({ date, format = 'MMM DD, YYYY' }) => {
  if (date.includes(' ')) {
    const justTheDate = date.split(' ')[0];
    return moment(justTheDate, 'YYYY-MM-DD').format(format);
  } else if (date.includes('T')) {
    const justTheDate = date.split('T')[0];
    return moment(justTheDate, 'YYYY-MM-DD').format(format);
  } else {
    return date;
  }
};

export const setSessionValue = (key, value) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionValue = key => {
  return JSON.parse(window.sessionStorage.getItem(key));
};

export const deleteSessionValue = key => {
  window.sessionStorage.removeItem(key);
};

export const notify = ({ message, severity = 'success', duration, position = 'top-center' }) => {
  let adjustedDuration = duration || 2000;
  let toastFn;
  switch (severity) {
    case 'info':
      toastFn = toast.info;
      break;
    case 'warning':
      toastFn = toast.warn;
      if (!duration) adjustedDuration = 5000;
      break;
    case 'danger':
    case 'error':
      toastFn = toast.error;
      if (!duration) adjustedDuration = 5000;
      break;
    case 'success':
      toastFn = toast.success;
      break;
    default:
      toastFn = toast;
  }
  toastFn(message, { autoClose: adjustedDuration, position });
};

export const handleError = ({ error, message }) => {
  if (error) {
    // TODO: do we want to start logging these UI errors using a remote logging service?
    console.error(error);
  }
  if (message) {
    notify({ message, severity: 'error' });
  }
};

export function sortBadgeApplicationConfigByMilestone(config, shouldReturnedAsGroupedByMilestone = false) {
  if (!config) return {};

  const collect = {};
  const prepare = {};
  const transmit = {};
  const authorize = {};
  const issue = {};

  const sortMilestoneByOrder = config => {
    const sorted = {};
    Object.keys(config)
      .sort((a, b) => config[a]['order'] - config[b]['order'])
      .forEach(key => (sorted[key] = config[key]));
    return sorted;
  };

  Object.keys(config).forEach(key => {
    const entry = config[key];
    switch (entry['milestone']) {
      case 'collect':
        collect[key] = entry;
        break;
      case 'prepare':
        prepare[key] = entry;
        break;
      case 'transmit':
        transmit[key] = entry;
        break;
      case 'authorize':
        authorize[key] = entry;
        break;
      case 'issue':
        issue[key] = entry;
        break;
    }
  });

  if (shouldReturnedAsGroupedByMilestone) {
    return {
      collect: { ...sortMilestoneByOrder(collect) },
      prepare: { ...sortMilestoneByOrder(prepare) },
      transmit: { ...sortMilestoneByOrder(transmit) },
      authorize: { ...sortMilestoneByOrder(authorize) },
      issue: { ...sortMilestoneByOrder(issue) },
    };
  }

  return {
    ...sortMilestoneByOrder(collect),
    ...sortMilestoneByOrder(prepare),
    ...sortMilestoneByOrder(transmit),
    ...sortMilestoneByOrder(authorize),
    ...sortMilestoneByOrder(issue),
  };
}

export function convertMinutesToHoursMinutes(minutes) {
  if (minutes <= 0 || !Number.isInteger(minutes)) {
    return 'ERROR';
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  }
  if (remainingMinutes > 0) {
    parts.push(`${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`);
  }
  if (parts.length === 0) {
    parts.push('ERROR');
  }

  return parts.join(' ');
}

/**
 * @param date - a JavaScript Date object
 *
 * @returns {string} - a string in the format 'YYYY-MM-DD'
 */
export function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 *
 * @param dateString - a string in the format 'YYYY-MM-DD'
 * @param time - an integer in the format HHMM
 *
 * @returns {Date} - a Date object representing the given date and time
 */
export function buildDateObject(dateString, time) {
  if (typeof dateString !== 'string' || typeof time !== 'number') {
    return new Date();
  }

  const [year, month, day] = dateString.split('-').map(Number);

  // Extract hours and minutes from the integer time value
  const hours = Math.floor(time / 100);
  const minutes = time % 100;

  return new Date(year, month - 1, day, hours, minutes);
}
