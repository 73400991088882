import React, { useContext } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import InputWithCharacterLimit from '../../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import ReadOnlyFormData from '../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import { AppContext } from '../../../../App';

export default function BadgeQuestions({
  displayMode = 'view',
  question1Value = '',
  onQuestion1Changed = () => {},
  question2Value = '',
  onQuestion2Changed = () => {},
  xs = 12,
  sm,
  md,
  lg,
  xl,
}) {
  const app = useContext(AppContext);
  const airportCode = (app?.airport?.abbreviation || 'ABF').toLocaleUpperCase();

  if (displayMode !== 'edit' && displayMode !== 'view') {
    throw new Error('Invalid display mode');
  }

  let question1Label = 'Which door, pedestrian gate, and/or vehicle gate access is requested?';
  let question1AlternateLabel = 'Requested Access';
  let question2Label = 'For what purpose does this individual require a badge?';
  let question2AlternateLabel = 'Badge Purpose';

  if (airportCode === 'MHT') {
    question1Label = "What is the applicant's assigned work area?";
    question1AlternateLabel = 'Assigned Work Area';
    question2Label = 'What clearance/group is required?';
    question2AlternateLabel = 'Requested Clearance/Group';
  } else if (airportCode === 'GPT') {
    question1Label = 'List special requests outside normal authorized doors, otherwise type N/A';
    question1AlternateLabel = 'Special Requests';
    question2Label = 'For what purpose does this individual require a badge?';
    question2AlternateLabel = 'Badge Purpose';
  }

  if (displayMode === 'view') {
    return (
      <Grid container spacing={3} direction="row">
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <ReadOnlyFormData
            skipTextTransform
            label={question1AlternateLabel}
            value={question1Value || 'Not specified'}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <ReadOnlyFormData
            skipTextTransform
            label={question2AlternateLabel}
            value={question2Value || 'Not specified'}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <FormGroup>
          <Label>
            <div className="required">{question1Label}</div>
          </Label>
          <InputWithCharacterLimit
            style={{ height: 60 }}
            limit={191}
            value={question1Value}
            onChange={onQuestion1Changed}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <FormGroup>
          <Label className="required">{question2Label}</Label>
          <InputWithCharacterLimit
            style={{ height: 60 }}
            limit={191}
            value={question2Value}
            onChange={onQuestion2Changed}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}
