import React, { useCallback, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { Alert, Button, FormGroup, Label } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import { TabActions, ValidateContext } from '../ValidateBadgeApplicationAction';
import badgeApplicationApi from '../../../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import {
  AppContext,
  basePaymentChoices,
  invoicePaymentChoice,
  noChargePaymentChoice,
} from '../../../../../../../../App';
import { ActionPanelContext } from '../../../BadgeApplicationActionPanel';
import badgeApplicationCommentApi from '../../../../../../../../ajax/BadgeApplicationComment/badgeApplicationCommentApi';

export default function ValidatePayments({ isOpen }) {
  const app = useContext(AppContext);
  const { badgeApplication, updateBadgeApplicationConfig } = useContext(ActionPanelContext);
  const { setShouldReloadBadgeApplication, gotoTab, closeModal, isBackgroundCheckAlreadyStarted } =
    useContext(ValidateContext);

  const [shouldAllowPayInPerson, setShouldAllowPayInPerson] = useState(false);
  const [paymentChoice, setPaymentChoice] = useState(basePaymentChoices.find(x => x.value === 'signatory'));

  const onMarkComplete = async () => {
    app.api.toggleLoading(true);
    setShouldReloadBadgeApplication(true);

    badgeApplicationApi.updateSettings({
      badgeApplicationUuid: badgeApplication.uuid,
      changes: { paymentChoice: paymentChoice.value },
    });

    // Should fingerprints be added now that validation is 100% complete?
    if (
      badgeApplication.config.validation.meta.personValidation.chrcExemption.choice === 'false' &&
      badgeApplication.config.fingerprintAppointment.isEnabled === false
    ) {
      badgeApplication.config.fingerprintAppointment.isEnabled = true;
    }

    badgeApplication.config.validation.isComplete = true;
    await updateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
    // onStepMarkedComplete(badgeApplication.config);
    badgeApplicationCommentApi.create({
      badgeApplicationUuid: badgeApplication.uuid,
      comment: 'Badge Application Validation completed',
    });
    closeModal({ shouldReloadBadgeApplication: true, isValidationTotallyComplete: true });
  };

  const onChangePaymentChoice = choice => {
    setPaymentChoice(choice);
  };

  const getPaymentOptions = useCallback(() => {
    const { isApplicantPaymentEnabled, isSignatoryPaymentEnabled, isStripeEnabled, isInPersonPaymentEnabled } =
      app.airport.payments;

    let choices = [];

    if (isStripeEnabled) {
      if (isApplicantPaymentEnabled) {
        choices.push(basePaymentChoices.find(x => x.value === 'applicant'));
      }
      if (isSignatoryPaymentEnabled) {
        choices.push(basePaymentChoices.find(x => x.value === 'signatory'));
      }
    }

    if (isInPersonPaymentEnabled) {
      choices.push(basePaymentChoices.find(x => x.value === 'in-person'));
    }

    const authorizedSignatoryCompany = badgeApplication.signatory.company;
    if (authorizedSignatoryCompany) {
      if (authorizedSignatoryCompany.shouldNeverChargePayment) {
        choices.push(noChargePaymentChoice);
      } else if (authorizedSignatoryCompany.shouldAllowPaymentInvoice) {
        choices.push(invoicePaymentChoice);
      }
    }

    return choices;
  }, [shouldAllowPayInPerson, badgeApplication]);

  useEffect(() => {
    if (!isOpen || !badgeApplication) return;

    if (badgeApplication.paymentChoice === 'no-charge') {
      setPaymentChoice(noChargePaymentChoice);
    } else if (badgeApplication.paymentChoice === 'invoice') {
      setPaymentChoice(invoicePaymentChoice);
    } else {
      setPaymentChoice(basePaymentChoices.find(x => x.value === badgeApplication.paymentChoice));
    }

    const { isInPersonPaymentEnabled } = app.airport.payments;
    setShouldAllowPayInPerson(isInPersonPaymentEnabled);
  }, [isOpen, badgeApplication]);

  return (
    <>
      <Grid container direction="column">
        <Grid item s={12} md={7} lg={5}>
          <FormGroup>
            <Label>Who should be responsible for paying for this badge application?</Label>
            <Select
              placeholder=""
              classNamePrefix="airbadge"
              className="form-select"
              options={getPaymentOptions()}
              value={paymentChoice}
              onChange={onChangePaymentChoice}
            />
          </FormGroup>
        </Grid>
        <Grid item s={12} md={7} lg={5}>
          <Alert color="info">Fees can be changed after validation is complete</Alert>
        </Grid>
      </Grid>

      <TabActions>
        <Button onClick={() => gotoTab(2)}>
          <i className="fa-solid fa-chevron-left mr-2"></i>Documents
        </Button>
        <div>
          {isBackgroundCheckAlreadyStarted ? (
            <Button onClick={() => closeModal()}>Close</Button>
          ) : (
            <>
              <Button onClick={() => closeModal()} className="mr-2">
                Close
              </Button>
              <Button
                color="primary"
                onClick={onMarkComplete}
                style={{ marginLeft: 15, minWidth: 100 }}
                disabled={!!badgeApplication?.config?.validation?.isComplete}
              >
                Mark Validation Complete
              </Button>
            </>
          )}
        </div>
      </TabActions>
    </>
  );
}
