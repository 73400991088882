import React, { useCallback, useContext, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import { colors } from '../../../../../../App';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import UploadChrcDocumentationModal from '../../../../People/PeopleList/UploadChrcDocumentationModal';

export default function UploadChrcDocumentationAction() {
  const { badgeApplication, onActionDone, isCurrentUserSignatory } = useContext(ActionPanelContext);
  const { applicationDocument, uploadChrcDocumentation, adjudicateCHRC } = badgeApplication.config;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isHidden = useCallback(() => {
    if (!isCurrentUserSignatory) {
      return true;
    }
    if (applicationDocument.isEnabled && !applicationDocument.isComplete) {
      // Application document needs to be done first
      return true;
    }
    if (!uploadChrcDocumentation?.isEnabled || uploadChrcDocumentation?.isComplete) {
      return true;
    }
    if (adjudicateCHRC.isEnabled && adjudicateCHRC.isComplete) {
      return true;
    }
    return false;
  }, [applicationDocument]);

  if (isHidden()) return null;

  return (
    <>
      <ListItem button onClick={() => setIsModalOpen(true)}>
        <ListItemIcon>
          <DescriptionIcon style={{ color: colors.primary }} />
        </ListItemIcon>
        <ListItemText primary="Provide CHRC Documentation" />
      </ListItem>

      <UploadChrcDocumentationModal
        isOpen={isModalOpen}
        badgeApplication={badgeApplication}
        onDone={({ wasChrcDocumentationUploaded }) => {
          setIsModalOpen(false);
          onActionDone({ shouldReload: wasChrcDocumentationUploaded });
        }}
      />
    </>
  );
}
