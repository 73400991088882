import React from 'react';
import { Alert, FormGroup, Input, Label } from 'reactstrap';
import MuiFormGroup from '@material-ui/core/FormGroup';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TitledBox from '../../../../../shared/TitledBox/TitledBox';

function splitCamelCaseToUpper(word) {
  if (!word) return '';

  // Check if the word is in camelCase
  if (!/^([a-z]+[A-Z][a-z]*)+$/.test(word)) {
    // Not camel cased
    return word.toLocaleUpperCase();
  }

  // Split the word by capital letters and convert each part to uppercase
  return word
    .split(/(?=[A-Z])/)
    .map(part => part.toLocaleUpperCase())
    .join(' ');
}

function ConfigEntry({ label, config, onChange, isForLiveBadgeApplication }) {
  let curatedLabel = splitCamelCaseToUpper(label);
  curatedLabel = curatedLabel.replace('_', ' ');

  const applicantConfig = config.applicant;
  const signatoryConfig = config.signatory;

  return (
    <TitledBox title={curatedLabel}>
      <div className="separator">APPLICANT NOTIFICATION</div>
      {!applicantConfig ? (
        <div style={{ width: '100%', textAlign: 'center', margin: '24px 0' }}>
          <i>Not available</i>
        </div>
      ) : (
        <div style={{ margin: '18px 0 32px 0' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 12 }}>
            <MuiFormGroup row>
              <MuiFormControlLabel
                control={<Switch color="primary" />}
                label="Enabled"
                checked={applicantConfig.isEnabled}
                onChange={event =>
                  onChange({
                    applicant: { ...applicantConfig, isEnabled: event.target.checked },
                    signatory: signatoryConfig,
                  })
                }
              />
            </MuiFormGroup>
            {!!isForLiveBadgeApplication && (
              <MuiFormGroup row>
                <MuiFormControlLabel
                  control={<Switch color="primary" />}
                  label="Has already been sent"
                  checked={applicantConfig.isAlreadySent}
                  onChange={event =>
                    onChange({
                      applicant: { ...applicantConfig, isAlreadySent: event.target.checked },
                      signatory: signatoryConfig,
                    })
                  }
                />
              </MuiFormGroup>
            )}
          </div>
          <FormGroup>
            <Label>Subject</Label>
            <Input
              value={applicantConfig.subject}
              onChange={event =>
                onChange({
                  applicant: { ...applicantConfig, subject: event.target.value },
                  signatory: signatoryConfig,
                })
              }
            />
          </FormGroup>

          <FormGroup>
            <Label>Message</Label>
            <Input
              type="textarea"
              rows={8}
              value={applicantConfig.body}
              onChange={event =>
                onChange({
                  applicant: { ...applicantConfig, body: event.target.value },
                  signatory: signatoryConfig,
                })
              }
            />
          </FormGroup>
        </div>
      )}

      <div className="separator">SIGNATORY NOTIFICATION</div>
      {!signatoryConfig ? (
        <div style={{ width: '100%', textAlign: 'center', margin: '24px 0' }}>
          <i>Not available</i>
        </div>
      ) : (
        <div style={{ margin: '18px 0 0 0' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 12 }}>
            <MuiFormGroup row>
              <MuiFormControlLabel
                control={<Switch color="primary" />}
                label="Enabled"
                checked={signatoryConfig.isEnabled}
                onChange={event =>
                  onChange({
                    applicant: applicantConfig,
                    signatory: { ...signatoryConfig, isEnabled: event.target.checked },
                  })
                }
              />
            </MuiFormGroup>
            {!!isForLiveBadgeApplication && (
              <MuiFormGroup row>
                <MuiFormControlLabel
                  control={<Switch color="primary" />}
                  label="Has already been sent"
                  checked={signatoryConfig.isAlreadySent}
                  onChange={event =>
                    onChange({
                      applicant: applicantConfig,
                      signatory: { ...signatoryConfig, isAlreadySent: event.target.checked },
                    })
                  }
                />
              </MuiFormGroup>
            )}
          </div>
          <FormGroup>
            <Label>Subject</Label>
            <Input
              value={signatoryConfig.subject}
              onChange={event =>
                onChange({
                  applicant: applicantConfig,
                  signatory: { ...signatoryConfig, subject: event.target.value },
                })
              }
            />
          </FormGroup>

          <FormGroup>
            <Label>Message</Label>
            <Input
              type="textarea"
              rows={8}
              value={signatoryConfig.body}
              onChange={event =>
                onChange({
                  applicant: applicantConfig,
                  signatory: { ...signatoryConfig, body: event.target.value },
                })
              }
            />
          </FormGroup>
        </div>
      )}
    </TitledBox>
  );
}

export default function BadgeApplicationAuxNotificationsEditor({ isForLiveBadgeApplication, config, onChanged }) {
  const onConfigEntryChanged = key => updatedConfig => {
    config[key] = updatedConfig;
    onChanged({ updatedConfig: config });
  };

  return (
    <div>
      <Alert color="info">
        Allowed message placeholders: <i>[applicant_name], [airport_person_id], [signatory_name], [badge_type]</i>
      </Alert>

      <div>&nbsp;</div>

      {Object.entries(config).map(([key, value], index) => (
        <ConfigEntry
          key={index}
          label={key}
          config={value}
          onChange={onConfigEntryChanged(key)}
          isForLiveBadgeApplication={isForLiveBadgeApplication}
        />
      ))}
    </div>
  );
}
