import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Alert, Button } from 'reactstrap';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AppContext } from '../../../../../../../../App';
import { ActionPanelContext } from '../../../BadgeApplicationActionPanel';
import ValidatePersonDupeChecker from './ValidatePersonDupeChecker';
import {
  CancelFingerprintAppointmentPrompt,
  CheckedIcon,
  getFormattedDate,
  getStepIcon,
  ThirdPartyChrcDecisionModal,
} from '../validateUtils';
import { TabActions, ValidateContext } from '../ValidateBadgeApplicationAction';
import badgeApplicationCommentApi from '../../../../../../../../ajax/BadgeApplicationComment/badgeApplicationCommentApi';
import peopleApi from '../../../../../../../../ajax/People/peopleApi';
import { handleError, notify } from '../../../../../../../../utils';
import filesApi from '../../../../../../../../ajax/File/filesApi';
import FileViewModal from '../../../../../../../../shared/components/FileViewModal/FileViewModal';
import badgeOfficeApi from '../../../../../../../../ajax/BadgeOffice/badgeOfficeApi';
import PeopleEditTscExemptionModal from '../../../../../../People/PeopleList/components/PeopleEditTscExemptionModal';
import backgroundCheckApi from '../../../../../../../../ajax/BackgroundCheck/backgroundCheckApi';
import useConfirm from '../../../../../../../../shared/useConfirm';
import UploadChrcDocumentationModal from '../../../../../../People/PeopleList/UploadChrcDocumentationModal';

export default function ValidatePerson({ isOpen }) {
  const [isDuplicatesModalOpen, setIsDuplicatesModalOpen] = useState(false);
  const [isStaExempt, setIsStaExempt] = useState(false);
  const [chrcExemptionChoice, setChrcExemptionChoice] = useState('');
  const [isExemptModalOpen, setIsExemptModalOpen] = useState(false);
  const [isAbleToUncancelRapback, setIsAbleToUncancelRapback] = useState(false);
  const [isCancelFingerprintAppointmentPromptOpen, setIsCancelFingerprintAppointmentPromptOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isThirdPartyChrcDecisionModalOpen, setIsThirdPartyChrcDecisionModalOpen] = useState(false);
  const [isUploadChrcDocumentationModalOpen, setIsUploadChrcDocumentationModalOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [hasActiveRapback, setHasActiveRapback] = useState(false);

  const theme = useTheme();
  const showConfirm = useConfirm();
  const app = useContext(AppContext);
  const { badgeApplication, updateBadgeApplicationConfig } = useContext(ActionPanelContext);
  const {
    setShouldReloadBadgeApplication,
    gotoTab,
    closeModal,
    onStepMarkedComplete,
    isBackgroundCheckAlreadyStarted,
  } = useContext(ValidateContext);
  const { applicant, badgeType, config } = badgeApplication;
  const { validation, uploadChrcDocumentation } = config;
  const { personValidation } = validation.meta;
  const isChrcRequired = !!badgeType.shouldRequireChrc;

  const processAndUpdateBadgeApplicationConfig = async ({ updatedConfig }) => {
    const personValidation = updatedConfig.validation.meta.personValidation;
    personValidation.isComplete = true;
    if (personValidation.duplicateCheck.isEnabled && !personValidation.duplicateCheck.isComplete) {
      personValidation.isComplete = false;
    } else if (personValidation.staExemption.isEnabled && !personValidation.staExemption.isComplete) {
      personValidation.isComplete = false;
    } else if (personValidation.chrcExemption.isEnabled && !personValidation.chrcExemption.isComplete) {
      personValidation.isComplete = false;
    } else if (personValidation.rapbackUncancel.isEnabled && !personValidation.rapbackUncancel.isComplete) {
      personValidation.isComplete = false;
    }
    const isPersonValidationComplete = personValidation.isComplete;
    const isBadgeValidationComplete = updatedConfig.validation.meta.badgeValidation.isComplete;
    const isDocumentValidationComplete = updatedConfig.validation.meta.documentValidation.isComplete;
    if (isPersonValidationComplete && isBadgeValidationComplete && isDocumentValidationComplete) {
      updatedConfig.validation.isComplete = true;
      // Should fingerprints be enabled?
      const isNotChrcExempt = personValidation.chrcExemption.choice === 'false';
      if (isNotChrcExempt && badgeApplication.config.fingerprintAppointment.isEnabled === false) {
        badgeApplication.config.fingerprintAppointment.isEnabled = true;
      }
    }
    return updateBadgeApplicationConfig({ updatedConfig });
  };

  const reset = async ({ isFullReset = false }) => {
    const { duplicateCheck, staExemption, chrcExemption, rapbackUncancel } = personValidation;

    if (isFullReset) {
      duplicateCheck.isEnabled = true;
      duplicateCheck.isComplete = false;
      duplicateCheck.lastChecked = null;
    }

    staExemption.isEnabled = !isFullReset;
    staExemption.isComplete = false;
    staExemption.isExempt = null;

    chrcExemption.isEnabled = false;
    chrcExemption.isComplete = false;
    chrcExemption.choice = null;

    rapbackUncancel.isEnabled = false;
    rapbackUncancel.isComplete = false;
    rapbackUncancel.lastChecked = null;

    setIsStaExempt(null);
    setChrcExemptionChoice('');
    setIsAbleToUncancelRapback(false);

    personValidation.isComplete = false;
    setIsComplete(false);
    setHasActiveRapback(false);
    promptForCancelFingerprintsAppointment();

    setShouldReloadBadgeApplication(true);
    await processAndUpdateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
    onStepMarkedComplete(badgeApplication.config);
  };

  const onMarkComplete = () => {
    if (isComplete) {
      // This step was previously marked complete - just go to the next tab
      return gotoTab(1);
    }

    personValidation.isComplete = true;
    processAndUpdateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
    setIsComplete(true);
    onStepMarkedComplete(badgeApplication.config);
    badgeApplicationCommentApi.create({
      badgeApplicationUuid: badgeApplication.uuid,
      comment: 'Person/Vetting validation complete',
    });
    gotoTab(1);
  };

  const onCheckRapbackUncancelClicked = decision => {
    if (decision === 'skip') {
      setIsAbleToUncancelRapback(false);
      personValidation.rapbackUncancel.isComplete = true;
      processAndUpdateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
      setShouldReloadBadgeApplication(true);
      return;
    }

    app.api.toggleLoading(true);
    backgroundCheckApi
      .changeRapbackSubscription({ userUuid: applicant.uuid, action: 'uncancel' })
      .then(({ success, errors, message }) => {
        if (success) {
          notify({ message: 'Rapback uncancel request sent to TSC' });
        } else if (errors) {
          showConfirm({
            title: 'Response from TSC',
            content: <Alert color="danger">{errors}</Alert>,
            onConfirm: () => null,
            onClose: false,
          });
        } else if (message) {
          showConfirm({
            title: 'Conflict Found',
            content: <p>{message}</p>,
            onConfirm: () => null,
            onClose: false,
          });
        }
        setIsAbleToUncancelRapback(false);
        personValidation.rapbackUncancel.isComplete = true;
        processAndUpdateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
        setShouldReloadBadgeApplication(true);
        badgeApplicationCommentApi.create({
          badgeApplicationUuid: badgeApplication.uuid,
          comment: 'Rapback un-cancel request sent to TSC',
        });
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onTscExemptionSave = () => {
    setIsExemptModalOpen(false);
    applicant.isTscExempt = false;
    reset({ isFullReset: false });
  };

  const onUploadChrcDocumentationModalClose = ({ wasChrcDocumentationUploaded }) => {
    setIsUploadChrcDocumentationModalOpen(false);
    const { chrcExemption, rapbackUncancel } = personValidation;
    if (!wasChrcDocumentationUploaded) {
      setChrcExemptionChoice('');
      chrcExemption.choice = null;
    } else {
      setChrcExemptionChoice('3rdPartyCHRC');
      chrcExemption.choice = '3rdPartyCHRC';
      chrcExemption.isComplete = true;
      rapbackUncancel.isComplete = true;
      // Fingerprints and CHRC documentation are no longer needed
      badgeApplication.config.fingerprintAppointment.isEnabled = false;
      promptForCancelFingerprintsAppointment();
      if (badgeApplication.config.uploadChrcDocumentation) {
        badgeApplication.config.uploadChrcDocumentation.isComplete = true;
      }
      setShouldReloadBadgeApplication(true);
    }
    processAndUpdateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
  };

  const onThirdPartyChrcDecisionModalClose = ({ choice, subject, message }) => {
    setIsThirdPartyChrcDecisionModalOpen(false);
    if (choice === 'provide') {
      setIsUploadChrcDocumentationModalOpen(true);
    } else if (choice === 'request') {
      setChrcExemptionChoice('3rdPartyCHRC');
      personValidation.chrcExemption.choice = '3rdPartyCHRC';
      personValidation.chrcExemption.isComplete = true;
      badgeApplication.config.fingerprintAppointment.isEnabled = false;
      promptForCancelFingerprintsAppointment();
      badgeApplication.config.uploadChrcDocumentation = {
        label: 'Provide CHRC Documentation',
        order: 1,
        isEnabled: true,
        isComplete: false,
        milestone: 'prepare',
      };
      setShouldReloadBadgeApplication(true);
      processAndUpdateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
      const { signatory } = badgeApplication;
      const comment = `Request for CHRC Documentation sent to ${signatory.fullName}`;
      badgeApplicationCommentApi.create({ badgeApplicationUuid: badgeApplication.uuid, comment });
      badgeOfficeApi
        .sendMessage({
          userUuid: signatory.uuid,
          subject,
          message,
        })
        .then(() => notify({ message: comment }))
        .catch(error => handleError({ error }));
    }
  };

  const onChrcExemptionChange = event => {
    const { tscData } = applicant;
    const { chrcExemption, rapbackUncancel } = personValidation;
    const choice = event.target.value;
    if (choice === '3rdPartyCHRC') {
      setIsThirdPartyChrcDecisionModalOpen(true);
    } else {
      chrcExemption.choice = choice;
      setChrcExemptionChoice(choice);
      chrcExemption.isComplete = true;
      if (chrcExemption.choice === 'true') {
        // Fingerprints and CHRC documentation are no longer needed
        badgeApplication.config.fingerprintAppointment.isEnabled = false;
        promptForCancelFingerprintsAppointment();
        if (badgeApplication.config.uploadChrcDocumentation) {
          badgeApplication.config.uploadChrcDocumentation.isEnabled = false;
        }
      } else {
        const rapbackStatus = (tscData.rapbackStatus || '').toLocaleLowerCase();
        if (
          !tscData.rapbackCaseNumber ||
          rapbackStatus === 'subscribed' ||
          rapbackStatus === 'subscription in progress'
        ) {
          setIsAbleToUncancelRapback(false);
        } else {
          rapbackUncancel.isEnabled = true;
          rapbackUncancel.isComplete = false;
          setIsAbleToUncancelRapback(true);
        }
        rapbackUncancel.lastChecked = moment.tz(tscData.lastUpdated, app.timezone);
        badgeApplicationCommentApi.create({
          badgeApplicationUuid: badgeApplication.uuid,
          comment: 'Determined that fingerprints are needed',
        });
        if (
          !badgeApplication?.settings?.shouldAddFingerprintsAsap &&
          !badgeApplication.config.fingerprintAppointment.isEnabled
        ) {
          showConfirm({
            title: 'Fingerprints Needed',
            content: (
              <div>
                Would you like to send the applicant a request to schedule their fingerprint appointment now?
                {/* Yes is the default */}
              </div>
            ),
            onConfirm: () => {
              badgeApplication.config.fingerprintAppointment.isEnabled = true;
              processAndUpdateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
            },
            okButtonText: 'Yes',
            cancelButtonText: <span style={{ width: 70, display: 'inline-block' }}>No</span>,
          });
        }
      }
      setShouldReloadBadgeApplication(true);
      processAndUpdateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
    }
  };

  const onCloseFileModal = () => {
    setSelectedFile(null);
    setIsViewModalOpen(false);
  };

  const onViewChrcDocumentClicked = () => {
    app.api.toggleLoading(true);
    filesApi
      .listForBadgeApplication({ user: app.user, badgeApplicationUuid: badgeApplication.uuid })
      .then(({ files }) => {
        if (files.length) {
          const doc = files.find(f => f.fileTypeName === 'CHRC Documentation');
          setSelectedFile(doc);
          setIsViewModalOpen(true);
        } else {
          notify({ message: 'Unable to locate the CHRC document' });
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const promptForCancelFingerprintsAppointment = () => {
    badgeApplicationCommentApi.create({
      badgeApplicationUuid: badgeApplication.uuid,
      comment: 'Determined that fingerprints are not needed',
    });
    if (badgeApplication.config.fingerprintAppointment.appointmentUuid) {
      setIsCancelFingerprintAppointmentPromptOpen(true);
    }
  };

  const markAccountAsStaExempt = () => {
    peopleApi
      .changeTscExemption({ userUuid: applicant.uuid, isTscExempt: true })
      .catch(error => handleError({ error, message: 'Unable to change exemption status' }));
  };

  const onStaExemptionChange = event => {
    const selectedChoice = event.target.value;
    const { staExemption, chrcExemption, rapbackUncancel } = personValidation;

    // Reset the CHRC exemption because the STA exemption was changed
    chrcExemption.isComplete = false;
    chrcExemption.choice = null;
    rapbackUncancel.isComplete = false;
    setChrcExemptionChoice(null);
    badgeApplication.config.validation.meta.personValidation.isComplete = false;
    badgeApplication.config.validation.isComplete = false;

    staExemption.isComplete = true;
    if (selectedChoice === 'true') {
      markAccountAsStaExempt();
      badgeApplication.applicant.isTscExempt = true;
      staExemption.isExempt = true;
      setIsStaExempt(true);
      if (isChrcRequired) {
        chrcExemption.isEnabled = true;
        chrcExemption.isComplete = true;
        rapbackUncancel.isComplete = true;
        chrcExemption.choice = 'true';
        setChrcExemptionChoice('true');
        // Fingerprints are no longer needed
        badgeApplication.config.fingerprintAppointment.isEnabled = false;
        promptForCancelFingerprintsAppointment();
        badgeApplicationCommentApi.create({
          badgeApplicationUuid: badgeApplication.uuid,
          comment: 'STA & CHRC exemption added',
        });
      } else {
        badgeApplicationCommentApi.create({
          badgeApplicationUuid: badgeApplication.uuid,
          comment: 'STA exemption added',
        });
      }
    } else {
      setIsStaExempt(false);
      staExemption.isExempt = false;
      const rapbackStatus = (applicant.tscData.rapbackStatus || '').toLocaleLowerCase();
      if (rapbackStatus === 'subscribed' || rapbackStatus === 'subscription in progress') {
        chrcExemption.isEnabled = false;
        setHasActiveRapback(true);
      } else {
        setHasActiveRapback(false);
        chrcExemption.isEnabled = isChrcRequired;
        if (badgeApplication['3rdPartyChrc'].caseNumber) {
          chrcExemption.isComplete = true;
          chrcExemption.choice = '3rdPartyCHRC';
          setChrcExemptionChoice('3rdPartyCHRC');
          rapbackUncancel.isComplete = true;
          // Fingerprints are no longer needed
          badgeApplication.config.fingerprintAppointment.isEnabled = false;
          promptForCancelFingerprintsAppointment();
          if (badgeApplication.config.uploadChrcDocumentation) {
            badgeApplication.config.uploadChrcDocumentation.isEnabled = false;
          }
        }
      }
    }
    processAndUpdateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
    setShouldReloadBadgeApplication(true);
  };

  const onCheckDuplicatesClicked = () => {
    setIsDuplicatesModalOpen(true);
  };

  const onValidatePersonDupeCheckerClose = ({ wasMerged, notDuplicate }) => {
    setIsDuplicatesModalOpen(false);
    const { duplicateCheck, staExemption, chrcExemption } = personValidation;
    duplicateCheck.lastChecked = moment.tz(app.timezone).toISOString();
    if (notDuplicate) {
      badgeApplicationCommentApi.create({
        badgeApplicationUuid: badgeApplication.uuid,
        comment: 'No duplicates found',
      });
      duplicateCheck.isComplete = true;
      staExemption.isEnabled = true;
      if (applicant.isTscExempt) {
        staExemption.isExempt = true;
        staExemption.isComplete = true;
        setIsStaExempt(true);
        if (isChrcRequired) {
          chrcExemption.isEnabled = true;
          chrcExemption.choice = 'true';
          chrcExemption.isComplete = true;
          setChrcExemptionChoice('true');
        }
      }
    } else {
      badgeApplicationCommentApi.create({
        badgeApplicationUuid: badgeApplication.uuid,
        comment: 'Duplicate user record found and merged',
      });
    }
    setShouldReloadBadgeApplication(notDuplicate || wasMerged);
    processAndUpdateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
  };

  const isMarkCompleteDisabled = () => {
    if (isComplete) {
      return false;
    }

    const { duplicateCheck, rapbackUncancel, staExemption, chrcExemption } = personValidation;
    return (
      !duplicateCheck.isComplete ||
      !staExemption.isComplete ||
      (chrcExemption.isEnabled && !chrcExemption.isComplete) ||
      (rapbackUncancel.isEnabled && !rapbackUncancel.isComplete)
    );
  };

  const resetVettingDecision = () => {
    const { staExemption, chrcExemption, rapbackUncancel } = personValidation;

    setIsComplete(false);

    staExemption.isComplete = false;
    staExemption.isExempt = null;
    setIsStaExempt(null);

    chrcExemption.isEnabled = false;
    chrcExemption.isComplete = false;
    chrcExemption.choice = null;
    setChrcExemptionChoice(null);

    rapbackUncancel.isEnabled = false;
    rapbackUncancel.isComplete = false;

    badgeApplication.config.validation.meta.personValidation.isComplete = false;
    badgeApplication.config.validation.isComplete = false;

    badgeApplicationCommentApi.create({
      badgeApplicationUuid: badgeApplication.uuid,
      comment: 'Reset vetting options',
    });

    processAndUpdateBadgeApplicationConfig({ updatedConfig: badgeApplication.config });
    setShouldReloadBadgeApplication(true);
  };

  useEffect(() => {
    if (isOpen) {
      const { duplicateCheck, rapbackUncancel, staExemption, chrcExemption } = personValidation;
      setIsStaExempt(staExemption.isExempt);
      setChrcExemptionChoice(chrcExemption.choice || '');
      setIsAbleToUncancelRapback(duplicateCheck.isComplete && rapbackUncancel.isEnabled && !rapbackUncancel.isComplete);
      setSelectedFile(null);
      setIsComplete(personValidation.isComplete);
      setShouldReloadBadgeApplication(false);
      const rapbackStatus = (applicant.tscData.rapbackStatus || '').toLocaleLowerCase();
      setHasActiveRapback(rapbackStatus === 'subscribed' || rapbackStatus === 'subscription in progress');
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 8,
          right: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          fontSize: 12,
          fontStyle: 'italic',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 24 }}>
          <i className="fa-regular fa-circle mr-1" style={{ fontSize: 14 }}></i> = incomplete
        </div>
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 8 }}>
          <i className="fa-solid fa-circle-check text-success mr-1" style={{ fontSize: 14 }}></i> = complete
        </div>
      </div>

      <Grid container>
        {/* ---------------------- */}
        {/* Duplicate Person Check */}
        {/* ---------------------- */}
        <Grid container>
          <Grid container direction="row">
            <Grid item xs={1} style={{ maxWidth: 50 }}>
              {getStepIcon(personValidation.duplicateCheck)}
            </Grid>
            <Grid item xs={11}>
              <h5>Duplicate Person Check</h5>
            </Grid>
          </Grid>
          {!personValidation.isComplete && (
            <Grid container direction="row" style={{ marginBottom: theme.spacing(2) }}>
              <Grid item xs={1} style={{ maxWidth: 50 }}></Grid>
              <Grid item xs={11}>
                An applicant must be unique. Check if this applicant is duplicated. This step will need to be re-done
                until there are no more duplicates.
              </Grid>
            </Grid>
          )}
          <Grid container direction="row">
            <Grid item xs={1} style={{ maxWidth: 50 }}></Grid>
            <Grid item xs={11} container direction="row" alignItems="center">
              {!personValidation.duplicateCheck.isComplete && (
                <Button
                  style={{ width: 135, marginRight: 32 }}
                  color="primary"
                  size="sm"
                  onClick={onCheckDuplicatesClicked}
                  disabled={!!personValidation.duplicateCheck.isComplete}
                >
                  Check Now
                </Button>
              )}
              <div style={{ fontStyle: 'italic', textAlign: 'right' }}>
                <span style={{ marginRight: theme.spacing(1) }}>Last Checked:</span>
                {getFormattedDate({
                  date: personValidation.duplicateCheck.lastChecked,
                  timezone: app.timezone,
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ marginBottom: theme.spacing(4) }}>&nbsp;</div>

      {/* ------------- */}
      {/* STA Exemption */}
      {/* ------------- */}
      <Grid container style={{ display: personValidation.staExemption.isEnabled ? 'initial' : 'none' }}>
        <Grid container direction="row">
          <Grid item xs={1} style={{ maxWidth: 50 }}>
            {getStepIcon(personValidation.staExemption)}
          </Grid>
          <Grid item xs={11}>
            <h5>STA Exemption</h5>
          </Grid>
        </Grid>

        {applicant.isTscExempt ? (
          <Grid container direction="row" style={{ marginBottom: 8 }}>
            <Grid item xs={1} style={{ maxWidth: 50 }}></Grid>
            <Grid item xs={11}>
              <Alert color="warning">
                This applicant is exempt from the STA requirement.
                <div className="mt-2">
                  <Button
                    color="warning"
                    size="sm"
                    onClick={() => setIsExemptModalOpen(true)}
                    disabled={!personValidation.staExemption.isEnabled || isBackgroundCheckAlreadyStarted}
                  >
                    Remove STA/CHRC Exemption
                  </Button>
                </div>
              </Alert>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container direction="row" style={{ marginBottom: 8 }}>
              <Grid item xs={1} style={{ maxWidth: 50 }}></Grid>
              <Grid item xs={11}>
                Is this applicant exempt from the STA requirement? This usually applies to law enforcement officers or
                TSA agents.
                {isChrcRequired ? (
                  <>
                    <br />
                    <small>
                      <i>Choosing "Yes" will also exempt this person from the CHRC requirement.</i>
                    </small>
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={1} style={{ maxWidth: 50 }}></Grid>
              <Grid item xs={11}>
                <RadioGroup row value={`${isStaExempt}`} onChange={onStaExemptionChange}>
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Yes"
                    disabled={personValidation.staExemption.isComplete || !personValidation.staExemption.isEnabled}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="No"
                    disabled={personValidation.staExemption.isComplete || !personValidation.staExemption.isEnabled}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <div style={{ marginBottom: theme.spacing(1) }}>&nbsp;</div>

      {/* -------------- */}
      {/* CHRC Exemption */}
      {/* -------------- */}
      {hasActiveRapback &&
        personValidation.staExemption.isComplete &&
        !personValidation.chrcExemption.isEnabled &&
        isChrcRequired && (
          <Grid container>
            <Grid container direction="row">
              <Grid item xs={1} style={{ maxWidth: 50 }}>
                <CheckedIcon />
              </Grid>
              <Grid item xs={11}>
                <h5>CHRC Exemption / 3rd Party CHRC</h5>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={1} style={{ maxWidth: 50 }}></Grid>
              <Grid item xs={11}>
                <Alert color="info">
                  This applicant has an active rapback subscription and does not require an update to their CHRC
                </Alert>
              </Grid>
            </Grid>
          </Grid>
        )}

      <Grid container style={{ display: personValidation.chrcExemption.isEnabled ? 'initial' : 'none' }}>
        <Grid container direction="row">
          <Grid item xs={1} style={{ maxWidth: 50 }}>
            {getStepIcon(personValidation.chrcExemption)}
          </Grid>
          <Grid item xs={11}>
            <h5>CHRC Exemption / 3rd Party CHRC</h5>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ marginBottom: 8 }}>
          <Grid item xs={1} style={{ maxWidth: 50 }}></Grid>
          {applicant.isTscExempt && personValidation.chrcExemption.isComplete ? (
            <Grid item xs={11}>
              <Alert color="warning">
                Because the applicant is exempt from the STA requirement, they are also exempt from the CHRC
                requirement.
              </Alert>
            </Grid>
          ) : (
            <Grid item xs={11}>
              Is this applicant exempt from the CHRC requirement or are they providing documentation for a 3rd party
              CHRC?
            </Grid>
          )}
        </Grid>
        {applicant.isTscExempt && personValidation.chrcExemption.isComplete ? null : (
          <>
            <Grid container direction="row">
              <Grid item xs={1} style={{ maxWidth: 50 }}></Grid>
              <Grid item xs={11}>
                <RadioGroup row value={chrcExemptionChoice} onChange={onChrcExemptionChange}>
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Exempt"
                    disabled={personValidation.chrcExemption.isComplete || !personValidation.chrcExemption.isEnabled}
                  />
                  <FormControlLabel
                    value="3rdPartyCHRC"
                    control={<Radio color="primary" />}
                    label="3rd Party CHRC"
                    disabled={personValidation.chrcExemption.isComplete || !personValidation.chrcExemption.isEnabled}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        Not Exempt
                        <small>
                          <i> (fingerprints will be required)</i>
                        </small>
                      </div>
                    }
                    disabled={personValidation.chrcExemption.isComplete || !personValidation.chrcExemption.isEnabled}
                  />
                </RadioGroup>
                {!personValidation.chrcExemption.choice && uploadChrcDocumentation?.isEnabled && (
                  <Alert color="info" style={{ fontStyle: 'italic', fontSize: 12 }}>
                    The Signatory has indicated that a 3rd party CHRC will be provided.
                  </Alert>
                )}
              </Grid>
            </Grid>

            {!badgeApplication['3rdPartyChrc'].caseNumber || personValidation.chrcExemption.choice === 'true' ? null : (
              <Grid container direction="row">
                <Grid item xs={1} style={{ maxWidth: 50 }}></Grid>
                <Grid item xs={11}>
                  <div style={{ fontStyle: 'italic' }}>
                    3rd Party CHRC Case Number: <strong>{badgeApplication['3rdPartyChrc'].caseNumber}</strong>
                  </div>
                  <button className="btn btn-link p-0 m-0 mt-2" onClick={onViewChrcDocumentClicked}>
                    <i className="fa-solid fa-eye mr-1 text-primary"></i>View Document
                  </button>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>

      <div style={{ marginBottom: theme.spacing(2) }}>&nbsp;</div>

      {/* ----------------- */}
      {/* Rapback Un-cancel */}
      {/* ----------------- */}
      <Grid
        container
        style={{
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(5),
          display: personValidation.rapbackUncancel.isEnabled ? 'initial' : 'none',
        }}
      >
        <Grid container direction="row">
          <Grid item xs={1} style={{ maxWidth: 50 }}>
            {getStepIcon(personValidation.rapbackUncancel)}
          </Grid>
          <Grid item xs={11}>
            <h5>Rapback Subscription Un-cancel</h5>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ marginBottom: theme.spacing(1) }}>
          <Grid item xs={1} style={{ maxWidth: 50 }}></Grid>
          <Grid item xs={11}>
            {!personValidation.isComplete && (
              <div style={{ marginBottom: 16 }}>
                An applicant may be eligible to reinstate their Rapback subscription if it was active in the last 30
                days.
              </div>
            )}
            <div style={{ fontStyle: 'italic' }}>
              <span style={{ marginRight: theme.spacing(1) }}>Last Checked:</span>
              {getFormattedDate({
                date: personValidation.rapbackUncancel.lastChecked,
                timezone: app.timezone,
              })}
            </div>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={1} style={{ maxWidth: 50 }}></Grid>
          <Grid item xs={11} container direction="row">
            <div className="mt-1">
              <Button
                className="mr-5"
                style={{ width: 135 }}
                color="warning"
                size="sm"
                onClick={() => onCheckRapbackUncancelClicked('un-cancel')}
                disabled={!isAbleToUncancelRapback}
                hidden={!!personValidation.rapbackUncancel.isComplete}
              >
                Un-cancel Rapback
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => onCheckRapbackUncancelClicked('skip')}
                disabled={!isAbleToUncancelRapback}
                hidden={!!personValidation.rapbackUncancel.isComplete}
              >
                Skip This Step
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <TabActions>
        <div>
          {personValidation.staExemption.isEnabled && isStaExempt === false && !isBackgroundCheckAlreadyStarted && (
            <Button color="warning" onClick={resetVettingDecision}>
              Reset Vetting Options
            </Button>
          )}
        </div>
        <div>
          <Button onClick={() => closeModal()} className="mr-2">
            Close
          </Button>
          <Button
            color="primary"
            onClick={onMarkComplete}
            disabled={isMarkCompleteDisabled()}
            style={{ marginLeft: 15, minWidth: 100 }}
          >
            Validate Badge/Training
            <i className="fa-solid fa-chevron-right ml-2"></i>
          </Button>
        </div>
      </TabActions>

      <ValidatePersonDupeChecker
        isOpen={isDuplicatesModalOpen}
        badgeApplication={badgeApplication}
        onClose={onValidatePersonDupeCheckerClose}
      />

      <CancelFingerprintAppointmentPrompt
        isOpen={isCancelFingerprintAppointmentPromptOpen}
        onClose={() => setIsCancelFingerprintAppointmentPromptOpen(false)}
        badgeApplication={badgeApplication}
      />

      <FileViewModal file={selectedFile} isOpen={isViewModalOpen} onClose={onCloseFileModal} />

      <ThirdPartyChrcDecisionModal
        isOpen={isThirdPartyChrcDecisionModalOpen}
        onClose={onThirdPartyChrcDecisionModalClose}
        badgeApplication={badgeApplication}
      />

      <UploadChrcDocumentationModal
        isOpen={isUploadChrcDocumentationModalOpen}
        badgeApplication={badgeApplication}
        onDone={onUploadChrcDocumentationModalClose}
      />

      <PeopleEditTscExemptionModal
        isOpen={isExemptModalOpen}
        onClose={() => setIsExemptModalOpen(false)}
        onSave={onTscExemptionSave}
        userUuid={applicant.uuid}
        fullName={applicant.fullName}
        isCurrentlyExempt={applicant.isTscExempt}
      />
    </>
  );
}
