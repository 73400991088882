import React, { useContext, useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';
import { Alert, Button, Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import moment from 'moment';
import 'moment-timezone';
import { AppContext } from '../../../../../App';
import badgeApi from '../../../../../ajax/Badge/badgeApi';
import BadgeDetailsModal from '../../../Badges/BadgesList/BadgeDetailsModal';
import { buildFullName, handleError } from '../../../../../utils';
import { hasFeature } from '../../../../../shared/Feature';
import BadgeCreateModal from '../../../Badges/BadgesList/BadgeCreateModal';
import badgeTypeApi from '../../../../../ajax/BadgeType/badgeTypeApi';
import ReplacePacsBadgeModal from '../../../Badges/BadgesList/ReplacePacsBadgeModal';
import uniqBy from 'lodash/uniqBy';
import BadgeTypeSelectionModal from './BadgeTypeSelectionModal';
import PrintBadgeModal from '../../../Badges/BadgesList/PrintBadgeModal';
import BadgeCreateRequest from '../../../../../ajax/Badge/BadgeCreateRequest';

function CellWithTitle({ value }) {
  return <span title={value}>{value}</span>;
}

const defaultSort = [{ id: 'issue_date', desc: false }];
const defaultColumns = [
  {
    id: 'authorizedSignatoryCompany',
    Header: 'Company',
    accessor: ({ authorizedSignatoryCompany }) => <CellWithTitle value={authorizedSignatoryCompany} />,
    sortable: false,
    width: 150,
  },
  {
    id: 'id',
    Header: 'ID',
    accessor: 'number',
    sortable: false,
  },
  {
    id: 'expiration_date',
    Header: 'Expires',
    accessor: 'expires',
    sortable: false,
    width: 120,
  },
  {
    id: 'type',
    Header: 'Type',
    accessor: row => <CellWithTitle value={row.type} />,
    sortable: false,
    width: 150,
  },
];

let requestTimeout = null;
let apiAbortController = null;

export default function PersonEditBadges({ selectedPerson, isOpen }) {
  const app = useContext(AppContext);
  const hasPrintingFeature = hasFeature('printing', app.airport?.config?.features || []);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [inactiveBadges, setInactiveBadges] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isBadgeModalOpen, setIsBadgeModalOpen] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [isIssueBadgeModalOpen, setIsIssueBadgeModalOpen] = useState(false);
  const [badgeData, setBadgeData] = useState(null);
  const [badgeTypes, setBadgeTypes] = useState([]);
  const [isPrintBadgeModalOpen, setIsPrintBadgeModalOpen] = useState(false);
  const [shouldReturnBadgeCreateModal, setShouldReturnBadgeCreateModal] = useState(false);
  const [shouldReturnPacsModal, setShouldReturnPacsModal] = useState(false);
  const [shouldReturnPrintingModal, setShouldReturnPrintingModal] = useState(false);
  const [isBadgeTypeSelectionModalOpen, setIsBadgeTypeSelectionModalOpen] = useState(false);
  const [selectedBadgeType, setSelectedBadgeType] = useState(null);
  const [selectedSignatory, setSelectedSignatory] = useState(null);
  const [defaultBadgeExpiration, setDefaultBadgeExpiration] = useState(null);
  const [defaultEndorsements, setDefaultEndorsements] = useState({ driving: 'none', isEscort: false });

  const loadData = () => {
    // Just in case it is still spinning
    app.api.toggleLoading(false);

    if (requestTimeout) {
      if (apiAbortController) apiAbortController.abort();
      clearTimeout(requestTimeout);
    }

    setIsLoading(true);

    requestTimeout = setTimeout(() => {
      setIsLoading(true);
      apiAbortController = new AbortController();
      const defaults = {
        pageSize: 10,
        page: 0,
        sorts: defaultSort,
        filters: {
          0: { id: 'status', value: 'in_use' },
          filterByUser: selectedPerson.uuid,
          filterByAuthorizedSignatory: null,
        },
        signal: apiAbortController.signal,
      };
      const activeBadgesParams = { ...defaults };
      activeBadgesParams.filters[0].value = 'in_use';
      const activeBadgesRequest = badgeApi.list(activeBadgesParams);
      const retainedBadgesParams = { ...defaults };
      retainedBadgesParams.filters[0].value = 'retained';
      const retainedBadgesRequest = badgeApi.list(retainedBadgesParams);
      const inactiveBadgesParams = { ...defaults };
      activeBadgesParams.filters[0].value = 'inactive';
      const inactiveBadgesRequest = badgeApi.list(inactiveBadgesParams);
      Promise.all([activeBadgesRequest, retainedBadgesRequest, inactiveBadgesRequest])
        .then(([{ data: dataSet1 }, { data: dataSet2 }, { data: dataSet3 }]) => {
          let rows = [];
          if (dataSet1) rows = rows.concat(dataSet1);
          if (dataSet2) rows = rows.concat(dataSet2);
          if (dataSet3) rows = rows.concat(dataSet3);
          const grouped = groupBy(rows, row => (row.active === 1 ? 'current' : 'old'));
          setData(uniqBy(grouped['current'], 'uuid'));
          setInactiveBadges(uniqBy(grouped['old'], 'uuid'));
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoading(false));
    }, 500);
  };

  const onBadgeModalClose = () => {
    setIsBadgeModalOpen(false);
    setSelectedBadge(null);
    loadData();
  };

  const onBadgeClicked = badge => () => {
    setSelectedBadge(badge);
    setIsBadgeModalOpen(true);
  };

  const onIssueBadgeModalClose = shouldReload => {
    setIsIssueBadgeModalOpen(false);
    if (shouldReload) loadData();
  };

  const onIssueBadgeClicked = () => {
    setSelectedBadgeType(null);
    setSelectedSignatory(null);
    setDefaultBadgeExpiration(null);

    if (!badgeTypes?.length) {
      app.api.toggleLoading(true);
      badgeTypeApi
        .activeBadgeTypes()
        .then(({ data }) => {
          setBadgeTypes(data);
          showIssueBadgeWarning();
        })
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    } else {
      showIssueBadgeWarning();
    }
  };

  const showIssueBadgeWarning = () => {
    if (!selectedPerson.pacs_person_id && app?.airport?.isPacsEnabled) {
      app.api.confirmMessage(
        'Missing PACS Identifier',
        <Alert color="danger">
          This person has not been associated with PACS. This usually indicates they have never completed a Badge
          Application. Please start with a new Badge Application.
        </Alert>,
        false,
        false
      );
    } else {
      app.api.confirmMessage(
        'Are you sure?',
        <Alert color="warning">
          Issuing a badge directly from this screen will bypass the standard compliance checks. In almost all cases, you
          should initiate a Badge Application.
        </Alert>,
        () => {
          openCorrectModal();
        },
        null,
        false,
        'Continue'
      );
    }
  };

  const openCorrectModal = () => {
    if (shouldReturnPrintingModal) {
      setIsBadgeTypeSelectionModalOpen(true);
    } else {
      setIsIssueBadgeModalOpen(true);
    }
  };

  const createMisprintedBadge = ({ number, cardNumber, expiration, isEscort, driving, template }) => {
    const now = moment.tz(app.timezone).format('YYYY-MM-DD HH:mm:ss');
    const badge = {
      badge_holder_uuid: selectedPerson.uuid,
      authorized_signatory_profile_uuid: selectedSignatory.uuid,
      type: selectedBadgeType.badgeTypeID,
      status: 'misprint',
      group: null,
      number: number || '???',
      card_serial_number: cardNumber || '???',
      effective: now,
      expires: expiration,
      driving: driving || 'none',
      escort: isEscort ? 1 : 0,
      leo: 0,
      parking: 0,
      collected: now,
      template: template || null,
    };
    app.api.toggleLoading(true);
    BadgeCreateRequest('authenticated-user', badge)
      .then((success, message) => {
        if (success === false) handleError({ message: message });
      })
      .catch(error => handleError({ error, message: 'Badge could not be created' }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onPrintBadgeModalClose = data => {
    if (data) {
      if (data.isMisprint) {
        createMisprintedBadge(data);
      } else {
        const { number, cardNumber, expiration, isEscort, driving, template } = data;

        const badge = {
          status: null,
          authorizedSignatoryProfileUuid: selectedSignatory?.uuid || null,
          type: selectedBadgeType?.displayName || null,
          badgeNumber: number || '',
          cardNumber: cardNumber || '',
          expirationDate: expiration ? `${expiration} 00:00:00` : null,
          driving: driving || null,
          escort: !!isEscort,
          leo: false,
          template: template || null,
        };

        setBadgeData({ ...badgeData, badge });
        setIsPrintBadgeModalOpen(false);
        setIsIssueBadgeModalOpen(true);
      }
    } else {
      setIsPrintBadgeModalOpen(false);
    }
  };

  const onBadgeTypeSelectionModalClose = ({ badgeType, signatory, expiration, endorsements }) => {
    setIsBadgeTypeSelectionModalOpen(false);
    setSelectedBadgeType(badgeType);
    setSelectedSignatory(signatory);
    setDefaultBadgeExpiration(expiration);
    setDefaultEndorsements(endorsements);
    setIsPrintBadgeModalOpen(true);
  };

  const initializeModals = () => {
    const isPacsEnabled = !!app?.airport?.isPacsEnabled;

    let shouldReturnBadgeCreateModal = false;
    let shouldReturnPacsModal = false;
    let shouldReturnPrintingModal = false;

    if (!isPacsEnabled && !hasPrintingFeature) {
      shouldReturnBadgeCreateModal = true;
      shouldReturnPacsModal = false;
      shouldReturnPrintingModal = false;
    } else if (!isPacsEnabled && hasPrintingFeature) {
      shouldReturnBadgeCreateModal = true;
      shouldReturnPacsModal = false;
      shouldReturnPrintingModal = true;
    } else if (isPacsEnabled && !hasPrintingFeature) {
      shouldReturnBadgeCreateModal = false;
      shouldReturnPacsModal = true;
      shouldReturnPrintingModal = false;
    } else if (isPacsEnabled && hasPrintingFeature) {
      let features = app?.airport?.pacsConfig?.features || [];
      const isPacsActivationEnabled = features.includes('allow_activation_from_airbadge');
      shouldReturnBadgeCreateModal = isPacsActivationEnabled;
      shouldReturnPacsModal = !isPacsActivationEnabled;
      shouldReturnPrintingModal = true;
    }

    setShouldReturnBadgeCreateModal(shouldReturnBadgeCreateModal);
    setShouldReturnPacsModal(shouldReturnPacsModal);
    setShouldReturnPrintingModal(shouldReturnPrintingModal);
  };

  useEffect(() => {
    if (!selectedPerson || !isOpen) return;

    initializeModals();
    setIsLoading(false);
    setData([]);
    setInactiveBadges([]);
    setIsBadgeModalOpen(false);
    setSelectedBadge(null);
    setBadgeData({
      person: {
        uuid: selectedPerson.uuid,
        firstName: selectedPerson.first_name,
        lastName: selectedPerson.last_name,
        name: `${selectedPerson.first_name} ${selectedPerson.last_name}`,
        full_name: `${selectedPerson.first_name} ${selectedPerson.last_name}`,
        pacsPersonId: selectedPerson.pacs_person_id,
        airportPersonId: selectedPerson.tsc_person_id,
        tscPersonId: selectedPerson.tsc_person_id,
        original: {
          airportPersonId: selectedPerson.tsc_person_id,
          dob: selectedPerson.date_of_birth,
        },
      },
      badge: {
        badgeNumber: '',
        cardNumber: '',
        expirationDate: null,
        type: null,
        status: null,
        escort: false,
        leo: false,
      },
    });

    const columns = [...defaultColumns];
    columns[1].accessor = row => (
      <button type="button" className="btn btn-link" style={{ padding: 0 }} onClick={onBadgeClicked(row)}>
        {row.number}
      </button>
    );
    columns[2].accessor = row => (
      <CellWithTitle value={row.expires ? moment.tz(row.expires, app.timezone).format('MM/DD/YYYY') : ''} />
    );
    setColumns(columns);

    loadData();
  }, [selectedPerson, isOpen]);

  return (
    <>
      <div className="mb-3">
        <Button color="primary" disabled={isLoading} onClick={onIssueBadgeClicked}>
          Issue New Badge
        </Button>
      </div>
      <Card>
        <CardBody>
          <div className="table-responsive" style={{ maxHeight: 150, minHeight: 150 }}>
            <ReactTable
              manual
              defaultSorted={defaultSort}
              className="border-0 -striped"
              loading={isLoading}
              data={data}
              columns={columns}
              pages={1}
              minRows={0}
              defaultPageSize={500}
              showPagination={false}
            />
          </div>

          <div className="separator mt-4 mb-4">Inactive Badges</div>

          <div className="table-responsive" style={{ maxHeight: 250, minHeight: 250 }}>
            <ReactTable
              manual
              defaultSorted={defaultSort}
              className="border-0 -striped"
              loading={isLoading}
              data={inactiveBadges}
              columns={columns}
              pages={1}
              minRows={0}
              defaultPageSize={500}
              showPagination={false}
            />
          </div>

          <BadgeDetailsModal
            isOpen={isBadgeModalOpen}
            onClose={onBadgeModalClose}
            state={app}
            api={app.api}
            badge={selectedBadge}
          />
        </CardBody>
      </Card>

      <BadgeTypeSelectionModal
        isOpen={isBadgeTypeSelectionModalOpen}
        badgeTypes={badgeTypes}
        onClose={onBadgeTypeSelectionModalClose}
      />

      {shouldReturnBadgeCreateModal && (
        <BadgeCreateModal
          isOpen={isIssueBadgeModalOpen}
          onClose={onIssueBadgeModalClose}
          predefinedBadge={badgeData}
          badgeTypes={badgeTypes}
          shouldSkipDefaultEndorsementSelection={shouldReturnPrintingModal}
        />
      )}

      {shouldReturnPacsModal && (
        <ReplacePacsBadgeModal
          isOpen={isIssueBadgeModalOpen}
          onClose={onIssueBadgeModalClose}
          badgeTypes={badgeTypes}
          defaultBadgeHolder={badgeData?.person}
        />
      )}

      {shouldReturnPrintingModal && (
        <PrintBadgeModal
          isOpen={isPrintBadgeModalOpen}
          onClose={onPrintBadgeModalClose}
          badgeType={selectedBadgeType}
          badgeData={{
            companyName: selectedSignatory?.companyName || '',
            number: '',
            firstName: selectedPerson?.first_name || '',
            middleName: '',
            lastName: selectedPerson?.last_name || '',
            fullName: buildFullName({ ...selectedPerson, middle_name: null }), // no need for middle name
            airportPersonId: selectedPerson?.tsc_person_id || '',
            dateOfBirth: selectedPerson?.date_of_birth || '',
            expiration: defaultBadgeExpiration,
            endorsements: {
              driving: defaultEndorsements?.driving || 'none',
              isEscort: defaultEndorsements?.isEscort,
              isParking: false,
              isLeo: false,
            },
          }}
        />
      )}
    </>
  );
}
