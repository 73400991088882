import React, { useCallback, useContext, useState } from 'react';
import 'moment-timezone';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GavelIcon from '@material-ui/icons/Gavel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { AppContext, colors } from '../../../../../../App';
import CustomModalHeader from '../../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../../shared/components/ModalActions/ModalActions';
import badgeApplicationApi from '../../../../../../ajax/BadgeApplication/badgeApplicationApi';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import ReadOnlyFormData from '../../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import FileUploadForm from '../../../../../../shared/components/FileUploadModal/FileUploadForm';
import filesApi from '../../../../../../ajax/File/filesApi';
import { handleError } from '../../../../../../utils';
import peopleApi from '../../../../../../ajax/People/peopleApi';

export default function AdjudicateAction() {
  const app = useContext(AppContext);
  const { badgeApplication, onActionDone, isCurrentUserASC } = useContext(ActionPanelContext);
  const { backgroundCheck, adjudicateCHRC } = badgeApplication.config;
  const [isAdjudicateModalOpen, setIsAdjudicateModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [decision, setDecision] = useState(null);
  const [files, setFiles] = useState([]);
  const [tscData, setTscData] = useState(null);

  const onFilesAdded = files => {
    setFiles(files);
  };

  const isHidden = useCallback(() => {
    if (backgroundCheck.isEnabled && !backgroundCheck.isComplete) {
      // Background Check must be done first
      return true;
    }
    if (!adjudicateCHRC.isEnabled || adjudicateCHRC.isComplete) {
      // No need to perform adjudication
      return true;
    }
    if (!isCurrentUserASC) {
      return true;
    }
    return false;
  }, [backgroundCheck, adjudicateCHRC, isCurrentUserASC, badgeApplication]);

  const onClick = () => {
    setIsAdjudicateModalOpen(true);
    app.api.toggleLoading(true);
    peopleApi
      .refreshTscInfo({ userUuid: badgeApplication.applicant.uuid })
      .then(({ success, message = '', data }) => {
        if (!success) {
          if (message.toLocaleLowerCase() === 'bcws api call failed') {
            return handleError({ message: 'Unable to retrieve updated information from TSC' });
          } else {
            return handleError({ message });
          }
        }
        setTscData(data);
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onModalClose = () => {
    setComment('');
    setDecision(null);
    setIsAdjudicateModalOpen(false);
    setTscData(null);
    onActionDone({ shouldReload: false });
  };

  const isSaveDisabled = () => {
    return !decision;
  };

  const onSaveDecision = async () => {
    const { user, api } = app;
    const { uuid: badgeApplicationUuid } = badgeApplication;

    api.toggleLoading(true);

    if (files && files.length) {
      const { data: fileTypes } = await filesApi.fileTypes();
      const chrcFileType = fileTypes.find(ft => ft.systemName === 'chrc-documentation');
      const file = files[0];
      const data = {
        user: badgeApplication.applicant.uuid,
        type: chrcFileType.uuid,
        badgeApplication: badgeApplication.uuid,
        signatory: badgeApplication.signatory.uuid,
        company: badgeApplication.signatory.company.uuid,
        comment: 'Uploaded as supporting documentation for the CHRC Adjudication step of the badge application.',
      };
      const response = await filesApi.upload({ user, file, data });
      if (!response.success) {
        api.toggleLoading(false);
        return handleError({ message: 'Unable to upload file' });
      }
    }

    badgeApplicationApi
      .adjudicateCHRC({ user, badgeApplicationUuid, decision, comment })
      .then(({ success }) => {
        if (success) {
          setComment('');
          setDecision(null);
          setIsAdjudicateModalOpen(false);
          onActionDone({ shouldReload: true });
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => api.toggleLoading(false));
  };

  return (
    <>
      <ListItem button onClick={onClick} hidden={isHidden()}>
        <ListItemIcon>
          <GavelIcon style={{ color: colors.primary }} />
        </ListItemIcon>
        <ListItemText primary="Adjudicate CHRC" />
      </ListItem>

      {isAdjudicateModalOpen && (
        <Modal size="md" isOpen={isAdjudicateModalOpen} toggle={onModalClose}>
          <CustomModalHeader toggle={onModalClose}>Adjudicate CHRC</CustomModalHeader>
          <ModalBody>
            <Row style={{ marginBottom: 15 }}>
              <Col>
                <ReadOnlyFormData
                  skipTextTransform
                  label="Case Number"
                  value={(
                    tscData?.chrcCaseNumber ||
                    badgeApplication['3rdPartyChrc']?.caseNumber ||
                    '-'
                  ).toLocaleUpperCase()}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 15 }}>
              <Col sm={12} md={6}>
                <ReadOnlyFormData
                  skipTextTransform
                  label="Status"
                  value={(tscData?.chrcStatus || '-').toLocaleUpperCase()}
                />
              </Col>
              <Col sm={12} md={6}>
                <ReadOnlyFormData
                  skipTextTransform
                  label="Result"
                  value={(tscData?.chrcResult || '-').toLocaleUpperCase()}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: 8, marginTop: 32 }}>
              <Col>
                Do you certify that you have reviewed the results of the Criminal History Records Check and have found
                no disqualifying offenses?
              </Col>
            </Row>

            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="decision"
                name="decision"
                value={decision}
                onChange={event => setDecision(event.target.value)}
              >
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </FormControl>

            <div style={{ marginTop: 16, marginBottom: 24 }}>
              <Label>Do you have any supporting documentation to attach?</Label>
              <FileUploadForm fileUploadOnly onFilesAdded={onFilesAdded} />
            </div>

            <FormGroup>
              <Label>
                Comment <i style={{ fontSize: 'smaller' }}>(optional)</i>
              </Label>
              <Input type="textarea" value={comment} onChange={event => setComment(event.target.value)} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <ModalActions
              closeLabel="Cancel"
              onClose={onModalClose}
              saveLabel="Save Decision"
              onSave={onSaveDecision}
              saveDisabled={isSaveDisabled()}
            />
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
