import request, { download, upload } from '../Request';

export default {
  getAllBadgeApplicationsForUser,
  details: getBadgeApplicationDetails,
  cancel: cancelBadgeApplication,
  skipStep: skipBadgeApplicationStep,
  completeAppointment,
  addVerificationDocument,
  startBackgroundCheck,
  adjudicateCHRC,
  checkBackgroundCheckStatus,
  updateBadgeIssuanceDecision,
  updateConfig,
  markTrainingComplete,
  getBadgerData,
  updateBadgerData,
  uploadFingerprints,
  redoBadgeApplication,
  findApplicants,
  bypassBackgroundCheck,
  issuePacsBadge,
  provideChrcDocumentation,
  downloadSummary,
  updateSettings,
  resetPayment,
};

function getAllBadgeApplicationsForUser({ userUuid, shouldIncludeRecentInactive = false, signal }) {
  let url = `badge-applications-for-user/${userUuid}`;
  if (shouldIncludeRecentInactive) {
    url = `${url}?shouldIncludeRecentInactive=true`;
  }
  return request('authenticated-user', 'GET', url, null, signal);
}

function getBadgeApplicationDetails({ user, badgeApplicationUuid }) {
  return request(user, 'GET', `badge-applications/${badgeApplicationUuid}`);
}

function cancelBadgeApplication({ badgeApplicationUuid, reason }) {
  return request('authenticated-user', 'POST', `badge-applications/${badgeApplicationUuid}/terminate`, { reason });
}

function skipBadgeApplicationStep({ user, uuid = '', comment = '', skippedRequirement = '', extra }) {
  return request(user, 'POST', `badge-applications/${uuid}/steps/skip`, {
    comment,
    skippedRequirement,
    extra,
  });
}

function completeAppointment({ user, badgeApplicationUuid, comment }) {
  return request(user, 'POST', `badge-applications/${badgeApplicationUuid}/complete-appointment`, { comment });
}

function addVerificationDocument({ user, badgeApplicationUuid, ...rest }) {
  return request(user, 'POST', `badge-applications/${badgeApplicationUuid}/add-document`, rest);
}

function startBackgroundCheck({ user, badgeApplicationUuid }) {
  return request(
    user || 'authenticated-user',
    'POST',
    `badge-applications/${badgeApplicationUuid}/start-background-check`
  );
}

function adjudicateCHRC({ user, badgeApplicationUuid, decision, comment }) {
  return request(user, 'POST', `badge-applications/${badgeApplicationUuid}/adjudicate-chrc`, { decision, comment });
}

function checkBackgroundCheckStatus({ user, badgeApplicationUuid }) {
  return request(user, 'GET', `badge-applications/${badgeApplicationUuid}/background-check-status`);
}

function updateBadgeIssuanceDecision({ user, badgeApplicationUuid, isApproved, comment }) {
  return request(user, 'PUT', `badge-applications/${badgeApplicationUuid}/badge-issuance`, { isApproved, comment });
}

function markTrainingComplete({ badgeApplicationUuid, shouldScheduleBadgePickupAppointment = false }) {
  return request('authenticated-user', 'PUT', `badge-applications/${badgeApplicationUuid}/mark-training-complete`, {
    shouldScheduleBadgePickupAppointment,
  });
}

function updateConfig({ badgeApplicationUuid, config, auxiliaryConfig }) {
  return request('authenticated-user', 'PUT', `badge-applications/${badgeApplicationUuid}/update-config`, {
    config,
    auxiliaryConfig,
  });
}

function provideChrcDocumentation({ badgeApplicationUuid, caseNumber, fileUuid, comment }) {
  return request('authenticated-user', 'PUT', `badge-applications/${badgeApplicationUuid}/attach-chrc-documentation`, {
    caseNumber,
    fileUuid,
    comment,
  });
}

function getBadgerData(badgeApplicationUuid) {
  return request('authenticated-user', 'GET', `badge-applications/${badgeApplicationUuid}/badger`);
}

function updateBadgerData({ badgeApplicationUuid, data }) {
  return request('authenticated-user', 'PUT', `badge-applications/${badgeApplicationUuid}/badger`, { data });
}

function uploadFingerprints({ badgeApplicationUuid, comment, file }) {
  const data = {
    size: file.size,
    extension: 'eft',
    mimeType: 'application/octet-stream',
    comment,
  };
  return upload(
    'authenticated-user',
    'POST',
    `badge-applications/${badgeApplicationUuid}/upload-fingerprints`,
    file,
    data
  );
}

function redoBadgeApplication({
  badgeApplicationUuid,
  reason,
  applicantSubject,
  applicantMessage,
  signatorySubject,
  signatoryMessage,
}) {
  return request('authenticated-user', 'PUT', `badge-applications/${badgeApplicationUuid}/redo-application`, {
    reason,
    applicantSubject,
    applicantMessage,
    signatorySubject,
    signatoryMessage,
  });
}

function findApplicants({ criteria, signal }) {
  return request('authenticated-user', 'POST', `badge-applications/search-applicants`, criteria, signal);
}

function bypassBackgroundCheck({ badgeApplicationUuid }) {
  return request('authenticated-user', 'PUT', `badge-applications/${badgeApplicationUuid}/bypass-background-check`);
}

function issuePacsBadge(data) {
  return request('authenticated-user', 'POST', `badge-applications/${data.badgeApplicationUuid}/pacs-badge`, data);
}

function downloadSummary({ badgeApplicationUuid }) {
  return download('authenticated-user', 'GET', `badge-applications/${badgeApplicationUuid}/download-summary`);
}

function updateSettings({ badgeApplicationUuid, changes, comments = '' }) {
  return request('authenticated-user', 'PUT', `badge-applications/${badgeApplicationUuid}`, { changes, comments });
}

function resetPayment({ badgeApplicationUuid, reason }) {
  return request('authenticated-user', 'POST', `badge-applications/${badgeApplicationUuid}/reset-payment`, { reason });
}
