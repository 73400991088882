import request from '../Request';

export default {
  getAll,
  create,
  getKey,
  addComment,
  unissue,
};

function getAll({ data, signal }) {
  return request('authenticated-user', 'POST', 'keys/list', data, signal);
}

function create(data) {
  return request('authenticated-user', 'POST', 'keys', data);
}

function getKey({ uuid }) {
  return request('authenticated-user', 'GET', `keys/${uuid}`);
}

function addComment({ uuid, comment }) {
  return request('authenticated-user', 'POST', `keys/${uuid}/comment`, { comment });
}

function unissue({ uuid }) {
  return request('authenticated-user', 'DELETE', `keys/${uuid}`);
}
