import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

export default function FormControlLabelWithHelp({ value = 'ERROR', control = <Radio color="primary" />, i18NKey }) {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'badger' });

  const helpText = translate(`${i18NKey}-help`);
  if (helpText && helpText.indexOf(i18NKey) === -1) {
    return (
      <FormControlLabel
        classes={{ root: 'FormControlLabelWithHelp-display-adjust' }}
        value={value}
        control={control}
        label={
          <div>
            {translate(i18NKey)}
            <br />
            <small>{helpText}</small>
          </div>
        }
      />
    );
  }

  return <FormControlLabel value={value} control={control} label={translate(i18NKey)} />;
}
