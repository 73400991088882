import { useCallback, useContext } from 'react';
import { AppContext } from '../App';

export default function useConfirm() {
  const app = useContext(AppContext);
  return useCallback(
    ({
      title = 'Missing Title',
      content = 'Missing Content',
      onConfirm = () => false,
      onClose = () => false,
      isDangerous = false,
      isWarning = false,
      okButtonText = 'OK',
      cancelButtonText = 'Cancel',
    }) => {
      app.api.confirmMessage(
        title,
        content,
        onConfirm,
        onClose,
        isDangerous,
        okButtonText,
        cancelButtonText,
        isWarning
      );
    },
    [app.api]
  );
}
