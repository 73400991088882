import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function Collapsible({ id, icon, title, expanded, onChange, style = {}, children }) {
  return (
    <Accordion id={id} expanded={expanded} onChange={onChange} style={style}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="div">
          <div style={{ display: 'inline-block', width: 35 }}>{icon}</div>
          <div style={{ display: 'inline-block' }}>{title}</div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>{children}</AccordionDetails>
    </Accordion>
  );
}
