import React from 'react';

export default function TitledBox({ title, style = {}, legendStyle = {}, children }) {
  return (
    <fieldset
      style={{
        border: '1px solid #e4e7ea',
        borderRadius: '0.25rem',
        padding: '8px 15px 16px 15px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        ...style,
      }}
    >
      <legend
        style={{
          marginLeft: title ? 15 : 0,
          paddingLeft: title ? 5 : 0,
          paddingRight: title ? 5 : 0,
          width: 'auto',
          fontSize: '0.875rem',
          ...legendStyle,
        }}
      >
        {title}
      </legend>
      <div style={{ width: '100%' }}>{children}</div>
    </fieldset>
  );
}
