import request from '../Request';

export default {
  changeRapbackSubscription,
  uploadNewCHRCDocumentation,
};

function changeRapbackSubscription({ userUuid, action }) {
  return request('authenticated-user', 'PUT', `background-check/${userUuid}/rapback-subscription/${action}`);
}

function uploadNewCHRCDocumentation(data) {
  return request('authenticated-user', 'PUT', `background-check/${data.userUuid}/update-chrc-documentation`, data);
}
