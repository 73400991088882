import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Alert, Button, Card, CardBody, Col, Row } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import ReadOnlyFormData from '../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import peopleApi from '../../../../../ajax/People/peopleApi';
import { formatDateWithoutTimeAndTimezone, handleError, notify } from '../../../../../utils';
import { AppContext } from '../../../../../App';
import { buildFullName, FULL_DATE_FORMAT } from '../PeopleListEditModal';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import backgroundCheckApi from '../../../../../ajax/BackgroundCheck/backgroundCheckApi';
import UploadChrcDocumentationModal from '../UploadChrcDocumentationModal';

export function VettingInfoSection({ title, children }) {
  return (
    <fieldset
      style={{
        border: '1px solid #e4e7ea',
        borderRadius: '0.25rem',
        padding: '0 15px',
      }}
    >
      <legend
        style={{
          marginLeft: 15,
          paddingLeft: 5,
          paddingRight: 5,
          width: 'auto',
          fontSize: '0.875rem',
        }}
      >
        {title}
      </legend>
      {children}
    </fieldset>
  );
}

function RapbackManagementButton({ rapbackData, onClick }) {
  const status = rapbackData.rapbackStatus.toLocaleUpperCase();
  let buttonLabel;

  if (status.includes('EXPIRED') || status.includes('CANCELLED') || status.includes('CANCELLATION IN PROGRESS')) {
    buttonLabel = 'Uncancel Rapback Subscription';
  } else {
    buttonLabel = 'Cancel Rapback Subscription';
  }

  return (
    <Button style={{ float: 'right', marginBottom: 16 }} color="warning" onClick={onClick}>
      {buttonLabel}
    </Button>
  );
}

function RapbackManagementConfirmation({ isOpen, onClose, onSave, rapbackData, person }) {
  const app = useContext(AppContext);

  if (!isOpen) {
    return null;
  }

  const { rapbackStatus, rapbackCancelInitiatedOn, rapbackUncancelInitiatedOn } = rapbackData;
  const status = rapbackStatus.toLocaleUpperCase();
  let buttonLabel;
  let action = null;
  let confirmationText;

  if (status.includes('EXPIRED') || status.includes('CANCELLED') || status.includes('CANCELLATION IN PROGRESS')) {
    buttonLabel = 'Uncancel Rapback Subscription';
    action = 'uncancel';
    confirmationText = 'uncancel the rapback subscription';
  } else {
    buttonLabel = 'Cancel Rapback Subscription';
    action = 'cancel';
    confirmationText = 'cancel the rapback subscription';
  }

  let difference = null;
  if (rapbackUncancelInitiatedOn) {
    const now = moment.tz(app.timezone);
    const rapbackUncancelInitiatedOnMoment = moment.tz(rapbackUncancelInitiatedOn, app.timezone);
    difference = now.diff(rapbackUncancelInitiatedOnMoment, 'hours');
  } else if (rapbackCancelInitiatedOn) {
    const now = moment.tz(app.timezone);
    const rapbackCancelInitiatedOnMoment = moment.tz(rapbackCancelInitiatedOn, app.timezone);
    difference = now.diff(rapbackCancelInitiatedOnMoment, 'hours');
  }

  let errorMessage = null;
  if (difference !== null && difference < 24) {
    errorMessage = (
      <div>
        <p>Rapback {action} may only be attempted once every 24 hours.</p>
        <p>You can try again in {24 - difference} hours.</p>
      </div>
    );
  }

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Confirmation"
      isOpen={isOpen}
      onClose={onClose}
      closeLabel="Cancel"
      onSave={errorMessage ? null : () => onSave({ action })}
      saveLabel={buttonLabel}
      saveColor="warning"
    >
      {errorMessage ? (
        <Alert color="warning">{errorMessage}</Alert>
      ) : (
        <p>
          Are you sure you want to <strong>{confirmationText}</strong> for{' '}
          <i>
            {buildFullName(person)} ({person.tsc_person_id})
          </i>
          ?
        </p>
      )}
    </AirBadgeModal>
  );
}

const defaultData = {
  airportPersonId: '-',
  thirdPartyChrcs: [],

  staId: '-',
  staStatus: '-',
  staDate: '-',
  staResult: '-',
  staResultDate: '-',

  chrcCaseNumber: '-',
  chrcStatus: '-',
  chrcDate: '-',
  chrcResult: '-',
  chrcResultDate: '-',

  rapbackStatus: '-',
  rapbackCaseNumber: '-',
  rapbackSubscriptionDate: '-',
  rapbackRenewalDate: '-',
  rapbackExpirationDate: '-',
  rapbackTerminationDate: '-',
  rapbackCancelInitiatedOn: null,
  rapbackCancelCompletedOn: null,
};

const defaultErrorMessage = 'Unable to retrieve updated information from TSC';

export default function PersonEditModalTscInfo({ person, isOpen }) {
  const app = useContext(AppContext);
  const isSuperAdmin = !!app?.user?.isSuperAdmin;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState({ ...defaultData });
  const [isRapbackManagementConfirmationOpen, setIsRapbackManagementConfirmationOpen] = useState(false);
  const [isUploadChrcDocumentationModalOpen, setIsUploadChrcDocumentationModalOpen] = useState(false);

  const onUploadChrcDocumentationModalDone = () => {
    setIsUploadChrcDocumentationModalOpen(false);
    loadData();
  };

  const onRapbackManagementConfirmationClose = () => {
    setIsRapbackManagementConfirmationOpen(false);
  };

  const onRapbackManagementConfirmationSave = ({ action }) => {
    app.api.toggleLoading(true);
    backgroundCheckApi
      .changeRapbackSubscription({ userUuid: person.uuid, action })
      .then(({ success, errors, message }) => {
        if (success) {
          if (action === 'cancel') {
            notify({ message: 'Rapback cancel request sent to TSC' });
          } else {
            notify({ message: 'Rapback uncancel request sent to TSC' });
          }
          onRapbackManagementConfirmationClose();
          loadData();
        } else if (errors) {
          app.api.confirmMessage(
            'Response from TSC',
            <Alert color="danger">
              <p>{errors}</p>
            </Alert>,
            () => null,
            false
          );
        } else if (message) {
          app.api.confirmMessage('Conflict Found', <p>{message}</p>, () => null, false);
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onRapbackManagementButtonClicked = () => {
    setIsRapbackManagementConfirmationOpen(true);
  };

  const formatDate = date => {
    if (!date || date === '-') return '-';
    // noinspection JSUnresolvedReference
    return moment.tz(date, app.timezone).format(FULL_DATE_FORMAT);
  };

  const loadData = () => {
    setIsLoading(true);
    setError('');
    peopleApi
      .refreshTscInfo({ userUuid: person.uuid })
      .then(({ success, message = '', data }) => {
        if (!success) {
          if (message.toLocaleLowerCase() === 'bcws api call failed') message = defaultErrorMessage;
          setError(message);
          return;
        }

        setData({
          airportPersonId: data.airportPersonId || '-',
          thirdPartyChrcs: data.thirdPartyChrcs || [],

          staId: data.staId,
          staStatus: data.staStatus || '-',
          staDate: data.staDate || '-',
          staResult: data.staResult || '-',
          staResultDate: data.staResultDate || '-',

          chrcCaseNumber: data.chrcCaseNumber,
          chrcStatus: data.chrcStatus || '-',
          chrcDate: data.chrcDate || '-',
          chrcResult: data.chrcResult || '-',
          chrcResultDate: data.chrcResultDate || '-',

          rapbackStatus: data.rapbackStatus || '-',
          rapbackCaseNumber: data.rapbackCaseNumber || '-',
          rapbackSubscriptionDate: data.rapbackSubscriptionDate || '-',
          rapbackRenewalDate: data.rapbackRenewalDate || '-',
          rapbackExpirationDate: data.rapbackExpirationDate || '-',
          rapbackTerminationDate: data.rapbackTerminationDate || '-',
          rapbackCancelInitiatedOn: data.rapbackCancelInitiatedOn,
          rapbackCancelCompletedOn: data.rapbackCancelCompletedOn,
          rapbackUncancelInitiatedOn: data.rapbackUncancelInitiatedOn,
        });
      })
      .catch(error => {
        handleError({ error });
        setError(defaultErrorMessage);
      })
      .finally(() => setIsLoading(false));
  };

  const getStaResultDisplay = () => {
    const result = data.staResult.toLocaleUpperCase();
    return result === 'PASS' ? (
      <div>
        <i className="fa-solid fa-check text-success mr-1"></i>PASS
      </div>
    ) : (
      result
    );
  };

  const getChrcResultDisplay = () => {
    const result = data.chrcResult.toLocaleUpperCase();
    return result === 'COMPLETED' ? (
      <div>
        <i className="fa-solid fa-check text-success mr-1"></i>COMPLETED
      </div>
    ) : (
      result
    );
  };

  const getRapbackStatusDisplay = () => {
    const status = data.rapbackStatus.toLocaleUpperCase();
    return status === 'SUBSCRIBED' ? (
      <div>
        <i className="fa-solid fa-check text-success mr-1"></i>SUBSCRIBED
      </div>
    ) : (
      status
    );
  };

  useEffect(() => {
    if (person && isOpen) {
      loadData();
    }
  }, [person, isOpen]);

  if (!person) return null;

  if (isLoading) {
    return (
      <Card>
        <CardBody>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={30} />
            <div className="ml-4">Requesting updated information from TSC</div>
          </div>
        </CardBody>
      </Card>
    );
  }

  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  return (
    <Card>
      <CardBody>
        <VettingInfoSection title={`STA ${data.staId ? `(${data.staId})` : ''}`}>
          <Row>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData skipTextTransform label="Status" value={data.staStatus.toLocaleUpperCase()} />
            </Col>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData skipTextTransform label="Status Date" value={formatDate(data.staDate)} />
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData skipTextTransform label="Result" value={getStaResultDisplay()} />
            </Col>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData skipTextTransform label="Result Date" value={formatDate(data.staResultDate)} />
            </Col>
          </Row>
        </VettingInfoSection>

        {(isSuperAdmin || !!data.thirdPartyChrcs.length) && (
          <>
            <div style={{ margin: '8px 0' }}>&nbsp;</div>
            <div style={{ textAlign: 'right' }}>
              <Button color="primary" onClick={() => setIsUploadChrcDocumentationModalOpen(true)}>
                Upload New CHRC Documentation
              </Button>
            </div>
            <VettingInfoSection title="CHRC (from other companies)">
              {!data.thirdPartyChrcs.length && (
                <div style={{ marginBottom: 12 }}>
                  There are currently no 3rd party CHRCs for this person. You are seeing this because you are an admin.
                </div>
              )}
              {data.thirdPartyChrcs.slice(0, 5).map((chrc, idx) => (
                <div key={idx}>
                  <Grid container spacing={3} style={{ marginBottom: 8 }}>
                    <Grid container item spacing={3}>
                      <Grid item sm={12} md={6}>
                        <ReadOnlyFormData skipTextTransform label="Case Number" value={chrc.caseNumber} />
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <ReadOnlyFormData skipTextTransform label="Company" value={chrc.companyName} />
                      </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                      <Grid item sm={12} md={6}>
                        <ReadOnlyFormData
                          skipTextTransform
                          label="Uploaded to AirBadge"
                          value={formatDate(chrc.statusDate)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {idx < data.thirdPartyChrcs.length - 1 && <hr style={{ marginTop: 0 }} />}
                </div>
              ))}
            </VettingInfoSection>
          </>
        )}

        <div style={{ margin: '8px 0' }}>&nbsp;</div>

        <VettingInfoSection title={`CHRC ${data.chrcCaseNumber ? `(${data.chrcCaseNumber})` : ''}`}>
          <Row>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData skipTextTransform label="Status" value={data.chrcStatus.toLocaleUpperCase()} />
            </Col>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData skipTextTransform label="Status Date" value={formatDate(data.chrcDate)} />
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData skipTextTransform label="Result" value={getChrcResultDisplay()} />
            </Col>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData skipTextTransform label="Result Date" value={formatDate(data.chrcResultDate)} />
            </Col>
          </Row>
        </VettingInfoSection>

        <div style={{ margin: '8px 0' }}>&nbsp;</div>

        <VettingInfoSection title="Rapback Subscription">
          <Row>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData skipTextTransform label="Status" value={getRapbackStatusDisplay()} />
            </Col>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData
                skipTextTransform
                label="Subscription Date"
                value={formatDateWithoutTimeAndTimezone({ date: data.rapbackSubscriptionDate })}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData skipTextTransform label="Case Number" value={data.rapbackCaseNumber} />
            </Col>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData skipTextTransform label="Renewal Date" value={formatDate(data.rapbackRenewalDate)} />
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData
                skipTextTransform
                label="Expiration Date"
                value={formatDate(data.rapbackExpirationDate)}
              />
            </Col>
            <Col md={12} lg={6} style={{ marginBottom: 15 }}>
              <ReadOnlyFormData
                skipTextTransform
                label="Termination Date"
                value={formatDate(data.rapbackTerminationDate)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <RapbackManagementButton
                rapbackData={data}
                onClick={onRapbackManagementButtonClicked}
                timezone={app.timezone}
              />
            </Col>
          </Row>
        </VettingInfoSection>

        <RapbackManagementConfirmation
          isOpen={isRapbackManagementConfirmationOpen}
          onClose={onRapbackManagementConfirmationClose}
          onSave={onRapbackManagementConfirmationSave}
          rapbackData={data}
          person={person}
          timezone={app.timezone}
        />

        <UploadChrcDocumentationModal
          isOpen={isUploadChrcDocumentationModalOpen}
          user={person}
          onDone={onUploadChrcDocumentationModalDone}
        />
      </CardBody>
    </Card>
  );
}
