import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import NextButton from '../components/NextButton';
import { BlockFormLabel } from '../components/styledComponents';
import FormControlLabelWithHelp from '../components/FormControlLabelWithHelp';

export default function CitizenshipQuestion({ isHidden, value, onChange, onNext }) {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'badger' });
  const [isNextHidden, setIsNextHidden] = useState(true);
  const [firstAnswer, setFirstAnswer] = useState(null);
  const [isNextClicked, setIsNextClicked] = useState(false);

  const onNextClicked = () => {
    if (!isNextClicked) {
      setIsNextClicked(true);
      setIsNextHidden(true);
    } else {
      onNext();
    }
  };

  if (isHidden) return null;

  return (
    <>
      {!isNextClicked && (
        <>
          <BlockFormLabel>
            <Typography variant="h6">{translate('citizenship.question1.label')}</Typography>
          </BlockFormLabel>
          <FormControl>
            <RadioGroup
              aria-label="citizenship question 1"
              name="citizenshipQuestion1"
              value={firstAnswer}
              onChange={({ target }) => {
                setFirstAnswer(target.value);
                setIsNextHidden(!target.value);
              }}
            >
              <FormControlLabelWithHelp
                value="yes"
                control={<Radio color="primary" />}
                i18NKey="citizenship.question1.answer1"
              />
              <FormControlLabelWithHelp
                value="no"
                control={<Radio color="primary" />}
                i18NKey="citizenship.question1.answer2"
              />
            </RadioGroup>
          </FormControl>
        </>
      )}

      {isNextClicked ? (
        <>
          <BlockFormLabel>
            <Typography variant="h6">{translate('citizenship.question2.label')}</Typography>
          </BlockFormLabel>
          <FormControl>
            <RadioGroup
              aria-label="citizenship question 2"
              name="citizenshipQuestion2"
              value={value}
              onChange={({ target }) => {
                onChange(target.value);
                setIsNextHidden(!target.value);
              }}
            >
              {firstAnswer === 'yes' ? (
                <>
                  <FormControlLabelWithHelp
                    value="usa_born"
                    control={<Radio color="primary" />}
                    i18NKey="citizenship.question2.answer1"
                  />
                  <FormControlLabelWithHelp
                    value="usa_naturalized"
                    control={<Radio color="primary" />}
                    i18NKey="citizenship.question2.answer2"
                  />
                  <FormControlLabelWithHelp
                    value="usa_born_abroad"
                    control={<Radio color="primary" />}
                    i18NKey="citizenship.question2.answer3"
                  />
                </>
              ) : (
                <>
                  <FormControlLabelWithHelp
                    value="permanent_resident"
                    control={<Radio color="primary" />}
                    i18NKey="citizenship.question2.answer4"
                  />
                  <FormControlLabelWithHelp
                    value="authorized_alien"
                    control={<Radio color="primary" />}
                    i18NKey="citizenship.question2.answer5"
                  />
                  <FormControlLabelWithHelp
                    value="non_citizen_national"
                    control={<Radio color="primary" />}
                    i18NKey="citizenship.question2.answer6"
                  />
                </>
              )}
            </RadioGroup>
          </FormControl>
        </>
      ) : null}

      <NextButton isDisabled={isNextHidden} onClick={onNextClicked} />
    </>
  );
}
