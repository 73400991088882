import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';
import { AppContext, basePaymentChoices, invoicePaymentChoice, noChargePaymentChoice } from '../../../../../../App';
import CustomModalHeader from '../../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ReadOnlyFormData from '../../../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import ModalActions from '../../../../../../shared/components/ModalActions/ModalActions';
import BadgeApplicationCreateRequest from '../../../../../../ajax/BadgeApplication/BadgeApplicationCreateRequest';
import InputWithCharacterLimit from '../../../../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import { handleError } from '../../../../../../utils';
import Feature from '../../../../../../shared/Feature';
import { BadgeApplicationContext } from '../../../../BadgeApplications/BadgeApplicationsList/BadgeApplicationModal';
import BadgeApplicationEndorsementsPicker from '../../../../BadgeApplications/BadgeApplicationEndorsementsPicker/BadgeApplicationEndorsementsPicker';
import { getDrivingOptions } from '../../../../../../shared/BadgeTypes/drivingOptionsUtils';

const defaultApplicationData = {
  user: null,
  badgeType: null,
  authorizedSignatory: null,
  badge: null,
  driving: null,
  escort: null,
  doors: '',
  purpose: '',
};

export default function StartRenewalActionModal({ isOpen, onDone, badge, history, isSignatory }) {
  const app = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [details, setDetails] = useState({});
  const [paymentChoice, setPaymentChoice] = useState(basePaymentChoices.find(x => x.value === 'signatory'));
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [applicationData, setApplicationData] = useState({ ...defaultApplicationData });

  const onModalClose = () => {
    setIsModalOpen(false);
    setPaymentChoice(basePaymentChoices.find(x => x.value === 'signatory'));
    setPaymentOptions([]);
    setApplicationData({ ...defaultApplicationData });
    setDetails({});
    onDone();
  };

  const onCreateClick = () => {
    const { api, user } = app;
    api.toggleLoading(true);
    const badgeApplication = {
      application_type: 'renewal',
      applicant_uuid: applicationData.user,
      badge_type_uuid: applicationData.badgeType.uuid,
      renewing_badge_uuid: applicationData.badge,
      authorized_signatory_profile_uuid: applicationData.authorizedSignatory,
      escort: applicationData.escort?.value,
      driving: applicationData.driving.value,
      doors: applicationData.doors,
      purpose: applicationData.purpose,
      paymentChoice: paymentChoice?.value,
    };

    BadgeApplicationCreateRequest(user, badgeApplication)
      .then(({ success, message, documentTransactionUuid }) => {
        if (!success) {
          return handleError({ message: message || 'Could not create badge application' });
        }
        onModalClose();
        if (isSignatory && documentTransactionUuid) {
          sessionStorage.setItem('AirBadge.BadgeApplications.returnTo', 'applications-grid');
          history.push(`/default/external-document/${documentTransactionUuid}`);
        } else {
          history.push(`/default/badge-applications`);
        }
      })
      .catch(error => handleError({ error, message: 'Could not create badge application' }))
      .finally(() => api.toggleLoading(false));
  };

  const isCreateButtonDisabled = () => {
    return (
      applicationData.user === null ||
      applicationData.badgeType === null ||
      applicationData.authorizedSignatory === null ||
      applicationData.user === null ||
      applicationData.driving === null ||
      applicationData.escort === null ||
      !applicationData.doors ||
      !applicationData.purpose ||
      !paymentChoice
    );
  };

  const getPaymentOptions = async authorizedSignatoryCompanySettings => {
    const { isApplicantPaymentEnabled, isSignatoryPaymentEnabled, isStripeEnabled, isInPersonPaymentEnabled } =
      app.airport.payments;

    let choices = [];

    if (isStripeEnabled) {
      if (isApplicantPaymentEnabled) {
        choices.push(basePaymentChoices.find(x => x.value === 'applicant'));
      }
      if (isSignatoryPaymentEnabled) {
        choices.push(basePaymentChoices.find(x => x.value === 'signatory'));
      }
    }

    if (isInPersonPaymentEnabled) {
      choices.push(basePaymentChoices.find(x => x.value === 'in-person'));
    }

    if (authorizedSignatoryCompanySettings.shouldNeverChargePayment) {
      choices.push(noChargePaymentChoice);
    } else if (authorizedSignatoryCompanySettings.shouldAllowPaymentInvoice) {
      choices.push(invoicePaymentChoice);
    }

    setPaymentOptions(choices);
  };

  const getBadgeTypeDisplay = () => {
    const { badgeTypeDetails } = details;
    const rootLabel = `${badgeTypeDetails.name}${badgeTypeDetails.accessLevels}`;
    if (badgeTypeDetails.description) {
      return (
        <div>
          {rootLabel}
          <div style={{ fontStyle: 'italic', fontSize: 12 }}>{badgeTypeDetails.description}</div>
        </div>
      );
    }
    return rootLabel;
  };

  useEffect(() => {
    if (!isOpen || !badge) return;

    const newDetails = { ...badge };
    if (newDetails.renewsIntoBadgeType) {
      newDetails.badgeTypeDetails = newDetails.renewsIntoBadgeType;
    }

    const accessLevels = [];
    const { badgeTypeDetails } = newDetails;
    if (badgeTypeDetails.isSida) {
      accessLevels.push('SIDA');
    }
    if (badgeTypeDetails.isSecured) {
      accessLevels.push('SECURED');
    }
    if (badgeTypeDetails.isSterile) {
      accessLevels.push('STERILE');
    }
    if (badgeTypeDetails.isAoa) {
      accessLevels.push('AOA');
    }
    if (badgeTypeDetails.isPublic) {
      accessLevels.push('PUBLIC');
    }
    badgeTypeDetails.accessLevels = accessLevels.length ? ` (${accessLevels.join(', ')})` : '';

    const drivingOptions = getDrivingOptions(badgeTypeDetails?.config?.endorsements?.driving);
    const driving = drivingOptions.find(x => x.value === badge.originalDriving);

    const { isApplicantPaymentEnabled, isSignatoryPaymentEnabled, isStripeEnabled, isInPersonPaymentEnabled } =
      app.airport.payments;
    const { shouldNeverChargePayment, shouldAllowPaymentInvoice } = badge.authorizedSignatoryCompanySettings;
    if (shouldNeverChargePayment) {
      setPaymentChoice(noChargePaymentChoice);
    } else if (shouldAllowPaymentInvoice) {
      setPaymentChoice(invoicePaymentChoice);
    } else if (isStripeEnabled) {
      if (isApplicantPaymentEnabled) {
        setPaymentChoice(basePaymentChoices.find(x => x.value === 'applicant'));
      } else if (isSignatoryPaymentEnabled) {
        setPaymentChoice(basePaymentChoices.find(x => x.value === 'signatory'));
      }
    } else if (isInPersonPaymentEnabled) {
      setPaymentChoice(basePaymentChoices.find(x => x.value === 'in-person'));
    }

    setApplicationData({
      ...defaultApplicationData,
      user: newDetails.badgeHolderUuid || newDetails.badgeHolder.uuid,
      badgeType: badgeTypeDetails,
      authorizedSignatory: newDetails.authorizedSignatoryProfileUuid,
      badge: newDetails.uuid,
      driving,
      escort: badge.isEscort ? { label: 'yes', value: 1 } : { label: 'no', value: 0 },
    });
    setDetails(newDetails);
    setIsModalOpen(true);
    getPaymentOptions(badge.authorizedSignatoryCompanySettings || {});
  }, [isOpen, badge]);

  if (!isModalOpen) return null;

  return (
    <Modal size="md" isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader label="New Badge Renewal Application" onClick={onModalClose} />
      <ModalBody>
        <BadgeApplicationContext.Provider value={{ applicationData, setApplicationData }}>
          <Row>
            <Col>
              <ReadOnlyFormData
                label="Signatory (Company)"
                value={`${details.authorizedSignatoryName} (${details.authorizedSignatoryCompany})`}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ReadOnlyFormData label="Badge Holder" value={details.badgeHolderName} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ReadOnlyFormData label="Badge Type (Access Level)" value={getBadgeTypeDisplay()} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ReadOnlyFormData label="Badge Being Renewed" value={details.badgeId} />
            </Col>
          </Row>

          <div>&nbsp;</div>

          <BadgeApplicationEndorsementsPicker shouldSkipDefaultSelection={true} />

          <Row className="mt-3">
            <Col>
              <FormGroup>
                <Label>
                  <div className="required">Which door, pedestrian gate, and/or vehicle gate access is requested?</div>
                  <div style={{ fontStyle: 'italic', fontSize: 13 }}>
                    If there are no changes, write "<i>No Changes</i>".
                  </div>
                </Label>
                <InputWithCharacterLimit
                  style={{ height: 60 }}
                  limit={191}
                  value={applicationData.doors}
                  onChange={value => setApplicationData({ ...applicationData, doors: value })}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <FormGroup>
                <Label className="required">For what purpose does this individual require a badge?</Label>
                <InputWithCharacterLimit
                  style={{ height: 60 }}
                  limit={191}
                  value={applicationData.purpose}
                  onChange={value => setApplicationData({ ...applicationData, purpose: value })}
                />
              </FormGroup>
            </Col>
          </Row>
          <Feature id="payments">
            <Row className="mt-3">
              <Col>
                <FormGroup>
                  <Label className="required">Who is going to pay for this badge application?</Label>
                  <Select
                    placeholder=""
                    classNamePrefix="airbadge"
                    className="form-select"
                    options={paymentOptions}
                    value={paymentChoice}
                    onChange={setPaymentChoice}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Feature>
        </BadgeApplicationContext.Provider>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Close"
          onClose={onModalClose}
          saveLabel="Create"
          onSave={onCreateClick}
          saveDisabled={isCreateButtonDisabled()}
        />
      </ModalFooter>
    </Modal>
  );
}
