import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { Alert, FormGroup, Input, Label } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import { AppContext } from '../../../../App';
import filesApi from '../../../../ajax/File/filesApi';
import { buildFullName, handleError } from '../../../../utils';
import badgeApplicationApi from '../../../../ajax/BadgeApplication/badgeApplicationApi';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import FileUploadForm from '../../../../shared/components/FileUploadModal/FileUploadForm';
import companyApi from '../../../../ajax/Company/companyApi';
import backgroundCheckApi from '../../../../ajax/BackgroundCheck/backgroundCheckApi';

export default function UploadChrcDocumentationModal({ isOpen, badgeApplication, user, onDone }) {
  const app = useContext(AppContext);
  const [comment, setComment] = useState('');
  const [caseNumber, setCaseNumber] = useState('');
  const [files, setFiles] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [fullName, setFullName] = useState(null);
  const [company, setCompany] = useState(null);
  const [companies, setCompanies] = useState([]);

  const reset = () => {
    setCaseNumber('');
    setComment('');
    setFiles([]);
    setFullName(null);
    setCompany(null);
    setCompanies([]);
  };

  const onCloseWithoutSaving = () => {
    reset();
    onDone({ wasChrcDocumentationUploaded: false });
  };

  const onSaveChanges = async () => {
    app.api.toggleLoading(true);

    if (!files.length) return;

    const fileType = fileTypes.find(ft => ft.systemName === 'chrc-documentation');
    const file = files[0];
    let fileUploadComment = 'Uploaded as part of completing the CHRC Documentation for the badge application.';
    if (!badgeApplication) {
      fileUploadComment = 'Manually uploaded on the person details screen';
    }
    const data = {
      user: badgeApplication?.applicant?.uuid || user?.uuid,
      type: fileType.uuid,
      badgeApplication: badgeApplication?.uuid || null,
      signatory: badgeApplication?.signatory?.uuid || null,
      company: badgeApplication?.signatory?.company?.uuid || company?.uuid || null,
      comment: fileUploadComment,
    };
    const { success, uuid } = await filesApi.upload({ file, data });
    if (!success) {
      app.api.toggleLoading(false);
      return handleError({ message: 'Unable to upload file' });
    }

    if (badgeApplication) {
      const response = await badgeApplicationApi.provideChrcDocumentation({
        badgeApplicationUuid: badgeApplication.uuid,
        caseNumber,
        fileUuid: uuid,
        comment,
      });

      app.api.toggleLoading(false);

      if (!response.success) {
        return handleError({ message: 'Unable to mark badge application step as completed' });
      }
    } else {
      const response = await backgroundCheckApi.uploadNewCHRCDocumentation({
        userUuid: user.uuid,
        caseNumber,
        companyUuid: company.uuid,
        fileUuid: uuid,
        comment,
      });

      app.api.toggleLoading(false);

      if (!response.success) {
        return handleError({ message: 'Unable to update CHRC documentation' });
      }
    }

    reset();
    return onDone({ wasChrcDocumentationUploaded: true });
  };

  const isSaveDisabled = () => {
    if (badgeApplication) {
      return !files.length || !caseNumber;
    }

    return !files.length || !caseNumber || !company;
  };

  const addFiles = files => {
    setFiles(files);
  };

  const loadCompanies = () => {
    return companyApi
      .getSimpleList()
      .then(({ data }) => {
        const companies = data.map(x => ({ label: x.name, value: x.uuid, uuid: x.uuid }));
        setCompanies(companies);
      })
      .catch(error => handleError({ error }));
  };

  useEffect(() => {
    if (!isOpen || (!badgeApplication && !user)) return;

    filesApi
      .fileTypes()
      .then(({ data }) => setFileTypes(data))
      .catch(error => handleError({ error }));

    if (badgeApplication) {
      setFullName(badgeApplication.applicant.fullName);
    } else if (user) {
      setFullName(buildFullName(user));
      loadCompanies();
    }
  }, [isOpen, badgeApplication, user]);

  if (!isOpen || (!badgeApplication && !user)) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title={
        <div>
          <div>Provide CHRC Documentation</div>
          {!!fullName && <div style={{ fontStyle: 'italic' }}>for {fullName}</div>}
        </div>
      }
      isOpen={isOpen}
      onClose={onCloseWithoutSaving}
      closeLabel="Cancel"
      onSave={onSaveChanges}
      saveLabel={badgeApplication ? 'Complete CHRC Documentation' : 'Upload New CHRC Documentation'}
      saveDisabled={isSaveDisabled()}
    >
      {badgeApplication ? (
        <div>
          A copy of the <i>CHRC documentation</i> must be uploaded and attached to this badge application before you can
          proceed
        </div>
      ) : (
        <Alert color="warning">
          Uploading new CHRC documentation will send a request to TSC to replace the old Case Number with the new one
          provided here
        </Alert>
      )}
      <div className="mt-4 mb-4">
        <FileUploadForm onFilesAdded={addFiles} />
      </div>

      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <FormGroup>
            {badgeApplication ? (
              <Label>
                <div className="required">Case Number</div>
                <div style={{ fontSize: 'smaller' }}>
                  <i>Required by TSC in order to {badgeApplication ? 'initiate' : 'update'} the background check</i>
                </div>
              </Label>
            ) : (
              <Label className="required">Case Number</Label>
            )}
            <Input
              style={{ width: 330 }}
              required
              value={caseNumber}
              onChange={event => setCaseNumber(event?.target?.value)}
            />
          </FormGroup>
        </Grid>

        {!badgeApplication && (
          <Grid item sm={12} md={6}>
            <FormGroup>
              <Label className="required">Company</Label>
              <Select
                isClearable={true}
                placeholder=""
                classNamePrefix="airbadge"
                className="form-select"
                options={companies}
                value={company}
                onChange={option => {
                  setCompany(option);
                }}
              />
            </FormGroup>
          </Grid>
        )}
      </Grid>

      <FormGroup>
        <Label>
          <div>Comments</div>
          <div style={{ fontSize: 'smaller' }}>
            <i>Provide any additional details that should be recorded</i>
          </div>
        </Label>
        <Input required type="textarea" value={comment} onChange={event => setComment(event?.target?.value)} />
      </FormGroup>
    </AirBadgeModal>
  );
}
