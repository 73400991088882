import React, { useContext, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import { AppContext, colors } from '../../../../../../App';
import { ActionPanelContext } from '../BadgeApplicationActionPanel';
import FingerprintingCodeModal from '../FingerprintingCodeModal';
import LakotaFingerprints from '../../../../../../shared/Fingerprints/LakotaFingerprints';

export function isGenerateFingerprintCodeActionHidden({ badgeApplication, isCurrentUserSignatory }) {
  if (!badgeApplication) return true;

  const { applicationDocument, fingerprintAppointment, adjudicateCHRC } = badgeApplication.config;

  if (!fingerprintAppointment.isEnabled) {
    // This badge application workflow doesn't require fingerprints
    return true;
  }
  if (applicationDocument.isEnabled && !applicationDocument.isComplete) {
    // Application document needs to be done first
    return true;
  }
  if (isCurrentUserSignatory) {
    // Signatories are not responsible for fingerprints
    return true;
  }
  if (adjudicateCHRC.isEnabled && adjudicateCHRC.isComplete) {
    // Background check is done so there is no longer a need for fingerprints
    return true;
  }
  return false;
}

export default function GenerateFingerprintCodeAction() {
  const app = useContext(AppContext);
  const isLakotaFingerprintsEnabled = !!app?.airport?.isLakotaFingerprintsEnabled;
  const { badgeApplication, onActionDone, isCurrentUserSignatory } = useContext(ActionPanelContext);
  const [isFingerprintingCodeModalOpen, setIsFingerprintingCodeModalOpen] = useState(false);
  const [isAirBadgeFingerprintsCaptureModalOpen, setIsAirBadgeFingerprintsCaptureModalOpen] = useState(false);

  const { fingerprintAppointment } = badgeApplication.config;
  let buttonIcon = <VpnKeyOutlinedIcon style={{ color: colors.primary }} />;
  let buttonText = fingerprintAppointment.isComplete ? 'Re-generate Fingerprint Code' : 'Generate Fingerprint Code';
  if (isLakotaFingerprintsEnabled) {
    buttonIcon = <FingerprintIcon style={{ color: colors.primary }} />;
    buttonText = fingerprintAppointment.fingerprintsFileUuid ? 'Re-capture Fingerprints' : 'Capture Fingerprints';
  }

  const onButtonClicked = () => {
    if (isLakotaFingerprintsEnabled) {
      setIsAirBadgeFingerprintsCaptureModalOpen(true);
    } else {
      setIsFingerprintingCodeModalOpen(true);
    }
  };

  const onAirBadgeFingerprintsCaptureModalClose = ({ wasCaptured }) => {
    setIsAirBadgeFingerprintsCaptureModalOpen(false);
    onActionDone({ shouldReload: wasCaptured });
  };

  if (isGenerateFingerprintCodeActionHidden({ badgeApplication, isCurrentUserSignatory })) return null;

  return (
    <>
      <ListItem button onClick={onButtonClicked}>
        <ListItemIcon>{buttonIcon}</ListItemIcon>
        <ListItemText primary={buttonText} />
      </ListItem>

      <FingerprintingCodeModal
        isOpen={isFingerprintingCodeModalOpen}
        onClose={() => {
          setIsFingerprintingCodeModalOpen(false);
          onActionDone({ shouldReload: true });
        }}
        badgeApplicationUuid={badgeApplication.uuid}
      />

      {isLakotaFingerprintsEnabled && (
        <LakotaFingerprints
          isOpen={isAirBadgeFingerprintsCaptureModalOpen}
          onClose={onAirBadgeFingerprintsCaptureModalClose}
          badgeApplication={badgeApplication}
        />
      )}
    </>
  );
}
