import React, { useContext } from 'react';
import { AMPUTATED_COLOR, BANDAGED_COLOR, defaultFingerConfig, LakotaFingerprintsContext } from './LakotaFingerprints';
import hands from './hands.png';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../components/AirBadgeModal/AirBadgeModal';

export default function AnnotateStep({ isOpen, onClose }) {
  const {
    leftPinky,
    setLeftPinky,
    leftRing,
    setLeftRing,
    leftMiddle,
    setLeftMiddle,
    leftIndex,
    setLeftIndex,
    leftThumb,
    setLeftThumb,
    rightPinky,
    setRightPinky,
    rightRing,
    setRightRing,
    rightMiddle,
    setRightMiddle,
    rightIndex,
    setRightIndex,
    rightThumb,
    setRightThumb,
  } = useContext(LakotaFingerprintsContext);

  const handleFingertipClicked = (finger, setFinger) => () => {
    let newFinger = {
      ...finger,
      isAmputated: false,
      isBandaged: false,
      color: defaultFingerConfig.color,
    };

    if (!finger.isAmputated && !finger.isBandaged) {
      newFinger = { ...finger, isAmputated: true, color: AMPUTATED_COLOR };
    } else if (finger.isAmputated && !finger.isBandaged) {
      newFinger = { ...finger, isAmputated: false, isBandaged: true, color: BANDAGED_COLOR };
    }

    setFinger(newFinger);
  };

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      title="Annotate Amputated or Bandaged Fingers"
      isOpen={isOpen}
      onClose={onClose}
      saveLabel="Save Annotations"
      onSave={onClose}
    >
      <style>{`
        .wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .hands-container {
            position: relative;
        }
        .hand-image {
            display: block;
            max-width: 100%;
        }
        .fingertip {
            position: absolute;
            width: 32px;
            height: 45px;
            border-radius: 50% 50% 0 0;
            cursor: pointer;
        }
      `}</style>

      <div className="wrapper">
        <div className="hands-container">
          <img src={hands} alt="Hands" className="hand-image" />

          <div
            className="fingertip"
            style={{
              top: 109,
              left: 3,
              transform: 'rotate(-30deg)',
              background: `linear-gradient(to bottom, ${leftPinky.color} 50%, transparent 100%)`,
            }}
            onClick={handleFingertipClicked(leftPinky, setLeftPinky)}
          ></div>
          <div
            className="fingertip"
            style={{
              top: 42,
              left: 65,
              width: 33,
              transform: 'rotate(-17deg)',
              background: `linear-gradient(to bottom, ${leftRing.color} 50%, transparent 100%)`,
            }}
            onClick={handleFingertipClicked(leftRing, setLeftRing)}
          ></div>
          <div
            className="fingertip"
            style={{
              top: 4,
              left: 167,
              width: 34,
              transform: 'rotate(1deg)',
              background: `linear-gradient(to bottom, ${leftMiddle.color} 50%, transparent 100%)`,
            }}
            onClick={handleFingertipClicked(leftMiddle, setLeftMiddle)}
          ></div>
          <div
            className="fingertip"
            style={{
              top: 49,
              left: 260,
              width: 34,
              transform: 'rotate(17deg)',
              background: `linear-gradient(to bottom, ${leftIndex.color} 50%, transparent 100%)`,
            }}
            onClick={handleFingertipClicked(leftIndex, setLeftIndex)}
          ></div>
          <div
            className="fingertip"
            style={{
              top: 201,
              left: 313,
              width: 48,
              height: 63,
              transform: 'rotate(47deg)',
              background: `linear-gradient(to bottom, ${leftThumb.color} 50%, transparent 100%)`,
            }}
            onClick={handleFingertipClicked(leftThumb, setLeftThumb)}
          ></div>

          <div
            className="fingertip"
            style={{
              top: 201,
              left: 406,
              width: 48,
              height: 63,
              transform: 'rotate(-47deg)',
              background: `linear-gradient(to bottom, ${rightThumb.color} 50%, transparent 100%)`,
            }}
            onClick={handleFingertipClicked(rightThumb, setRightThumb)}
          ></div>
          <div
            className="fingertip"
            style={{
              top: 49,
              left: 470,
              width: 35,
              transform: 'rotate(-19deg)',
              background: `linear-gradient(to bottom, ${rightIndex.color} 50%, transparent 100%)`,
            }}
            onClick={handleFingertipClicked(rightIndex, setRightIndex)}
          ></div>
          <div
            className="fingertip"
            style={{
              top: 5,
              left: 563,
              width: 35,
              transform: 'rotate(-2deg)',
              background: `linear-gradient(to bottom, ${rightMiddle.color} 50%, transparent 100%)`,
            }}
            onClick={handleFingertipClicked(rightMiddle, setRightMiddle)}
          ></div>
          <div
            className="fingertip"
            style={{
              top: 42,
              left: 668,
              width: 35,
              transform: 'rotate(18deg)',
              background: `linear-gradient(to bottom, ${rightRing.color} 50%, transparent 100%)`,
            }}
            onClick={handleFingertipClicked(rightRing, setRightRing)}
          ></div>
          <div
            className="fingertip"
            style={{
              top: 111,
              left: 730,
              transform: 'rotate(29deg)',
              background: `linear-gradient(to bottom, ${rightPinky.color} 50%, transparent 100%)`,
            }}
            onClick={handleFingertipClicked(rightPinky, setRightPinky)}
          ></div>
        </div>
      </div>

      <div className="text-center mt-5">
        <h3>
          <strong>Click the fingertips than cannot be captured</strong>
        </h3>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 16 }}>
            <div
              style={{
                width: 32,
                height: 35,
                borderRadius: '50% 50% 0 0',
                background: `linear-gradient(to bottom, ${AMPUTATED_COLOR} 50%, transparent 100%)`,
              }}
            ></div>
            <div style={{ fontWeight: 'bold', marginLeft: 24, fontSize: 'larger', width: 360, textAlign: 'left' }}>
              Amputated or permanently missing
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', paddingTop: 16 }}>
            <div
              style={{
                width: 32,
                height: 35,
                borderRadius: '50% 50% 0 0',
                background: `linear-gradient(to bottom, ${BANDAGED_COLOR} 50%, transparent 100%)`,
              }}
            ></div>
            <div style={{ fontWeight: 'bold', marginLeft: 24, fontSize: 'larger', width: 360, textAlign: 'left' }}>
              Bandaged or temporarily unprintable
            </div>
          </div>
        </div>
      </div>
    </AirBadgeModal>
  );
}
