import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import routes from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import Loading from './Loading';
import Message from './Message';
import Confirm from './Confirm';

export default class DefaultLayout extends Component {
  render() {
    return (
      <div className={'app'}>
        <Loading loading={this.props.state.loading} target={this.props.state.loadingTarget} />
        <Message brand={this.props.state.messageBrand} message={this.props.state.messageContent} />
        <Confirm
          api={this.props.api}
          title={this.props.state.confirmTitle}
          message={this.props.state.confirmContent}
          onConfirm={this.props.state.confirmCallbackOnConfirm}
          onClose={this.props.state.confirmCallbackOnClose}
          isDangerous={this.props.state.confirmIsDangerous}
          isWarning={this.props.state.confirmIsWarning}
          okBtnText={this.props.state.confirmOkBtnText}
          cancelBtnText={this.props.state.cancelBtnText}
        />

        <DefaultHeader api={this.props.api} state={this.props.state} />

        <div className={'app-body m-0'}>
          <div id="mainContentArea" className={'main'}>
            <div className={'pt-4'}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} state={this.props.state} api={this.props.api} />}
                    />
                  ) : null;
                })}
                <Redirect from={'/'} to={'/login'} />
              </Switch>
            </div>
          </div>
        </div>
        <footer className="app-footer">
          <Container>
            <DefaultFooter applicationState={this.props.state} />
          </Container>
        </footer>
      </div>
    );
  }
}
