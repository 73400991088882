import React, { useState } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import NextButton from '../components/NextButton';
import { BlockFormLabel } from '../components/styledComponents';

export default function ListBDocuments({ isHidden, data, onChange, onNext }) {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'badger' });
  const [isNextHidden, setIsNextHidden] = useState(true);
  const [firstAnswer, setFirstAnswer] = useState(null);
  const [secondAnswer, setSecondAnswer] = useState(null);
  const [isShowingRestOfListB, setIsShowingRestOfListB] = useState(false);
  const citizenship = get(data, 'citizenship.value', '');

  const isUSACitizen = () => {
    // Important! - Do not include 'usa_naturalized' in this list - there is special logic implemented for that
    return ['usa_born', 'usa_born_abroad', 'non_citizen_national'].indexOf(citizenship) > -1;
  };

  const shouldGoToVisaQuestion = () => {
    const listADocument = get(data, 'listADocuments.value');
    return listADocument === 'foreign-passport-and-i-94';
  };

  const onNextClicked = () => {
    if (isShowingRestOfListB) {
      if (secondAnswer === 'none') {
        return onNext({ nextStep: 'fail' });
      } else {
        onChange(secondAnswer);
        return onNext({ nextStep: 'listC' });
      }
    } else {
      if (firstAnswer === 'yes') {
        onChange('drivers-license');
        if (data.listADocuments.value !== 'none') {
          if (isUSACitizen()) {
            return onNext({ nextStep: 'scanning' });
          }
          if (shouldGoToVisaQuestion()) {
            return onNext({ nextStep: 'visa' });
          }
          return onNext({ nextStep: 'supplemental' });
        } else {
          return onNext({ nextStep: 'listC' });
        }
      }

      if (firstAnswer === 'no') {
        if (data.listADocuments.value !== 'none') {
          if (isUSACitizen()) {
            return onNext({ nextStep: 'scanning' });
          }
          if (shouldGoToVisaQuestion()) {
            return onNext({ nextStep: 'visa' });
          }
          return onNext({ nextStep: 'supplemental' });
        } else {
          setIsShowingRestOfListB(true);
          setIsNextHidden(true);
        }
      }
    }
  };

  if (isHidden) return null;

  return (
    <>
      {!isShowingRestOfListB && (
        <>
          <BlockFormLabel>
            <Typography variant="h6">{translate('listBDocuments.question1.label')}</Typography>
          </BlockFormLabel>
          {data?.driving && data?.driving !== 'none' && (
            <Alert color="warning">
              <Typography variant="body2">{translate('listBDocuments.question1.warning')}</Typography>
            </Alert>
          )}
          <FormControl>
            <RadioGroup
              aria-label="list B documents"
              name="listBDocuments"
              value={firstAnswer}
              onChange={({ target }) => {
                setFirstAnswer(target.value);
                setIsNextHidden(!target.value);
              }}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label={translate('listBDocuments.question1.answer1')}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label={translate('listBDocuments.question1.answer2')}
              />
            </RadioGroup>
          </FormControl>
        </>
      )}

      {isShowingRestOfListB && (
        <>
          <BlockFormLabel>
            <Typography variant="h6">{translate('listBDocuments.question2.label')}</Typography>
          </BlockFormLabel>
          <FormControl>
            <RadioGroup
              aria-label="list B documents"
              name="listBDocuments"
              value={secondAnswer}
              onChange={({ target }) => {
                setSecondAnswer(target.value);
                setIsNextHidden(!target.value);
              }}
            >
              <FormControlLabel
                value="state-id-card"
                control={<Radio color="primary" />}
                label={translate('listBDocuments.question2.answer1')}
              />
              <FormControlLabel
                value="school-id-card"
                control={<Radio color="primary" />}
                label={translate('listBDocuments.question2.answer2')}
              />
              {['usa_born', 'usa_naturalized', 'usa_born_abroad'].indexOf(citizenship) > -1 && (
                <FormControlLabel
                  value="voter-registration-card"
                  control={<Radio color="primary" />}
                  label={translate('listBDocuments.question2.answer3')}
                />
              )}
              <FormControlLabel
                value="military-id"
                control={<Radio color="primary" />}
                label={translate('listBDocuments.question2.answer4')}
              />
              <FormControlLabel
                value="military-dependent-id"
                control={<Radio color="primary" />}
                label={translate('listBDocuments.question2.answer5')}
              />
              <FormControlLabel
                value="merchant-mariner-card"
                control={<Radio color="primary" />}
                label={translate('listBDocuments.question2.answer6')}
              />
              {['usa_born'].indexOf(citizenship) > -1 && (
                <FormControlLabel
                  value="tribal-document"
                  control={<Radio color="primary" />}
                  label={translate('listBDocuments.question2.answer7')}
                />
              )}
              <FormControlLabel
                value="none"
                control={<Radio color="primary" />}
                label={translate('listBDocuments.question2.answer8')}
              />
            </RadioGroup>
          </FormControl>
        </>
      )}

      <NextButton isDisabled={isNextHidden} onClick={onNextClicked} />
    </>
  );
}
